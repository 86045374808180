import { useState, FC, useEffect, useMemo } from 'react';
import { ServerSideDataGrid } from '../../components';
import { getScheduledServiceServiceLog } from '../../fetch';
import { IScheduledServiceLog } from '../../models';
import {
  formatShortFriendlyDateWithTime,
  getLocalPageSize,
  SERVICE_LOG_GRID_KEY,
} from '../../helpers';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

interface IServiceLogDetails {
  serviceId: string | number;
  shouldRefetchTableUpdate: string | null;
}

export const ServiceLogDetails: FC<IServiceLogDetails> = ({
  serviceId,
  shouldRefetchTableUpdate,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [serviceLogs, setServiceLogs] = useState<IScheduledServiceLog[]>([]);
  const [page, setPage] = useState<number>(0);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [perPage, setRowsPerPage] = useState<number>(getLocalPageSize(SERVICE_LOG_GRID_KEY));

  const fetchServiceLogs = async () => {
    try {
      setLoading(true);
      const res = await getScheduledServiceServiceLog(serviceId);
      setServiceLogs(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchServiceLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, shouldRefetchTableUpdate]);

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'whenCreated',
          headerName: 'Log Date/Time',
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          renderCell: (params: GridRenderCellParams<IScheduledServiceLog>) => {
            const { row: original } = params;
            return formatShortFriendlyDateWithTime(original.whenCreated);
          },
        },
        {
          headerName: 'User',
          field: 'userName',
          sortable: false,
          flex: 1,
          disableColumnMenu: true,
        },
        {
          headerName: 'Log Entry',
          field: 'logEntry',
          sortable: false,
          flex: 1,
          disableColumnMenu: true,
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceLogs]
  );
  return (
    <>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: IScheduledServiceLog) => row.serviceLogId}
        rows={serviceLogs}
        columns={columns}
        loading={isLoading}
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        onPageChange={page => setPage(page)}
        onPageSizeChange={num => {
          setRowsPerPage(num);
          localStorage.setItem(SERVICE_LOG_GRID_KEY, String(num));
        }}
        hasMobileLayout
        mobileProps={{
          useFirstCell: true,
        }}
      />
    </>
  );
};
