import { FC, Fragment, useContext, useState } from 'react';
import { Loader, NonAuthPage, Card, DisplayGroup, Signature } from '../../../components';
import { BrandingContext } from '../../../context/branding-context';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import {
  IExternalRecurringPaymentData,
  IPaymentProcessorModel,
  PaymentProcessor,
} from '../../../models';
import {
  Alert,
  Stack,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Theme,
  ListItem,
  List,
  Divider,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { getExternalRecurringPaymentData, getOfficePaymentProcessor } from '../../../fetch';
import { PaymentInfo } from '../../online-payments';
import { formatAddressBlock, formatDate, formatMoney, getDaySuffix } from '../../../helpers';

export const RecurringExternalPaymentPage: FC = () => {
  const { isPoolService } = useContext(BrandingContext);
  const { recurringPaymentId }: { recurringPaymentId?: string } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const [error, setError] = useState<string | null>(null);
  const [sigPadInstance, setSigPadInstance] = useState<SignaturePad | null>(null);
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const {
    isLoading: isLoadingPaymentData,
    data: paymentData,
    refetch: reloadData,
  } = useQuery<IExternalRecurringPaymentData, AxiosError>(
    ['getExternalRecurringPaymentData', token, recurringPaymentId],
    async () => {
      const res = await getExternalRecurringPaymentData(token as string, recurringPaymentId);
      return res;
    },
    {
      onError: (e: any) => {
        setError(e?.Detail ?? 'Error loading payment details, please refresh and try again.');
      },
    }
  );
  const { data: paymentProcessorData, isLoading: isLoadingPaymentProcessor } = useQuery<
    IPaymentProcessorModel,
    Error
  >(
    ['getOfficePaymentProcessor', paymentData?.officeId],
    () => getOfficePaymentProcessor(paymentData?.officeId!, token),
    {
      enabled: !!paymentData?.officeId,
    }
  );
  const address = paymentData?.address;

  const renderSigCard = () => {
    return (
      <Card cardTitleProps={{ title: 'Signature' }}>
        <Stack spacing={2}>
          {(!isConfirmed || !isSigned) && (
            <Alert severity="info">Sign and confirm payment before paying for service.</Alert>
          )}
          <Signature
            setSigPadInstance={setSigPadInstance}
            sigPadInstance={sigPadInstance}
            setIsSigned={setIsSigned}
            isSigned={isSigned}
            hasSubmitted={false}
          />
          <FormControlLabel
            control={<Checkbox name="aggreement" checked={isConfirmed} />}
            label={paymentData?.terms}
            onChange={(_, checked) => setIsConfirmed(checked)}
            required
          />
        </Stack>
      </Card>
    );
  };
  return (
    <NonAuthPage
      title={`Collect Credit Info and set up Recurring Payment`}
      hideHeader={isPoolService}
      innerWrapStyles={{ padding: { xs: 3, md: 4 } }}
    >
      {paymentData && (
        <Stack spacing={1} alignItems="center" justifyContent="center" mb={1}>
          <Box>
            <img
              src={paymentData?.logoUrl}
              alt="logo"
              style={{ height: '50px', marginRight: '10px' }}
            />
          </Box>
          <Typography component="h1" variant="h5">
            {paymentData?.officeName}
          </Typography>
        </Stack>
      )}
      {!!error || !token ? (
        <Alert severity="error">
          The link no longer appears to be valid. Please contact the office for more information.
          Thank you!
        </Alert>
      ) : (
        <>
          {paymentData && paymentData?.status !== 'InProgress' && (
            <Alert severity="info">Credit Card information has already been collected</Alert>
          )}
          {(isLoadingPaymentData || isLoadingPaymentProcessor) && (
            <Loader type="overlay" position="centered" title="Loading" />
          )}
          {!isLoadingPaymentData &&
            !isLoadingPaymentProcessor &&
            paymentData &&
            paymentData?.status === 'InProgress' && (
              <Grid container spacing={2}>
                {!isMobile && (
                  <Grid item xs={12} lg={4}>
                    {renderSigCard()}
                  </Grid>
                )}
                <Grid item xs={12} lg={8}>
                  <Stack spacing={2}>
                    <Card cardTitleProps={{ title: 'Details' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <DisplayGroup label="Date" labelId="When Created">
                            {paymentData?.whenCreated ? formatDate(paymentData?.whenCreated) : ''}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <DisplayGroup label="Customer" labelId="accountName">
                            {paymentData?.accountName ?? ''}
                          </DisplayGroup>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <DisplayGroup
                            label="Account Address"
                            labelId="accountAddress"
                            component="div"
                          >
                            {!!address && (
                              <>
                                {!!address.addressName && (
                                  <Typography component="span">{address?.addressName}</Typography>
                                )}
                                <Typography component="address">
                                  {formatAddressBlock(
                                    address?.street,
                                    address?.city,
                                    address?.state,
                                    address?.postalCode
                                  )}
                                </Typography>
                              </>
                            )}
                          </DisplayGroup>
                        </Grid>
                      </Grid>
                    </Card>
                    {isMobile && renderSigCard()}
                    {paymentData?.recurringServices &&
                      paymentData?.recurringServices?.length > 0 && (
                        <Card cardTitleProps={{ title: 'Recurring Services' }}>
                          <List>
                            {paymentData.recurringServices?.map((service, index) => {
                              return (
                                <Fragment key={`${index}`}>
                                  <ListItem>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={6} lg={4}>
                                        <DisplayGroup label="Site" labelId="siteDescription">
                                          {service.siteDescription}
                                        </DisplayGroup>
                                      </Grid>
                                      <Grid item xs={12} md={6} lg={4}>
                                        <DisplayGroup
                                          label="Service"
                                          labelId="serviceDefDescription"
                                        >
                                          {service.serviceDefDescription}
                                        </DisplayGroup>
                                      </Grid>
                                      <Grid item xs={12} md={6} lg={4}>
                                        <DisplayGroup label="Frequency" labelId="genericFrequency">
                                          {service.genericFrequency}
                                        </DisplayGroup>
                                      </Grid>
                                      <Grid item xs={12} md={6} lg={4}>
                                        <DisplayGroup
                                          label="Amount"
                                          labelId="defaultAmountToCharge"
                                        >
                                          {formatMoney(service.defaultAmountToCharge)}
                                        </DisplayGroup>
                                      </Grid>
                                    </Grid>
                                  </ListItem>
                                  {paymentData?.recurringServices &&
                                    paymentData?.recurringServices?.length > 1 && <Divider />}
                                </Fragment>
                              );
                            })}
                          </List>
                        </Card>
                      )}
                    <PaymentInfo
                      currentCustomer={
                        {
                          name: address?.addressName ?? '',
                          firstName: paymentData?.accountFirstName ?? '',
                          lastName: paymentData?.accountLastName ?? '',
                          address,
                          accountId: paymentData?.accountId ?? '',
                        } as any
                      }
                      defaultAmount={String(paymentData?.paymentAmount) ?? 0}
                      isExternalPayment
                      reloadExternalPayment={reloadData}
                      authToken={token}
                      showCancelButton={false}
                      showNotesField={false}
                      recurringPaymentId={recurringPaymentId}
                      defaultDayOfMonth={String(paymentData?.dayOfMonth) ?? '1'}
                      adyen={
                        paymentProcessorData?.paymentProcessor === PaymentProcessor.Adyen
                          ? true
                          : false
                      }
                      passedInPaymentProcessor={paymentProcessorData}
                      isParentShouldCallAdyenSession={isSigned && isConfirmed}
                      showRecurringActionButton={false}
                      showRecurringCancelButton={false}
                      showPayBalence={paymentData.payOutstandingBalance}
                      isPayBalanceDisabled={true}
                      isExternalSaveDisabled={!isConfirmed || !isSigned}
                      showInvoiceInput={false}
                      sigPadInstance={sigPadInstance}
                      alertText={
                        paymentData?.dayOfMonth === -1
                          ? undefined
                          : `Your card will be charged on the ${
                              paymentData?.dayOfMonth === 0
                                ? 'last'
                                : `upcoming ${getDaySuffix(paymentData?.dayOfMonth)}`
                            } day of the month ${
                              paymentData.payOutstandingBalance
                                ? `for the entire balance remaining`
                                : 'and subsequent months for the amount'
                            } of ${formatMoney(paymentData.paymentAmount)}.`
                      }
                      showStoredPaymentMethods={false}
                    />
                  </Stack>
                </Grid>
              </Grid>
            )}
        </>
      )}
    </NonAuthPage>
  );
};
