import { FC, useCallback, useState } from 'react';
import { IFilterInputProps, IFilter, IFilterLayout, ISiteSimple } from '../../models';
import {
  CheckboxGroupFilter,
  CheckboxFilter,
  DateFilter,
  DateRangeFilter,
  DateTimeFilter,
  SelectListFilter,
  IntegerFilter,
  MoneyFilter,
  RadioButtonsFilter,
  TextareaFilter,
  TextboxFilter,
  SelectListMultiFilter,
} from './filter-controls';
import { Box, Grid, Button } from '@mui/material';
import { sortBy } from 'lodash';
import { getCleanFilterValues } from './utils';
import { SiteAutocomplete } from '../inputs';
import { Loader } from '../loader';
import { checkForEnterKey } from '../../helpers';

const DEFAULT_LAYOUT: IFilterLayout = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 3,
};

export interface IFilterFormProps {
  filters: IFilter[];
  values: Record<string, string[]>;
  filterLayouts?: Record<string, IFilterLayout>;
  defaultLayout?: IFilterLayout;
  isSubmitting?: boolean;
  onSubmit: (values: Record<string, string[]>) => unknown;
  onChange: (values: Record<string, string[]>) => unknown;
  onReset: (values: Record<string, string[]>) => unknown;
  isLoading?: boolean;
}

export const FilterForm: FC<IFilterFormProps> = ({
  filters,
  values,
  filterLayouts,
  isSubmitting,
  onSubmit,
  defaultLayout = DEFAULT_LAYOUT,
  onChange,
  onReset,
  isLoading,
}) => {
  const [siteOptions, setSiteOptions] = useState<ISiteSimple[]>([]);
  const handleChange = (value: string[], param: IFilter) => {
    onChange?.({
      ...values,
      [param.name]: value,
    });
  };

  const handleSubmit = useCallback(() => {
    onSubmit(getCleanFilterValues(values));
  }, [onSubmit, values]);

  const resetFilters = () => {
    onReset?.({});
  };

  const renderParam = (param: IFilter) => {
    const value = values[param.name] || [];
    let ParamInput: FC<IFilterInputProps> | undefined;
    if (param?.name === 'SiteId') {
      const selected = siteOptions?.find(val => val.siteId === value?.[0]);
      return (
        <SiteAutocomplete
          handleChange={val => {
            handleChange([val], param);
          }}
          selectedSite={selected ?? null}
          siteId={value?.[0] ?? ''}
          isRequired={false}
          showInvalidAddress={false}
          handleOptions={sites => setSiteOptions(sites)}
          showSiteLink={false}
        />
      );
    }
    switch (param.type) {
      case 'Checkbox':
        ParamInput = CheckboxFilter;
        break;
      case 'CheckboxList':
        ParamInput = CheckboxGroupFilter;
        break;
      case 'RadioButtonList':
        ParamInput = RadioButtonsFilter;
        break;
      case 'SelectList':
        ParamInput = SelectListFilter;
        break;
      case 'SelectListMulti':
        ParamInput = SelectListMultiFilter;
        break;
      case 'Textarea':
        ParamInput = TextareaFilter;
        break;
      case 'Date':
        ParamInput = DateFilter;
        break;
      case 'DateRange':
        ParamInput = DateRangeFilter;
        break;
      case 'DateTime':
        ParamInput = DateTimeFilter;
        break;
      case 'Money':
        ParamInput = MoneyFilter;
        break;
      case 'Number':
        ParamInput = IntegerFilter;
        break;
      case 'Text':
        ParamInput = TextboxFilter;
        break;
    }
    if (!ParamInput) {
      return null;
    }
    return <ParamInput filter={param} values={value} onChange={handleChange} filters={filters} />;
  };

  const sortedFilters = sortBy(filters, (filter, i) => {
    const layout = filterLayouts?.[filter.name];
    if (!layout) {
      // push filter to the end of the list if it has not layout
      return 10000 + i;
    }
    return layout.sortOrder;
  });

  if (isLoading) {
    return <Loader position="centered" type="inline" />;
  }

  return (
    <form
      onKeyDown={e => {
        checkForEnterKey(e, () => {
          handleSubmit();
        });
      }}
    >
      <Grid container spacing={2} mt={0.5}>
        {sortedFilters.map(filter => {
          const layout = filterLayouts?.[filter.name] || defaultLayout;
          if (!layout?.hide) {
            return (
              <Grid
                item
                xs={layout.xs}
                sm={layout.sm}
                md={layout.md}
                lg={layout.lg}
                xl={layout.xl}
                key={filter.name}
              >
                {renderParam(filter)}
              </Grid>
            );
          } else return null;
        })}
      </Grid>
      {sortedFilters?.length > 0 && (
        <Box display="flex" justifyContent="flex-end" gap={1} mt={1}>
          <Button
            type="button"
            color="inherit"
            size="small"
            disabled={isSubmitting}
            onClick={resetFilters}
            data-testid="reset-button"
          >
            Reset
          </Button>
          <Button
            type="button"
            color="primary"
            size="small"
            disabled={isSubmitting}
            onClick={handleSubmit}
            data-testid="apply-filters-button"
          >
            Apply Filters
          </Button>
        </Box>
      )}
    </form>
  );
};
