import { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Stack, Grid, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Loader,
  SelectAsync,
  TextField,
  Page,
  SaveButton,
  Card,
  AccountAutocomplete,
  ConfirmPrompt,
  CancelIcon,
  AmountInput,
} from '../../components';
import { UserContext } from '../../context';
import {
  IResponse,
  IListUser,
  ICommissionForm,
  ICommissionPost,
  ICommissionType,
  IAccountSimple,
} from '../../models';
import {
  getUsers,
  getCommission,
  deleteCommission,
  putCommission,
  postCommission,
  getCommissionTypes,
} from '../../fetch';
import { format } from 'date-fns';
import { useConfirm } from '../../hooks';
import { defaultUnsavedChangesMessage, Paths } from '../../constants';
import { convertToNumber, formatMoney } from '../../helpers';

const FORM_VALIDATION = Yup.object().shape({
  reason: Yup.string().max(500, 'Max 500 characters'),
  amount: Yup.string()
    .test('min', 'Must be greater than 0', val => (val && convertToNumber(val) > 0 ? true : false))
    .required('Required'),
});

export const CommissionDetail = () => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const today = format(new Date(), 'L/d/yyyy');
  const [commission, setCommission] = useState<ICommissionForm>({
    commissionId: '',
    amount: '$0.00',
    whenCreated: today,
    whenDue: '',
    reason: '',
    commissionTypeId: '',
    commissionTypeDescription: '',
    userId: '',
    userName: '',
    accountId: '',
    accountName: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<IAccountSimple | null>(null);
  const [accounts, setAccounts] = useState<IAccountSimple[] | null>(null);

  useEffect(() => {
    if (!!commission?.accountId && accounts) {
      const account = accounts?.find(account => account.accountId === commission.accountId);
      if (account) {
        setSelectedAccount(account);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commission, accounts]);

  const { commissionId }: { commissionId: string } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);
  const confirm = useConfirm();

  const history = useHistory();
  const isNewCommission = commissionId === 'new';

  const handleDelete = async () => {
    const result = await confirm('Are you sure you want to delete this commission?');
    if (!result) return;
    try {
      setIsDeleting(true);
      await deleteCommission(commissionId);
      enqueueSnackbar(`Commission Deleted!`, {
        variant: 'success',
      });
      redirect && history.push(redirect);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting commission, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const fetchCommission = async (newCommissionId?: string) => {
    try {
      setIsLoading(true);
      const response = await getCommission(newCommissionId ?? commissionId);
      setCommission({
        ...response,
        amount: formatMoney(response.amount),
      });
    } catch (err: any) {
      enqueueSnackbar(
        err?.response?.data?.Detail || `Error loading commission. Please try again.`,
        {
          variant: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isNewCommission) {
      fetchCommission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Page
        title={`${isNewCommission ? 'New' : 'Edit'} Commission`}
        breadcrumb={{
          text: 'Commissions',
          title: 'Back to Commissions',
          link: redirect ?? Paths.commissions.url,
        }}
      >
        <Formik
          initialValues={{
            ...commission,
          }}
          enableReinitialize={true}
          validationSchema={FORM_VALIDATION}
          onSubmit={async values => {
            try {
              const payload: ICommissionPost = {
                amount: convertToNumber(values.amount),
                whenCreated: values.whenCreated,
                whenDue: values.whenDue,
                reason: values.reason,
                commissionTypeId: values.commissionTypeId,
                userId: values.userId,
                accountId: values.accountId,
              };
              if (!isNewCommission && commission.commissionId) {
                await putCommission(commission.commissionId, {
                  ...payload,
                });
              } else {
                await postCommission({ ...payload });
              }
              enqueueSnackbar('Commission saved!', { variant: 'success' });
              redirect && history.push(redirect);
            } catch (err: any) {
              enqueueSnackbar(err?.Detail ?? `Error saving commission. Please try again.`, {
                variant: 'error',
              });
            }
          }}
        >
          {({
            isSubmitting,
            isValid,
            dirty,
            values,
            setFieldValue,
            resetForm,
            errors,
            handleBlur,
            touched,
          }) => {
            return (
              <Form style={{ height: '100%' }}>
                {(isDeleting || isSubmitting || isLoading) && (
                  <Loader position="centered" type="overlay" />
                )}
                <>
                  <Box>
                    <Card
                      cardTitleProps={{
                        title: 'Commission Details',
                      }}
                    >
                      <ConfirmPrompt
                        when={dirty && !isSubmitting}
                        message={defaultUnsavedChangesMessage}
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} order={{ xs: '1', md: '0' }}>
                          <Stack direction="column" gap={2}>
                            <SelectAsync
                              name="userId"
                              label="Commission For"
                              required
                              apiRequest={() => getUsers({ perPage: -1, officeId: user?.officeId })}
                              transformResponse={(response: IResponse<IListUser[]>) => {
                                return response.records.map(record => ({
                                  label: record.userName,
                                  value: record.userId,
                                }));
                              }}
                              hasClear={values.userId ? true : false}
                            />
                            <AccountAutocomplete
                              setSelectedAccount={val => {
                                setFieldValue('accountId', val?.accountId);
                                setSelectedAccount(val);
                              }}
                              labelText="Customer"
                              isRequired
                              handleOptions={data => setAccounts(data)}
                              selectedAccount={selectedAccount}
                            />
                            <DatePicker
                              label="When Created"
                              format="MM/dd/yyyy"
                              onChange={(date: any) => {
                                setFieldValue('whenCreated', date);
                              }}
                              value={values.whenCreated ? new Date(values.whenCreated) : null}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  fullWidth: true,
                                },
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} order={{ xs: '0', md: '1' }}>
                          <Stack direction="column" gap={2}>
                            <SelectAsync
                              name="commissionTypeId"
                              label="Commission Type"
                              required
                              apiRequest={() =>
                                getCommissionTypes({
                                  perPage: -1,
                                  officeId: user?.officeId as string,
                                })
                              }
                              transformResponse={(response: IResponse<ICommissionType[]>) => {
                                return response.records.map(record => ({
                                  label: record.description,
                                  value: record.commissionTypeId,
                                }));
                              }}
                              hasClear={values.commissionTypeId ? true : false}
                            />
                            <AmountInput
                              value={values.amount}
                              name="amount"
                              label="Amount"
                              onChange={val => setFieldValue('amount', val)}
                              onBlur={handleBlur}
                              error={touched.amount && Boolean(errors.amount)}
                              helperText={touched.amount && errors.amount ? errors.amount : ''}
                            />
                            <DatePicker
                              label="When Due"
                              format="MM/dd/yyyy"
                              onChange={(date: any) => {
                                setFieldValue('whenDue', date);
                              }}
                              value={values.whenDue ? new Date(values.whenDue) : null}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  fullWidth: true,
                                  error: false,
                                },
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} order={{ xs: '3' }}>
                          <TextField name="reason" label="Reason" multiline minRows={4} />
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{
                      xs: 'space-between',
                    }}
                    className="print--none"
                    mt={2}
                  >
                    <Box flex={1}>
                      {commissionId !== 'new' && (
                        <Button
                          color="error"
                          type="button"
                          onClick={handleDelete}
                          title="Delete Commission"
                          startIcon={<FontAwesomeIcon icon={faTrash} size="lg" />}
                        >
                          Delete
                        </Button>
                      )}
                    </Box>
                    <Box display="flex" gap={1}>
                      <Button
                        color="inherit"
                        onClick={() => {
                          if (!dirty && redirect) {
                            history.push(redirect);
                          } else {
                            resetForm();
                            redirect && history.push(redirect);
                          }
                        }}
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                      <SaveButton disabled={!dirty || !isValid} />
                    </Box>
                  </Box>
                </>
              </Form>
            );
          }}
        </Formik>
      </Page>
    </>
  );
};
