import { ListItem, ListItemButton } from '@mui/material';
import { FC, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  ServerSideDataGrid,
  ServerSideDataGridProps,
  ExternalLink,
  Link,
  TableActionsMenu,
  QuickBooksIcon,
} from '../../../components';
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { formatMoney, formatDate, getLegacyUrl, hasCorrectUserPermissions } from '../../../helpers';
import { ITransaction, IInvoiceLaborCharges, IAccountSimple } from '../../../models';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { UserContext } from '../../../context';
import { Paths, Permissions } from '../../../constants';
import { postFinalizeInvoice, canFinalizeInvoice } from '../../../fetch';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TransactionModal } from '../../customers/customers-detail/transaction-modal';
import { LaborHoursModal } from '../../estimates/labor-hours-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDollar, faEye } from '@fortawesome/free-solid-svg-icons';

interface OTSInvoicesDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: ITransaction[];
  refetch: () => Promise<void>;
  redirect?: string;
  isOTSStatusOpen?: boolean;
  repairId?: string;
  paymentMethodId?: string | null;
  refetchRepairStatus?: () => void;
  shouldShowLaborModal?: boolean;
  currentCustomer?: IAccountSimple | null;
}

export const OTSInvoicesDataGrid: FC<OTSInvoicesDataGridProps> = ({
  rows,
  refetch,
  redirect,
  isOTSStatusOpen,
  repairId,
  paymentMethodId,
  refetchRepairStatus,
  shouldShowLaborModal,
  currentCustomer,
  ...gridProps
}) => {
  const [isCheckingInvoice, setCheckingInvoice] = useState(false);
  const [isShowingLaborModal, setIsShowingLaborModal] = useState<boolean>(false);
  const [isUpdatingInvoice, setIsUpdatingInvoice] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<ITransaction | null>(null);
  const [currentRefernce, setCurrentReference] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const { v2Billing } = useFlags();
  const PCP_LEGACY_URL = getLegacyUrl?.();
  const { user, hasQBInvoiceEntryType } = useContext(UserContext);
  const hasTransactionSameDayPermission = hasCorrectUserPermissions(
    Permissions.TransactionEditSameDay,
    user!
  );
  const hasTransactionPriorDayPermission = hasCorrectUserPermissions(
    Permissions.TransactionEditPriorDay,
    user!
  );
  const finalizeInvoice = async (transactionId: string, laborHours?: IInvoiceLaborCharges[]) => {
    setIsUpdatingInvoice(true);
    try {
      await postFinalizeInvoice(transactionId, laborHours);
      enqueueSnackbar(
        `Invoice finalized${hasQBInvoiceEntryType ? ' and sent to Quickbooks' : ''}!`,
        {
          variant: 'success',
        }
      );
      refetch();
      refetchRepairStatus?.();
    } catch (err: any) {
      enqueueSnackbar(err?.Detail || `Error finalizing invoice, please try again.`, {
        variant: 'error',
      });
      return true;
    } finally {
      setIsUpdatingInvoice(false);
    }
  };
  const renderTransactionLink = (transaction: ITransaction, linkName?: string) => {
    // the date for the transaction is greater than or equal to today
    const dateIsSameOrAfter =
      isSameDay(new Date(transaction.whenPosted), new Date()) ||
      isAfter(new Date(transaction.whenPosted), new Date());

    // the whenPosted for the transaction is less than to today
    const dateIsBefore = isBefore(new Date(transaction.whenPosted), new Date());

    const hasPermission: boolean =
      (hasTransactionSameDayPermission && dateIsSameOrAfter) ||
      (hasTransactionPriorDayPermission && dateIsBefore);

    const formattedPostedDate = transaction?.whenPosted ? formatDate(transaction?.whenPosted) : '';

    if (hasPermission) {
      if (!v2Billing) {
        return (
          <ExternalLink to={`${PCP_LEGACY_URL}/Office/Billing/Edit/${transaction.transactionId}`}>
            {linkName ? linkName : formattedPostedDate}
          </ExternalLink>
        );
      }
      if (v2Billing) {
        return (
          <Link to={`${Paths.billing.url}/invoices/${transaction.transactionId}${redirect}`}>
            {linkName ? linkName : formattedPostedDate}
          </Link>
        );
      }
    }
    return formattedPostedDate;
  };
  const columns = useMemo(() => {
    return [
      {
        field: 'whenPosted',
        headerName: 'Date',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ITransaction>) => {
          const { row: transaction } = params;
          return renderTransactionLink(transaction);
        },
      },
      {
        field: 'reference',
        headerName: 'Description',
        flex: 1,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams<ITransaction>) => {
          const { row: transaction } = params;
          return formatMoney(transaction.amount);
        },
      },
      !hasQBInvoiceEntryType && {
        field: 'amountDue',
        headerName: 'Balance',
        flex: 1,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams<ITransaction>) => {
          const { row: transaction } = params;
          return transaction.amountDue ? formatMoney(transaction.amountDue) : '$0.00';
        },
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        align: 'center',
        maxWidth: 120,
        renderCell: (params: GridRenderCellParams<ITransaction>) => {
          const { row: transaction } = params;
          if (isOTSStatusOpen) {
            return (
              <>
                <TableActionsMenu
                  labelContext="OTS Invoice"
                  id={`action-menu-${transaction?.transactionId}`}
                >
                  {!transaction.isPosted && (
                    <ListItem disablePadding>
                      <ListItemButton
                        disabled={isCheckingInvoice || isUpdatingInvoice}
                        onClick={async () => {
                          if (shouldShowLaborModal) {
                            setCheckingInvoice(true);
                            const res = await canFinalizeInvoice(transaction.transactionId);
                            if (res.errors?.length > 0) {
                              setCheckingInvoice(false);
                              // show first error in errors array
                              return enqueueSnackbar(res.errors?.[0], {
                                variant: 'error',
                              });
                            }
                            setCheckingInvoice(false);
                            setSelectedTransaction(transaction);
                            setIsShowingLaborModal(true);
                          } else {
                            finalizeInvoice(transaction.transactionId);
                          }
                        }}
                        sx={{ color: theme => theme.palette.primary.main }}
                      >
                        {hasQBInvoiceEntryType ? (
                          <QuickBooksIcon style={{ marginRight: '.5rem', fontSize: '1.3rem' }} />
                        ) : (
                          <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '.5rem' }} />
                        )}
                        {hasQBInvoiceEntryType ? 'Send to QuickBooks' : 'Finalize'}
                      </ListItemButton>
                    </ListItem>
                  )}
                  {transaction.isPosted && (
                    <ListItem disablePadding>
                      <ListItemButton
                        disabled={isUpdatingInvoice || hasQBInvoiceEntryType}
                        onClick={() => {
                          setSelectedTransaction(transaction);
                        }}
                        sx={{ color: theme => theme.palette.primary.main }}
                      >
                        <FontAwesomeIcon icon={faDollar} style={{ marginRight: '.5rem' }} />
                        Pay
                      </ListItemButton>
                    </ListItem>
                  )}
                  <ListItem disablePadding>
                    <ListItemButton sx={{ color: theme => theme.palette.primary.main }}>
                      <FontAwesomeIcon icon={faEye} style={{ marginRight: '.5rem' }} />
                      {renderTransactionLink(transaction, 'View Invoice')}
                    </ListItemButton>
                  </ListItem>
                  {!transaction.isPosted && (
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          setSelectedTransaction(transaction);
                          setCurrentReference('Deposit received');
                        }}
                        disabled={hasQBInvoiceEntryType}
                        sx={{ color: theme => theme.palette.primary.main }}
                      >
                        <FontAwesomeIcon icon={faDollar} style={{ marginRight: '.5rem' }} />
                        Apply Deposit
                      </ListItemButton>
                    </ListItem>
                  )}
                </TableActionsMenu>
              </>
            );
          }
          return <></>;
        },
      },
    ].filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, isOTSStatusOpen, setCheckingInvoice, isCheckingInvoice]);

  return (
    <>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: ITransaction) => row.transactionId}
        rows={rows}
        // @ts-ignore
        columns={columns}
        disableColumnSelector
        disableColumnFilter
        disableRowSelectionOnClick
        columnHeaderHeight={36}
        {...gridProps}
        loading={gridProps.loading || isUpdatingInvoice}
        hasMobileLayout
        mobileProps={{
          mobileCustomDefaultAccessor: (row: ITransaction) =>
            row.whenPosted && formatDate(row.whenPosted),
          showHandleActions: true,
        }}
      />
      <TransactionModal
        isOpen={!!selectedTransaction && !isShowingLaborModal}
        onClose={(shouldUpdate?: boolean) => {
          if (shouldUpdate) {
            refetch();
            refetchRepairStatus?.();
          }
          setSelectedTransaction(null);
          setCurrentReference('');
        }}
        accountId={selectedTransaction?.accountId!}
        isOtsInvoice
        defaultAmount={selectedTransaction?.amountDue! ?? selectedTransaction?.amount!}
        repairId={repairId}
        paymentMethodId={paymentMethodId}
        currentCustomer={currentCustomer}
        reference={currentRefernce}
        isForSecurityDeposit={selectedTransaction?.isPosted === false ? true : undefined}
        isForAdditionalSecurityDeposit={selectedTransaction?.isPosted === false ? true : undefined}
        transactionId={selectedTransaction?.transactionId}
      />
      <LaborHoursModal
        isOpen={isShowingLaborModal}
        handleClose={() => {
          setIsShowingLaborModal(false);
          setSelectedTransaction(null);
        }}
        isSubmitting={isUpdatingInvoice}
        handleSubmit={async (laborHours: IInvoiceLaborCharges[]) => {
          return await finalizeInvoice(selectedTransaction?.transactionId!, laborHours);
        }}
        repairId={repairId}
      />
    </>
  );
};
