import { InvoiceDetail, Page, Modal } from '../../components';
import { Box, Fade } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FC, useState } from 'react';
import { Paths } from '../../constants';

interface IInvoiceDetailPage {
  isModal?: boolean;
  isModalOpen?: boolean;
  handleModalClose?: () => void;
  currentInvoiceId?: string | null;
}

export const InvoiceDetailPage: FC<IInvoiceDetailPage> = ({
  isModal,
  isModalOpen,
  handleModalClose,
  currentInvoiceId,
}) => {
  const { invoiceId: paramsInvoiceId }: { invoiceId: string } = useParams();
  const invoiceId = paramsInvoiceId ?? currentInvoiceId;

  const searchParams = new URLSearchParams(window.location.search);
  const needsRefresh = searchParams.get('refresh');
  const accountId = searchParams.get('accountId') as string;

  const [invoiceNumber, setInvoiceNumber] = useState<number | null>(null);

  return isModal ? (
    <Modal open={isModalOpen!} onClose={handleModalClose} maxWidth="md" title="Invoice">
      <Fade in={isModalOpen!}>
        <Box mt={2} mb={4}>
          <InvoiceDetail
            invoiceId={invoiceId}
            accountId={accountId}
            isForm
            handleModalClose={handleModalClose}
            setInvoiceNumber={setInvoiceNumber}
          />
        </Box>
      </Fade>
    </Modal>
  ) : (
    <Page
      title={
        invoiceId === 'new'
          ? 'New Invoice'
          : invoiceNumber === null
          ? 'Loading...'
          : `Invoice ${invoiceNumber}`
      }
      breadcrumb={{
        text: 'Billing',
        title: 'Back to Billing',
        link: Paths.billing.url,
      }}
    >
      <InvoiceDetail
        invoiceId={invoiceId}
        accountId={accountId}
        needsRefresh={needsRefresh}
        isForm
        setInvoiceNumber={setInvoiceNumber}
      />
    </Page>
  );
};
