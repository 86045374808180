import { Box } from '@mui/material';
import { FC } from 'react';
import { IAccountDetail } from '../../../models';
import { EstimatesCard } from '../../estimates';
import { CustomerLeads } from './customer-leads';
import { Paths } from '../../../constants';

export interface IEstimatesTab {
  accountId: string;
  currentCustomer: IAccountDetail | null;
  isEditable?: boolean;
}

export const EstimatesTab: FC<IEstimatesTab> = ({ accountId, currentCustomer, isEditable }) => {
  const redirect = `${Paths.customers.url}/${currentCustomer?.accountId}/estimates`;
  return (
    <>
      <Box marginTop="1rem">
        <CustomerLeads accountId={accountId} />
        <EstimatesCard accountId={accountId} isEditable={isEditable} redirect={redirect} />
      </Box>
    </>
  );
};
