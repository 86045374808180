import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CustomViewDetails } from './custom-view-page-details';
import { Page } from '../../components';
import { Paths } from '../../constants';

interface ICustomViewPage {}

export const CustomViewPage: FC<ICustomViewPage> = () => {
  const { viewId }: { viewId: string } = useParams();
  const isNewCustomView = viewId === 'new';

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get('redirect');

  return (
    <Page
      title={`${isNewCustomView ? 'New' : 'Edit'} Custom View`}
      breadcrumb={{
        text: 'Customers',
        title: 'Back to Customers',
        link: redirect?.includes(Paths.customers.url) ? redirect : Paths.customers.url,
      }}
    >
      <CustomViewDetails />
    </Page>
  );
};
