import { useContext, useEffect, FC } from 'react';
import { Redirect, Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../../context';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useSnackbar } from 'notistack';
import { finishPendingRequests } from '../../helpers/axios';
import { Loader } from '../loader';
import { FeatureFlag, Paths, ROLES } from '../../constants';
import {
  convertStringToArray,
  hasAppAccessPermission,
  hasCorrectUserPermissions,
} from '../../helpers';

interface IPrivateRoute extends RouteProps {
  featureFlag?: string[] | string;
  permission?: string;
  legacyUrl?: string;
  reverseFlagCheck?: boolean; // When OFF redirect behavior needs to happen when flag is ON
  role?: string | string[];
  redirect?: string;
}

export const PrivateRoute: FC<IPrivateRoute> = ({
  featureFlag,
  permission,
  reverseFlagCheck,
  role,
  redirect,
  ...props
}) => {
  const { user, isFetching, permissions } = useContext(UserContext);
  const featureFlags = useFlags();
  const ldContext = useLDClient();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  useEffect(() => {
    // any pending requests, cancel them so we don't get any possible BE errors on the wrong pages
    return () => {
      finishPendingRequests('RouteChange');
    };
  }, [location.pathname]);
  useEffect(() => {
    let mounted = true;

    const waitForFF = async () => {
      const context = ldContext?.getContext();
      // need to check for logged in users context before we evaluate the current FF
      if (context && !context.anonymous) {
        const hasFFTurnedOff =
          !isFetching &&
          featureFlags &&
          featureFlag &&
          ((!reverseFlagCheck &&
            !convertStringToArray(featureFlag).every(item => featureFlags[item])) ||
            (reverseFlagCheck &&
              !!convertStringToArray(featureFlag).every(item => featureFlags[item])));
        // check the logged in users's permissions and see if they are valid
        const hasInCorrectPermissions =
          !isFetching &&
          permissions &&
          permissions?.length > 0 &&
          permission &&
          !hasCorrectUserPermissions(permission, user!) &&
          location.pathname === props.path;
        // if user doesn't have the DesktopApplicationAccess permission for pool service, redirect to /unauthorized
        if (!hasAppAccessPermission(user!) && location.pathname === props.path) {
          enqueueSnackbar(`Not the right permissions, redirecting...`, {
            variant: 'info',
          });

          return history.push(Paths.unauthorized.url);
        }
        // if the v2Homepage flag ever gets turned off for pool service redirect to /unauthorized
        if (featureFlag === FeatureFlag.v2HomePage && hasFFTurnedOff) {
          enqueueSnackbar(`Redirecting...`, {
            variant: 'info',
          });

          return history.push(Paths.unauthorized.url);
        } else if (hasFFTurnedOff && location.pathname === props.path) {
          // feature flag is turned off and there is no legacy url getting passed in, redirect to home page
          // need to check the user is loaded and we have featureFlags loaded
          enqueueSnackbar(`Redirecting...`, {
            variant: 'info',
          });
          history.push('/');
        } // logged in user doesn't have permissions to view route
        else if (hasInCorrectPermissions) {
          enqueueSnackbar(`Not the right permissions, redirecting...`, {
            variant: 'info',
          });
          history.push(`/`);
        } else if (
          Array.isArray(role) &&
          user?.userType !== ROLES.Emulating &&
          !role.includes(user?.userType!) &&
          location.pathname === props.path
        ) {
          enqueueSnackbar(`Not the right permissions, redirecting...`, {
            variant: 'info',
          });
          history.push(redirect ?? `/`);
        }
        // check if a role is passed in like "Office" or "SuperAdmin" and that the user isn't in emulating state at the moment
        // and the path matches the location pathname
        // if the passed in role doesn't match the user's role, redirect to the home page
        else if (
          role &&
          user?.userType !== ROLES.Emulating &&
          role !== user?.userType &&
          location.pathname === props.path
        ) {
          enqueueSnackbar(`Not the right permissions, redirecting...`, {
            variant: 'info',
          });
          history.push(redirect ?? `/`);
        }
      }
    };
    if (mounted) {
      waitForFF();
    }
    return () => {
      mounted = false;
    };
  }, [
    isFetching,
    featureFlag,
    featureFlags,
    permissions,
    history,
    permission,
    enqueueSnackbar,
    ldContext,
    user,
    location.pathname,
    props.path,
    reverseFlagCheck,
    role,
    redirect,
  ]);

  if (isFetching && location.pathname === props.path) {
    return <Loader type="overlay" position="centered" />;
  }

  return (
    <Route
      {...props}
      render={location => {
        return user && !isFetching ? (
          props.children
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        );
      }}
    />
  );
};
