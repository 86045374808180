import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import { UserContext } from '../../../context';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

// Components
import { Modal, Loader, TextField, ModalSaveSection } from '../../../components';
import { Box, Fade, Typography, Grid, Divider } from '@mui/material';
// fetch
import { updateAccountAlert, createAccountAlert } from '../../../fetch';

import { IAccountAlert } from '../../../models';

interface IAddEditAccountAlertDefsModal {
  open: boolean;
  onClose: () => void;
  currentAccountAlertDef?: IAccountAlert | null;
  fetchAccountAlertDefs: () => void;
}

const Schema = Yup.object().shape({
  accountAlertDefId: Yup.string(),
  sortOrder: Yup.number().required('Required').max(10000, 'Sort Order should not exceed 10000'),
  code: Yup.string().required('Required').max(20),
  description: Yup.string().required('Required').max(100),
});

export const AddEditAccountAlertDefsModal: FC<IAddEditAccountAlertDefsModal> = ({
  open,
  onClose,
  currentAccountAlertDef,
  fetchAccountAlertDefs,
}) => {
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          code: currentAccountAlertDef?.code ?? '',
          description: currentAccountAlertDef?.description ?? '',
          accountAlertDefId: currentAccountAlertDef?.accountAlertDefId ?? '',
          officeId: user?.officeId ?? '',
          sortOrder: currentAccountAlertDef?.sortOrder ?? 0,
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            ...values,
          };
          try {
            currentAccountAlertDef
              ? await updateAccountAlert(data)
              : await createAccountAlert(data);
            enqueueSnackbar(
              currentAccountAlertDef
                ? 'Successfully updated account alert!'
                : 'Successfully created account alert!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchAccountAlertDefs();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving account alert, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentAccountAlertDef ? 'Edit Account Alert' : 'Add Account Alert'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name="code" label="Code" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="sortOrder" label="Sort Order" type="number" required />
                      </Grid>
                    </Grid>
                    <ModalSaveSection
                      handleCancel={() => {
                        onClose();
                        resetForm();
                      }}
                      isSaveDisabled={!dirty || isSubmitting || !isValid}
                    />
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
