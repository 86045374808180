import { FC, useCallback, useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { Box, Button, IconButton, styled, TextField, useMediaQuery } from '@mui/material';
import { faTrash, faEdit, faTable, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Loader,
  Modal,
  ModalSaveSection,
  CardTitle,
  Card,
  ImageModal,
  useDataGrid,
  GridDataFetcher,
  ServerSideDataGrid,
} from '../../../components';
import { MultiImageUpload } from '../../../components/file/multi-image-upload';
import {
  createVisitPhoto,
  deleteVisitPhoto,
  getVisitPhotosByRepair,
  updateVisitPhoto,
  getRepairVisits,
} from '../../../fetch';
import { formatDate } from '../../../helpers';
import { IRepairVisitPhoto } from '../../../models';
import { useConfirm } from '../../../hooks';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { VisitPhotoGrid } from './visit-photo-grid';

interface IVisitPhotosProps {
  repairId: string;
  siteId: string;
  isExpanded?: boolean;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  hasOpenVisits: boolean;
  setShouldRefetchPhotos: (val: boolean) => void;
  shouldRefetchPhotos: boolean;
}

export const VisitPhotos: FC<IVisitPhotosProps> = ({
  repairId,
  siteId,
  isExpanded,
  isCollapsible = true,
  initialExpand = true,
  hasOpenVisits,
  setShouldRefetchPhotos,
  shouldRefetchPhotos,
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [isModalOpen, setisModalOpen] = useState(false);
  const [source, setSource] = useState<string>('');

  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [isDeletingPhoto, setIsDeletingPhoto] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentImage, setCurrentImage] = useState<IRepairVisitPhoto | null>(null);
  const [imageNameValue, setImageNameValue] = useState<string>('');
  const [orderTakenValue, setOrderTakenValue] = useState<number>(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [viewImageGrid, setViewImageGrid] = useState(false);
  const [currentSiteId] = useState(siteId);
  const [currentRepairId, setCurrentRepairId] = useState(repairId);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Prevent datafetcher from running right away and creating 2 API calls.
    let mounted = true;
    if (repairId && mounted) {
      setCurrentRepairId(repairId);
      setIsInitialized(true);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repairId]);

  const dataFetcher: GridDataFetcher<IRepairVisitPhoto> = useCallback(
    async ({ sortColumn, sortDirection, page, perPage }) => {
      try {
        if (!isInitialized) {
          return {
            continueLoading: true,
          };
        }
        let res: { records: IRepairVisitPhoto[]; totalRecordCount: number } = {
          records: [],
          totalRecordCount: 0,
        };

        if (currentRepairId && currentRepairId !== 'new') {
          res = await getVisitPhotosByRepair(currentRepairId, {
            perPage: perPage,
            sortBy: sortColumn || 'whenTaken',
            sortDirection: sortDirection || 'desc',
            page: page + 1,
          });
        }
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading visit photos, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInitialized]
  );

  const {
    rows,
    setRows,
    page,
    pageSize: perPage,
    isLoading: isLoadingVisitPhotos,
    refetch: fetchVisitPhotos,
    onSortModelChange,
    onPageChange,
    rowCount: recordCount,
    onPageSizeChange,
  } = useDataGrid<IRepairVisitPhoto>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'visit-photos-grid',
      sortColumn: 'whenTaken',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  useEffect(() => {
    if (shouldRefetchPhotos) {
      fetchVisitPhotos();
      setTimeout(() => {
        setShouldRefetchPhotos(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchPhotos]);

  const getVisitId = async () => {
    let res = await getRepairVisits({
      sortBy: 'ServiceDate',
      sortDirection: 'desc',
      page: 1,
      perPage: 5,
      repairId,
    });
    // TODO maybe need to re-visit this logic on trying to tie a photo to a visit
    // it seems it would be a better UI experince to have the photos tied with visit line items
    // and not in a seperate section called "Visit Photos"
    if (res.records.length > 0) {
      let currentOpenVisit = res.records.find(
        visit =>
          visit.status === 'Open' && visit.serviceDate && new Date(visit.serviceDate) <= new Date()
      );
      if (currentOpenVisit) {
        return currentOpenVisit.repairVisitId;
      } else {
        return res.records[0].repairVisitId;
      }
    }
    return null; // Return null if no visits are found
  };

  const uploadVisitPhoto = async (payload: File, photoMeta: IRepairVisitPhoto) => {
    // Get the latest visit with an open status
    let latestRepairVisitId = await getVisitId();

    setIsUploadingPhoto(true);
    try {
      if (latestRepairVisitId) {
        await createVisitPhoto(latestRepairVisitId, payload, {
          ...photoMeta,
          siteId: currentSiteId,
          repairId,
          orderTaken: rows.length + 1,
          photoName: payload.name,
        });
      } else {
        enqueueSnackbar(`There was no repair visit that matched that repair.`, {
          variant: 'error',
        });
      }
      enqueueSnackbar(`Photo Uploaded!`, {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error uploading photo, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsUploadingPhoto(false);
    }
  };

  const removeVisitPhoto = async (repairVisitId: string, visitPhotoId: number) => {
    setIsDeletingPhoto(true);
    try {
      await deleteVisitPhoto(repairVisitId, visitPhotoId);
      enqueueSnackbar(`Photo Deleted!`, {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error deleting photo, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDeletingPhoto(false);
      await fetchVisitPhotos();
    }
  };

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'thumbnail',
        headerName: '',
        disableColumnMenu: true,
        flex: 1,
        maxWidth: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IRepairVisitPhoto>) => {
          const { row: photo } = params;
          return (
            <ImgButton
              variant="text"
              onClick={() => {
                setSource(photo.urlPath);
                setisModalOpen(true);
              }}
            >
              <img src={photo.urlPath} alt={photo.photoName} style={{ width: '100%' }} />
            </ImgButton>
          );
        },
      },
      {
        field: 'photoName',
        headerName: 'Title',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IRepairVisitPhoto>) => {
          const { row: photo } = params;

          return (
            (
              <Button
                variant="text"
                disabled={isUploadingPhoto || isLoadingVisitPhotos || isDeletingPhoto}
                onClick={() => {
                  setSource(photo.urlPath);
                  setisModalOpen(true);
                }}
                sx={{ padding: 0 }}
              >
                {photo.photoName}
              </Button>
            ) || '--'
          );
        },
      },
      {
        field: 'whenTaken',
        headerName: 'Taken Date',
        disableColumnMenu: true,
        minWidth: 125,
        renderCell: (params: GridRenderCellParams<IRepairVisitPhoto>) => {
          const { row: photo } = params;

          return <span>{!!photo.whenTaken ? formatDate(photo.whenTaken) : ''}</span> || '--';
        },
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        maxWidth: 300,
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IRepairVisitPhoto>) => {
          const { row: photo } = params;

          return (
            <Box textAlign="right" display="flex" justifyContent="flex-end">
              <Box className={clsx('print--none')} display="flex">
                <IconButton
                  color="error"
                  title="Delete Photo"
                  disabled={
                    isUploadingPhoto ||
                    isLoadingVisitPhotos ||
                    isDeletingPhoto ||
                    rows.some(photo => !photo.whenTaken)
                  }
                  onClick={async () => {
                    const result = await confirm('Are you sure you want to delete this photo?');
                    if (result) {
                      await removeVisitPhoto(photo.repairVisitId, photo.visitPhotoId);
                    } else {
                      return;
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="sm" />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={
                    isUploadingPhoto ||
                    isLoadingVisitPhotos ||
                    isDeletingPhoto ||
                    rows.some(photo => !photo.whenTaken)
                  }
                  title={`Edit Visit Photo Name`}
                  onClick={() => {
                    setImageNameValue('');
                    setOrderTakenValue(0);
                    setCurrentImage(null);
                    setShowEditModal(true);
                    setCurrentImage(photo);
                    setImageNameValue(photo.photoName);
                    setOrderTakenValue(photo.orderTaken);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} size="sm" />
                </IconButton>
              </Box>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchVisitPhotos, rows, isLoadingVisitPhotos]);

  const toggleGrid = () => {
    setViewImageGrid(!viewImageGrid);
  };

  return (
    <Card>
      <CardTitle
        title="Visit Photo(s)"
        withExpand={isCollapsible}
        initialExpand={initialExpand}
        overrideExpand={isExpanded}
        mobileWrap
        action={
          <MultiImageUpload
            handleFileChange={async fileImages => {
              setRows(prev => [...prev, ...fileImages]);
              await Promise.all(
                fileImages.map(async photo => {
                  if (photo?.whenTaken) {
                    return;
                  }
                  if (photo?.file) {
                    await uploadVisitPhoto(photo.file, photo);
                  }
                })
              );
              await fetchVisitPhotos();
            }}
            images={rows.map(photo => photo.urlPath)}
            disabled={isLoadingVisitPhotos || !hasOpenVisits}
            photoId="visitPhotoId"
            buttonSize="small"
            buttonColor="secondary"
            isButtonFullWidth={isMobile}
          />
        }
      >
        {rows?.length > 0 && (
          <Box textAlign="right" display="flex" justifyContent="flex-end" className="print--none">
            <IconButton
              color="primary"
              onClick={toggleGrid}
              disabled={!viewImageGrid}
              title={`Show visit images as a list`}
            >
              <FontAwesomeIcon icon={faList} size="sm" />
            </IconButton>
            <IconButton
              color="primary"
              onClick={toggleGrid}
              title={`Show visit images as a tile gallery`}
              disabled={viewImageGrid}
            >
              <FontAwesomeIcon icon={faTable} size="sm" />
            </IconButton>
          </Box>
        )}
        <ImageModal isOpen={isModalOpen} onClose={() => setisModalOpen(false)} source={source} />
        <Modal
          maxWidth="xs"
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          title="Edit Visit Photo"
        >
          {isUpdating && <Loader type="overlay" position="centered" />}
          <Box mt={2}>
            <TextField
              fullWidth
              value={imageNameValue}
              onChange={e => setImageNameValue(e.target.value)}
              label="Photo Name"
              size="small"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              value={orderTakenValue}
              type="number"
              onChange={e => setOrderTakenValue(parseInt(e.target.value))}
              label="Order Taken"
              size="small"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Box>
          <ModalSaveSection
            isSaveDisabled={!imageNameValue}
            handleSave={async () => {
              try {
                if (currentImage !== null) {
                  setIsUpdating(true);
                  await updateVisitPhoto(currentImage.repairVisitId, currentImage.visitPhotoId, {
                    photoName: imageNameValue,
                    orderTaken: orderTakenValue,
                  });
                  fetchVisitPhotos();
                }
              } catch (error: any) {
                enqueueSnackbar(error?.Detail ?? `Error updating visit photo, please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsUpdating(false);
                setShowEditModal(false);
                setImageNameValue('');
                setOrderTakenValue(0);
                setCurrentImage(null);
              }
            }}
            handleCancel={() => {
              setShowEditModal(false);
            }}
          />
        </Modal>
        {viewImageGrid ? (
          <VisitPhotoGrid openModal={setisModalOpen} setSource={setSource} images={rows} />
        ) : (
          <ServerSideDataGrid
            getRowId={(row: IRepairVisitPhoto) => {
              return row.visitPhotoId!;
            }}
            rows={rows}
            columns={columns}
            autoHeight
            columnHeaderHeight={36}
            onSortModelChange={onSortModelChange}
            hideFooter
            loading={isLoadingVisitPhotos || isUploadingPhoto || isDeletingPhoto}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            noResultsMessage="No visit photos found."
          />
        )}
      </CardTitle>
    </Card>
  );
};

const ImgButton = styled(Button)(({ theme }) => ({
  padding: 0,
  maxHeight: 80,
  maxWidth: 80,
  minHeight: 30,
  minWidth: 30,
  overflow: 'hidden',
  position: 'relative',
  margin: 0,
  borderRadius: 0,
  display: 'block',
}));
