import { useContext } from 'react';
import { UserContext } from '../context';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { userLogout } from '../helpers';
import { useMsal } from '@azure/msal-react';
import { BrandingContext } from '../context/branding-context';

let buildConfig = require('../buildSettings');

export const useLogout = () => {
  const { setUser } = useContext(UserContext);
  const { isPoolService } = useContext(BrandingContext);
  const ldClient = useLDClient();
  const { instance } = useMsal();

  const handleLogout = () => {
    setUser(undefined);
    userLogout();
    ldClient?.identify({ key: 'anonymous', anonymous: true }); // Reset to anonymous flag detection and prevent flash of old branding on login screen
  };

  const handleEntraLogout = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: `${buildConfig.REACT_APP_MSAL_REDIRECT}`,
      });
      handleLogout();
    } catch (error) {
      console.error(error);
    }
  };
  if (isPoolService) {
    return handleEntraLogout;
  }
  return handleLogout;
};
