import { Box, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useContext, useState } from 'react';
import { CardTitle, Card, useDataGrid, GridDataFetcher, Loader } from '../../../../components';
import { ICommunicationTemplateInsert } from '../../../../models';
import { deleteCommunicationInsert, getCommunicationInserts } from '../../../../fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../../../context';
import { FollowUpNotesDataGrid } from './follow-up-notes-data-grid';
import { AddEditFollowUpNotesModal } from './add-edit-follow-up-notes-modal';
import { useConfirm } from '../../../../hooks';

interface IFollowUpNotesCardProps {
  communicationTemplateId: string;
  overrideExpand?: boolean;
}

export const FollowUpNotesCard: FC<IFollowUpNotesCardProps> = ({
  communicationTemplateId,
  overrideExpand,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const confirm = useConfirm();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedFollowUpNote, setSelectedFollowUpNote] =
    useState<ICommunicationTemplateInsert | null>(null);
  const [isDeletingFollowUpNote, setIsDeletingFollowUpNote] = useState(false);
  const dataFetcher: GridDataFetcher<ICommunicationTemplateInsert> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res = await getCommunicationInserts(communicationTemplateId, {
          sortBy: sortColumn || 'sortOrder',
          sortDirection: sortDirection || 'asc',
          page: page + 1,
          perPage,
          officeId: user?.officeId,
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading follow-up notes, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ICommunicationTemplateInsert>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'email-template-follow-up-notes-grid',
      sortColumn: 'sortOrder',
      sortDirection: 'asc',
    },
    dataFetcher,
  });
  const handleEdit = async (followUpNote: ICommunicationTemplateInsert) => {
    setModalOpen(true);
    setSelectedFollowUpNote(followUpNote);
  };
  const handleDelete = async (followUpNote: ICommunicationTemplateInsert) => {
    const result = await confirm('Are you sure you want to delete this follow up note?');
    if (result) {
      try {
        setIsDeletingFollowUpNote(true);
        await deleteCommunicationInsert(
          communicationTemplateId,
          followUpNote.emailTemplateInsertId
        );
        enqueueSnackbar('Successfully deleted follow up note!', {
          variant: 'success',
        });
        refetch();
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error deleting follow up note, please try again.`, {
          variant: 'error',
        });
      } finally {
        setIsDeletingFollowUpNote(false);
      }
    }
  };
  return (
    <Box marginTop="1rem">
      <Card>
        <CardTitle
          title="Follow Up Notes"
          withExpand
          overrideExpand={overrideExpand}
          action={
            <Box display="flex" alignItems="center" gap={1}>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  setModalOpen(true);
                }}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add&nbsp;
                <Typography
                  component="span"
                  fontSize="inherit"
                  lineHeight="inherit"
                  sx={{ display: { xs: 'none', sm: 'inline' } }}
                >
                  Follow Up&nbsp;
                </Typography>
                Note
              </Button>
            </Box>
          }
        >
          <Box marginTop={-1}>
            {isDeletingFollowUpNote && (
              <Loader type="overlay" position="centered" title="Deleting..." />
            )}
            <FollowUpNotesDataGrid
              loading={isLoading}
              rows={rows}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              refetch={refetch}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
            <AddEditFollowUpNotesModal
              isOpen={isModalOpen}
              communicationTemplateId={communicationTemplateId}
              onClose={(shouldUpdate?: boolean) => {
                if (shouldUpdate) {
                  refetch();
                }
                setSelectedFollowUpNote(null);
                setModalOpen(false);
              }}
              selectedFollowUpNote={selectedFollowUpNote}
            />
          </Box>
        </CardTitle>
      </Card>
    </Box>
  );
};
