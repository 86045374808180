import { Page } from '../../../components';
import { FC } from 'react';
import { AccountingExportDetails } from './account-export-page-details';
import { Paths } from '../../../constants';

export const AccountingExportPage: FC = () => {
  return (
    <Page
      title="Accounting Export"
      breadcrumb={{
        text: 'Billing',
        link: Paths.billing.url,
      }}
    >
      <AccountingExportDetails />
    </Page>
  );
};
