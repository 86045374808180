import { FC, useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { IParameterInputProps, IReportParameter } from '../../models';
import {
  CheckboxGroupParameter,
  CheckboxParameter,
  DateParameter,
  DropdownParameter,
  IntegerParameter,
  MoneyParameter,
  RadioButtonsParameter,
  TextareaParameter,
  TextboxParameter,
} from './parameter-controls';
import { Box, Grid, Button, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faFile, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { CancelIcon } from '../icon';
import { useSnackbar } from 'notistack';
import { validateStartDate } from '../../helpers';

export interface IReportParameterFormProps {
  parameters: IReportParameter[];
  isSubmitting?: boolean;
  isExporting?: boolean;
  onSubmit: (values: Record<string, string>) => unknown;
  onExport: (values: Record<string, string>) => unknown;
  setStartDate?: Dispatch<SetStateAction<string>>;
  setEndDate?: Dispatch<SetStateAction<string>>;
  submitImmediately?: boolean;
  generateOnBlur?: boolean;
  isForm?: boolean;
  handleClose?: () => void;
  isModal?: boolean;
}

export const ReportParameterForm: FC<IReportParameterFormProps> = ({
  parameters,
  isSubmitting,
  isExporting,
  onSubmit,
  onExport,
  setEndDate,
  setStartDate,
  submitImmediately = false,
  generateOnBlur = false,
  isForm,
  handleClose,
  isModal,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState<Record<string, string>>({});
  const isMobile = useMediaQuery('(max-width: 1300px)');
  const [dateRangeField, setDateRangeField] = useState<{
    startDateName: string;
    endDateName: string;
  } | null>(null);
  // Record of date range field name pairs, since reports aren't consistent in date range field name pairings
  // NOTE: if a new report deviates from any of the patterns specified below, the array needs to be updated in order for date range validation to work for the new report
  const dateRangeFieldNames: {
    startDateName: string;
    endDateName: string;
    startMatch: boolean;
    endMatch: boolean;
  }[] = [
    {
      startDateName: 'BegDate',
      endDateName: 'EndDate',
      startMatch: false,
      endMatch: false,
    },
    {
      startDateName: 'startdate',
      endDateName: 'enddate',
      startMatch: false,
      endMatch: false,
    },
    {
      startDateName: 'BeginningStartupDate',
      endDateName: 'EndingStartupDate',
      startMatch: false,
      endMatch: false,
    },
    {
      startDateName: 'DetailDate',
      endDateName: 'EndDate',
      startMatch: false,
      endMatch: false,
    },
    {
      startDateName: 'StartDate',
      endDateName: 'EndDate',
      startMatch: false,
      endMatch: false,
    },
    {
      startDateName: 'BeginDate',
      endDateName: 'EndDate',
      startMatch: false,
      endMatch: false,
    },
    {
      startDateName: 'StartDate1',
      endDateName: 'EndDate1',
      startMatch: false,
      endMatch: false,
    },
    {
      startDateName: 'BeginningDateTime',
      endDateName: 'EndingDateTime',
      startMatch: false,
      endMatch: false,
    },
  ];

  // Check if there are any existing date range fields and then set field names to use for validation
  const detectDateRangeFields = (values: IReportParameter[]) => {
    // If there are no parameters set, reset date field to null and stop process
    if (values.length === 0) {
      return setDateRangeField(null);
    }
    // Checking that for both start and end matches to ensure both necessary values for validation exist
    dateRangeFieldNames.forEach((range, index) => {
      const startMatch = values.some(val => val.parameterName === range.startDateName);
      const endMatch = values.some(val => val.parameterName === range.endDateName);
      // Update the matches array with the start and end matches
      if (startMatch) {
        dateRangeFieldNames[index].startMatch = true;
      }
      if (endMatch) {
        dateRangeFieldNames[index].endMatch = true;
      }
    });
    // Find the first match with both startMatch and endMatch set to true
    const match = dateRangeFieldNames.find(m => m.startMatch && m.endMatch);
    // Set date range field based on matches for use with date range validation onSubmit
    setDateRangeField(match ?? null);
  };
  const validateDateRange = (values: Record<string, string>) => {
    // Get the param's reportParameterId's, which are used directly in the values useState
    const startDateKey = parameters?.find(
      param => param.parameterName === dateRangeField?.startDateName
    )?.reportParameterId;
    const endDateKey = parameters?.find(
      param => param.parameterName === dateRangeField?.endDateName
    )?.reportParameterId;

    // Get the proper start and end date Date values
    const startDate =
      startDateKey && !!values[startDateKey] ? new Date(values[startDateKey]) : undefined;
    const endDate = endDateKey && !!values[endDateKey] ? new Date(values[endDateKey]) : undefined;

    if (!startDate && endDate) {
      // Must provide a start date when defining end date
      return false;
    }
    if (startDate && endDate) {
      // Validate that start is before end date
      return validateStartDate(startDate, endDate, true);
    }
    return true; // Default to valid; dates are allowed to be null
  };

  // Perform validation check and error messaging onSubmit/onExport
  const onSubmitValidation = (
    onSubmit: (values: Record<string, string>) => void,
    values: Record<string, string>
  ) => {
    // Only run if there is a date range field for known start/end date field names
    if (!!dateRangeField) {
      const isValidDateRange = validateDateRange(values);
      if (isValidDateRange) {
        return onSubmit(values);
      }
      enqueueSnackbar('Start Date must be before End Date', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    detectDateRangeFields(parameters); // Check to see if there is a need for date range validation onSubmit
    const initialValues = {} as Record<string, string>;
    parameters.forEach(param => {
      let defaultValue = param.savedValue ?? param.defaultValue;

      if (param.selectListItems.length) {
        switch (param.parameterType) {
          case 'checkboxgroup':
            const selectedItems = param.selectListItems.filter(item => item.selected);
            if (selectedItems.length) {
              defaultValue = selectedItems.map(item => item.value).toString();
            }
            break;
          default:
            const selectedItem = param.selectListItems.find(item => item.selected);
            if (selectedItem && !defaultValue) {
              defaultValue = selectedItem.value;
            }
            break;
        }
      }

      if (!defaultValue && param.selectListItems.length) {
        defaultValue = param.selectListItems[0].value;
      }

      initialValues[param.reportParameterId] = defaultValue;
    });

    setValues(initialValues);

    if (submitImmediately && !isSubmitting) {
      onSubmitValidation(onSubmit, initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  const handleChange = (value: string, param: IReportParameter) => {
    if (
      setStartDate &&
      param.parameterName === 'DetailDate' &&
      `${new Date(value)?.getFullYear()}`?.length === 4
    ) {
      setStartDate(value);
    }
    if (
      setEndDate &&
      param.parameterName === 'EndDate' &&
      `${new Date(value)?.getFullYear()}`?.length === 4
    ) {
      setEndDate(value);
    }
    setValues({
      ...values,
      [param.reportParameterId]: value,
    });
  };

  const handleSubmit = useCallback(() => {
    onSubmitValidation(onSubmit, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, values]);

  const handleExportSubmit = useCallback(() => {
    onSubmitValidation(onExport, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onExport, values]);

  const renderParam = (param: IReportParameter) => {
    const value = values[param.reportParameterId] || '';
    let ParamInput: FC<IParameterInputProps> | undefined;
    switch (param.parameterType) {
      case 'checkbox':
        ParamInput = CheckboxParameter;
        break;
      case 'checkboxgroup':
        ParamInput = CheckboxGroupParameter;
        break;
      case 'radiobuttons':
        ParamInput = RadioButtonsParameter;
        break;
      case 'dropdown':
        ParamInput = DropdownParameter;
        break;
      case 'textarea':
        ParamInput = TextareaParameter;
        break;
      case 'datetime':
      case 'date':
        ParamInput = DateParameter;
        break;
      case 'datetime2':
        ParamInput = DateParameter;
        break;
      case 'money':
        ParamInput = MoneyParameter;
        break;
      case 'integer':
        ParamInput = IntegerParameter;
        break;
      case 'textbox':
      case 'string':
        ParamInput = TextboxParameter;
        break;
    }
    if (!ParamInput) {
      return null;
    }
    return (
      <ParamInput
        parameter={param}
        value={value}
        onChange={handleChange}
        onBlur={() => {
          generateOnBlur && !isSubmitting && handleSubmit();
        }}
      />
    );
  };

  const renderChildren = () => {
    return (
      <>
        {parameters?.length > 0 && (
          <Grid container spacing={2}>
            {parameters
              .filter(p => !p.hidden)
              .map(param => (
                <Grid item xs={12} key={param.reportParameterId}>
                  {renderParam(param)}
                </Grid>
              ))}
          </Grid>
        )}
        {parameters?.length > 0 && !generateOnBlur && (
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent={isModal ? 'flex-end' : 'center'}
            gap={1}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            {handleClose && (
              <Button
                type="button"
                color="inherit"
                disabled={isSubmitting}
                onClick={handleClose}
                startIcon={<CancelIcon />}
                fullWidth={isMobile}
              >
                Cancel
              </Button>
            )}
            <Button
              type="button"
              color="secondary"
              disabled={isSubmitting || isExporting}
              onClick={handleSubmit}
              fullWidth={isMobile}
              startIcon={
                isSubmitting ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  <FontAwesomeIcon icon={faFile} />
                )
              }
            >
              {isSubmitting ? 'Generating' : 'Generate'}
            </Button>
            <Button
              type="button"
              color="primary"
              disabled={isExporting || isSubmitting}
              onClick={handleExportSubmit}
              fullWidth={isMobile}
              startIcon={
                isExporting ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  <FontAwesomeIcon icon={faFileExport} />
                )
              }
            >
              {isExporting ? 'Exporting' : 'Export'}
            </Button>
          </Box>
        )}
      </>
    );
  };
  return <>{isForm ? <form>{renderChildren()}</form> : renderChildren()}</>;
};
