import { faMap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
  Box,
  Button,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import { format } from 'date-fns';
import { FC, useContext, useState, useEffect, useMemo, Fragment } from 'react';
import { UserContext } from '../../../context';
import {
  ICalendarView,
  IRouteUpdateMode,
  IServiceChange,
  IServiceRoute,
  ITechnician,
  ITechOptimizationEvent,
} from '../../../models';
import { getServiceRouteDetails } from '../../../fetch';
import { formatDate, parseCalendarDate, isValidDate } from '../../../helpers';
import { theme } from '../../../styles';
import { DatePicker, Loader, Card } from '../../../components';
import { useSnackbar } from 'notistack';
import { useConfirm } from '../../../hooks';
import { PodServiceAccordion } from './pod-service-accordion';

export interface IPod {
  route: IServiceRoute;
  routeIndex?: number;
  saving?: boolean;
  hideEditButton?: boolean;
  condense?: boolean;
  showAllTechsButton?: boolean;
  selectedTechs?: ITechnician[];
  toggleSelection?: (
    e: React.KeyboardEvent,
    cheked: boolean,
    podId: string,
    techServices: string[]
  ) => void;
  selectedDraggableIds?: string[];
  activeDraggableId?: string | null;
  onMapClick?: (routeIndex: number) => void;
  updateMode?: IRouteUpdateMode;
  changes?: Record<string, IServiceChange>;
  readonly?: boolean;
  allowMultiDrag?: boolean;
  showServiceIndex?: boolean;
  serviceIndexStyle?: 'avatar' | 'inline';
  allowOptimization?: boolean;
  onOptimizationClick?: (event: ITechOptimizationEvent) => unknown;
  setSingleViewServiceDate?: React.Dispatch<React.SetStateAction<string>>;
  isSingleViewMode?: boolean;
  setOptimizedTech?: React.Dispatch<React.SetStateAction<ITechnician | undefined>>;
  optimizedTech?: ITechnician;
  isCompareView?: boolean;
  setUpdatedRoutes?: (data: IServiceRoute[]) => void;
  serviceRoutes?: IServiceRoute[];
  hasChanges?: boolean;
  colorizeSiteIndex?: boolean;
  view?: ICalendarView;
  serviceDate: string;
  handleUpdateSelectedDraggableIds?: (ids: string[]) => void;
}

export const Pod: FC<IPod> = ({
  route,
  hideEditButton = false,
  routeIndex = 0,
  saving,
  condense = false,
  showAllTechsButton = false,
  selectedTechs,
  toggleSelection,
  selectedDraggableIds,
  activeDraggableId,
  onMapClick,
  updateMode = 'Single',
  changes,
  readonly = false,
  allowMultiDrag = true,
  showServiceIndex = false,
  serviceIndexStyle = 'avatar',
  allowOptimization = true,
  onOptimizationClick,
  setSingleViewServiceDate,
  isSingleViewMode,
  setOptimizedTech,
  optimizedTech,
  isCompareView,
  setUpdatedRoutes,
  serviceRoutes,
  hasChanges,
  colorizeSiteIndex = false,
  view = ICalendarView.Day,
  serviceDate,
  handleUpdateSelectedDraggableIds,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isPrint = useMediaQuery('print');
  const isCondensed = isPrint ? true : condense;
  const [showAllTechs, setShowAllTechs] = useState(false);
  const [isLoadingSelectedRoute, setIsLoadingSelectedRoute] = useState(false);
  const [isUpdatingService, setIsUpdatingService] = useState(false);
  const { isServiceTech, user } = useContext(UserContext);
  const [techs, setTechs] = useState<ITechnician[]>([]);

  const confirm = useConfirm();
  const hasNoServiceRoutes = useMemo(() => {
    return route.technicians.filter(tech => tech.services.length > 0).length === 0;
  }, [route]);

  useEffect(() => {
    if (selectedTechs?.length) {
      const selected: Record<string, boolean> = selectedTechs.reduce(
        (accumulator, currentValue) => ({ ...accumulator, [currentValue.userId]: true }),
        {}
      );
      setTechs(route.technicians.filter(tech => selected[tech.userId]));
      return;
    }

    setTechs(
      showAllTechs
        ? // Temp solution - Only show techs with "Service Tech" selected from Tech management screen
          // https://dev.azure.com/mwks/internal/_workitems/edit/229108
          route.technicians.filter(tech => tech.isServiceTech || tech.services.length > 0)
        : route.technicians.filter(
            tech =>
              // https://dev.azure.com/mwks/internal/_workitems/edit/215387
              // https://dev.azure.com/mwks/internal/_workitems/edit/228593
              // Always show all active and available service technicians on page load and that have services assigned no matter what their tech status is - even disabled users still show
              // tech.services.length > 0
              //   ? true
              //   : (tech?.isAvailable &&
              //     !tech?.isDisabled &&
              //     !tech.hasDayOff &&
              //     (tech.isRepairTech || tech.isServiceTech)
              //   ) && tech.services.length > 0

              // Temp solution - Only show techs with "Service Tech" selected from Tech management screen,
              // unless they already have services assigned
              // https://dev.azure.com/mwks/internal/_workitems/edit/229108
              ((tech?.isAvailable && !tech?.isDisabled && !tech.hasDayOff && tech.isServiceTech) ||
                (!tech?.isDisabled && !tech.hasDayOff)) &&
              tech.services.length > 0
          )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.technicians, showAllTechs, selectedTechs]);

  const handleOptimizationClick = (e: any, tech: ITechnician) => {
    e.stopPropagation();
    if (!allowOptimization || !onOptimizationClick) {
      return;
    }
    onOptimizationClick({ tech, route });
  };

  const handleSiteChange = async (id: string | number) => {
    try {
      setIsUpdatingService(true);
      const res = await getServiceRouteDetails(id);
      if (serviceRoutes) {
        // update the current array index with the new routes data
        serviceRoutes[routeIndex] = res;
        setUpdatedRoutes?.(serviceRoutes);
      }
    } catch (error) {
    } finally {
      setIsUpdatingService(false);
    }
  };

  const handleCompareModeDateChange = async (date: Date) => {
    if (isValidDate(date)) {
      const reloadDates = async () => {
        try {
          setIsLoadingSelectedRoute && setIsLoadingSelectedRoute(true);
          // get the tickId
          const ticksId = date.getTime() * 10000 + 621355968000000000;
          const route = await getServiceRouteDetails(ticksId, {
            officeId: user?.officeId,
          });
          if (route) {
            // update the current array index with the new routes data
            serviceRoutes![routeIndex] = route;
            setUpdatedRoutes?.(serviceRoutes!);
          } else {
            // handle setting empty pod if no services
            serviceRoutes![routeIndex] = {
              nextRouteId: '',
              previousRouteId: '',
              officeId: '',
              routeId: '',
              serviceDate: date.toISOString(),
              technicians: [],
            };
            setUpdatedRoutes?.(serviceRoutes!);
          }
        } catch (error: any) {
          enqueueSnackbar(error?.Detail ?? `Error loading service date, ${formatDate(date)}.`, {
            variant: 'error',
          });
        } finally {
          setIsLoadingSelectedRoute && setIsLoadingSelectedRoute(false);
        }
      };
      // user made services changes via drag and drop, need to confirm they want to change the date.
      if (hasChanges) {
        const result = await confirm(
          'You have unsaved changes, are you sure you want to change date?'
        );
        if (result) {
          reloadDates();
        }
      } else {
        reloadDates();
      }
    }
  };

  const renderServiceDate = () => {
    return (
      <Typography
        sx={{ fontWeight: 700, fontSize: 14, color: theme.palette.primary.main }}
        variant="body1"
      >
        {format(parseCalendarDate(route.serviceDate), 'EEE M/d/yyyy')}
      </Typography>
    );
  };
  // they can only change services in the first day of the routes per AC
  // "Only let them change the first card to resolve recurring mode issues."
  const podReadOnlyInCompareMode = useMemo(
    () => isCompareView && routeIndex !== 0,
    [isCompareView, routeIndex]
  );
  return (
    <>
      <StyledCard>
        {(isLoadingSelectedRoute || isUpdatingService) && (
          <Loader type="overlay" position="centered" />
        )}
        <CardHeader>
          <DateAndMapWrapper>
            {isCompareView && !isSingleViewMode && serviceRoutes && (
              <DatePicker
                format="EE MM/dd/yyyy"
                onChange={(date: Date) => {
                  handleCompareModeDateChange(date);
                }}
                showClearButton={false}
                shouldDisableDate={(date: any) => {
                  const currentDates =
                    serviceRoutes && serviceRoutes.map(s => formatDate(s.serviceDate));
                  return currentDates?.includes(formatDate(date));
                }}
                onKeyDown={(e: any) => {
                  e.preventDefault();
                }}
                value={route.serviceDate ? new Date(route.serviceDate) : null}
                slotProps={{
                  textField: {
                    size: 'small',
                    error: false,
                    readOnly: true,
                    InputLabelProps: { shrink: true },
                  },
                }}
              />
            )}
            {!isCompareView && !isSingleViewMode && (
              <Button
                variant="text"
                color="primary"
                sx={{ padding: 0 }}
                onClick={() => setSingleViewServiceDate?.(route.serviceDate as string)}
                disabled={hasNoServiceRoutes}
                data-testid="service-date-field"
              >
                {renderServiceDate()}
              </Button>
            )}
            {!isCompareView && isSingleViewMode && renderServiceDate()}
            {!hideEditButton && (
              <MapSection style={isCompareView ? { textAlign: 'left' } : undefined}>
                <Button
                  onClick={() => onMapClick?.(routeIndex as number)}
                  className={clsx('print--none')}
                  color="primary"
                  disabled={saving || hasNoServiceRoutes}
                  variant="contained"
                  size="small"
                  startIcon={<FontAwesomeIcon icon={faMap} size="lg" />}
                  data-testid="map-button"
                >
                  Map
                </Button>
              </MapSection>
            )}
          </DateAndMapWrapper>

          {showAllTechsButton && !isServiceTech && (
            <Box sx={{ width: '100%' }}>
              <ShowAllTechs
                sx={{ marginLeft: '-8px' }}
                control={
                  <Checkbox
                    checked={showAllTechs}
                    id="emptyTechs-checkbox"
                    disabled={saving}
                    sx={{ padding: '5px' }}
                    onClick={() => {
                      setShowAllTechs(!showAllTechs);
                    }}
                  />
                }
                label="Show all techs"
              />
            </Box>
          )}
        </CardHeader>
        <div>
          {techs.map((tech: ITechnician, techIndex) => {
            return (
              <Fragment key={`${tech.userId}-${techIndex}`}>
                <PodServiceAccordion
                  tech={tech}
                  techIndex={techIndex}
                  routeIndex={routeIndex}
                  route={route}
                  podReadOnlyInCompareMode={podReadOnlyInCompareMode}
                  allowOptimization={allowOptimization}
                  handleOptimizationClick={handleOptimizationClick}
                  handleSiteChange={handleSiteChange}
                  isCondensed={isCondensed}
                  view={view}
                  updateMode={updateMode}
                  serviceDate={serviceDate}
                  changes={changes}
                  readonly={readonly}
                  selectedDraggableIds={selectedDraggableIds}
                  activeDraggableId={activeDraggableId}
                  toggleSelection={toggleSelection}
                  showServiceIndex={showServiceIndex}
                  serviceIndexStyle={serviceIndexStyle}
                  colorizeSiteIndex={colorizeSiteIndex}
                  allowMultiDrag={allowMultiDrag}
                  saving={saving}
                  onOptimizationClick={onOptimizationClick}
                  handleUpdateSelectedDraggableIds={handleUpdateSelectedDraggableIds!}
                />
              </Fragment>
            );
          })}
        </div>
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '@media print': {
    'page-break-inside': 'avoid',
    marginBottom: '3rem',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  alignItems: 'center',
  borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.secondary.light}`,
}));

const DateAndMapWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  '& .btn-text': {
    padding: '0',
  },
  '@media (min-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  '@media (min-width: 1500px)': {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const MapSection = styled(Box)(() => ({
  marginBottom: '5px',
  '@media (min-width: 600px)': {
    flex: '1 1 auto',
  },
  '@media (min-width: 1500px)': {
    textAlign: 'right',
  },
}));

const ShowAllTechs = styled(FormControlLabel)(({ theme }) => ({
  '&& .MuiFormControlLabel-label': {
    fontSize: '0.85rem',
  },
}));
