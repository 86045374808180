import { Page } from '../../components';
import { TransactionBatchDetails } from './transaction-batch-details';
import { IBreadcrumb } from '../../models';
import { useState } from 'react';
import { Paths } from '../../constants';

export const TransactionBatchPage = () => {
  const [pageBreadCrumb, setPageBreadCrumb] = useState<IBreadcrumb | undefined>(undefined);
  return (
    <Page
      title="Transaction Batch"
      breadcrumb={
        pageBreadCrumb
          ? pageBreadCrumb
          : {
              text: 'Payments',
              title: 'Back to Payments',
              link: Paths.payments.url,
            }
      }
    >
      <TransactionBatchDetails setPageBreadCrumb={setPageBreadCrumb} />
    </Page>
  );
};
