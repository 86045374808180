import { axios } from '../helpers';
import {
  ICreateScheduledService,
  IResponse,
  IScheduledServiceDetail,
  IServiceSuggestionChecklist,
  IServiceSuggestionResponse,
  ServiceSuggestionType,
  IScheduledServiceLog,
  IUpdateScheduledService,
  IPaginatedResponse,
  IScheduledService,
  IEnRouteNotification,
  ICreateNextEnRouteNotification,
} from '../models';
import qs from 'qs';
import { userSignal } from '../signals';
import { AxiosResponse } from 'axios';

const API_PATH = '/api/scheduled-services' as const;

export const getScheduledServices = async (filters?: {
  userIds?: string;
  startDate?: string;
  endDate?: string;
  sortBy?: string;
  status?: string[] | string;
  sortDirection?: string;
  siteId?: string;
  first?: boolean;
  last?: boolean;
  before?: string;
  after?: string;
  perPage?: number;
  officeId?: string;
  serviceDefId?: string;
  accountId?: string;
  userGroupId?: string;
}): Promise<IPaginatedResponse<IScheduledService>> => {
  try {
    const { data } = await axios.get(API_PATH, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getScheduledService = async (
  id: string | number,
  officeId?: string | null
): Promise<IScheduledServiceDetail | any> => {
  try {
    const { data } = await axios.get(`${API_PATH}/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateScheduledServices = async (
  id: number | string,
  payload: IUpdateScheduledService,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.put(`${API_PATH}/${id}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createScheduledServices = async (
  payload: ICreateScheduledService,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.post(`${API_PATH}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getRouteSuggestions = async (filters?: {
  routeSuggestionType: ServiceSuggestionType;
  siteId: string;
  officeId?: string | null;
  startDate: string;
  endDate?: string;
  excludeScheduledServiceId?: string | null;
  excludeRecurringServiceId?: string | null;
}): Promise<IServiceSuggestionResponse> => {
  try {
    const { data } = await axios.get(`${API_PATH}/suggestions`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getScheduledServiceChecklist = async (
  serviceId: string | number,
  serviceDefId: string | number,
  officeId?: string | null
): Promise<IServiceSuggestionChecklist[]> => {
  try {
    const { data } = await axios.get(
      `${API_PATH}/${serviceId}/checklist?serviceDefId=${serviceDefId}`,
      {
        params: {
          officeId: officeId ?? userSignal.value?.officeId ?? '',
        },
        paramsSerializer: params => qs.stringify(params),
      }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getScheduledServiceServiceLog = async (
  serviceId: string | number,
  officeId?: string | null
): Promise<IResponse<IScheduledServiceLog[]>> => {
  try {
    const { data } = await axios.get(`${API_PATH}/${serviceId}/service-logs`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createScheduledServiceServiceLog = async (
  serviceId: string | number,
  description: string,
  officeId?: string | null
): Promise<number> => {
  try {
    const { data } = await axios.post(`${API_PATH}/${serviceId}/service-logs`, {
      description,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateScheduledServiceServiceLog = async (
  serviceId: string | number,
  serviceLogId?: string | number,
  description?: string,
  officeId?: string | null
): Promise<any> => {
  try {
    const { data } = await axios.put(`${API_PATH}/${serviceId}/service-logs/${serviceLogId}`, {
      description,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteScheduledService = async (
  serviceId: string | number | undefined
): Promise<any> => {
  try {
    const { data } = await axios.delete(`${API_PATH}/${serviceId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNextEnRouteInfo = async (
  scheduledServiceId: string | number,
  officeId?: string | null
): Promise<AxiosResponse<IEnRouteNotification>> => {
  try {
    const res = await axios.get(`${API_PATH}/en-route-information`, {
      params: {
        scheduledServiceId,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNextEnRouteNotification = async (
  scheduledServiceId: string | number,
  communicationTemplateId: string,
  officeId?: string | null
): Promise<IEnRouteNotification> => {
  try {
    const { data } = await axios.get(`${API_PATH}/next-en-route-notification`, {
      params: {
        scheduledServiceId,
        communicationTemplateId,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createNextEnRouteNotification = async (
  nextScheduledServiceId: string | number,
  communicationTemplateId: string,
  payload: ICreateNextEnRouteNotification,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`${API_PATH}/queue-en-route-notification`, payload, {
      params: {
        nextScheduledServiceId,
        communicationTemplateId,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getSuppressEnRouteNotification = async (): Promise<boolean> => {
  try {
    const { data } = await axios.get(`${API_PATH}/is-en-route-notification-suppressed`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const suppressEnRouteNotification = async (): Promise<string> => {
  try {
    const { data } = await axios.post(`${API_PATH}/suppress-en-route-notification`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
