import { FC } from 'react';
import { useQuery } from 'react-query';
import { DisplayGroup } from '../../../components';
import { getCommunicationTemplateParams } from '../../../fetch';
import { IDropdownResponse } from '../../../models';
import { Box, styled } from '@mui/material';

interface IEmailTemplateSubstitutions {
  selectedEmailEvent: string;
}

export const EmailTemplateSubstitutions: FC<IEmailTemplateSubstitutions> = ({
  selectedEmailEvent,
}) => {
  const { data: currentEmailEventParams } = useQuery<IDropdownResponse[], Error>(
    ['getEmailTemplateEventParams', selectedEmailEvent],
    () => getCommunicationTemplateParams({ emailEvent: selectedEmailEvent! }),
    {
      enabled: !!selectedEmailEvent,
    }
  );

  return (
    <Wrapper>
      <DisplayGroup
        label="Substitutions"
        component="div"
        labelId="substitutions"
        className={classes.substitutionsList}
      >
        {currentEmailEventParams?.map((v, index) => {
          return (
            <p key={`${index}`}>
              &#123;{index}&#125; {v.value}
            </p>
          );
        })}
      </DisplayGroup>
    </Wrapper>
  );
};

const PREFIX = 'EmailTemplateSubstitutions';

const classes = {
  substitutionsList: `${PREFIX}-substitutionsList`,
};

const Wrapper = styled(Box)(({ theme }) => ({
  [`& .${classes.substitutionsList}`]: {
    '& > p': {
      marginBottom: 0,
    },
  },
}));
