import {
  SelectAsync,
  TextField,
  MultipleEmailInput,
  Wysiwyg,
  Card,
  ModalSaveSection as FormSaveSection,
  Loader,
  ConfirmPrompt,
  CardTitle,
  Switch,
} from '../../../components';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  FormHelperText,
  TextField as MuiTextField,
  Tooltip,
  Alert,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { defaultUnsavedChangesMessage, Paths } from '../../../constants';
import {
  getCommunicationTemplateEventTypes,
  createCommunicationTemplate,
  updateCommunicationTemplate,
  getCommunicationTemplate,
  getCommunicationTemplateCampaigns,
} from '../../../fetch';
import {
  IDropdownResponse,
  ICommunicationTemplateDetail,
  EMAIL_EVENTS,
  ISelectOption,
} from '../../../models';
import { UserContext } from '../../../context';
import { deepEqual } from 'fast-equals';
import { EmailTemplateSubstitutions } from './communication-template-subsitutions';
import { FollowUpNotesCard } from './follow-up-notes';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const FORM_VALIDATION = Yup.object().shape({
  emailEvent: Yup.string().required('Required'),
  campaignId: Yup.mixed().when('emailEvent', {
    is: (val: string) => val === EMAIL_EVENTS.ServiceCompletion,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  templateName: Yup.string().required('Required').max(30, 'Max 30 characters'),
  sortOrder: Yup.number().nullable(),
  isSuppressed: Yup.boolean(),
  isSmsAllowModify: Yup.boolean(),
  emailBccs: Yup.array(Yup.string().nullable()).nullable(),
  emailSubject: Yup.string().required('Required'),
  smsBody: Yup.mixed().when('textDetails', {
    is: (val: boolean) => val,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  emailBody: Yup.string().required('Required'),
  textDetails: Yup.boolean(),
});

const CAMPAIGN_BANNER_GENERIC_DEFAULT = '84e0bc7c-29b2-45e4-8db5-7a27c1b46007';
const DEFAULT_SERVICE_COMPLETION_MESSAGE = 'Your scheduled services have been completed.';
const DEFAULT_IN_ROUTE_MESSAGE = `Good news! {0} is on the way to you and will be there in the next {1} to {2} minutes.`;

export const CommunicationTemplatesDetailsPage = () => {
  const { user } = useContext(UserContext);
  const { communicationTemplateId }: { communicationTemplateId: string } = useParams();
  const isNewTemplate = communicationTemplateId === 'new';
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean | undefined>(true);
  const [systemEvents, setSystemEvents] = useState<ISelectOption[] | null>(null);
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  const { isLoading, data: currentTemplate } = useQuery<ICommunicationTemplateDetail, Error>(
    ['getCommunicationTemplate', communicationTemplateId],
    () => getCommunicationTemplate(communicationTemplateId!),
    {
      enabled: !!communicationTemplateId && communicationTemplateId !== 'new',
    }
  );
  return (
    <>
      <Box
        mb={1}
        mt={-2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        className="print--none"
      >
        <Button
          variant="text"
          onClick={() => setIsAllExpanded(!isAllExpanded ? true : false)}
          startIcon={isAllExpanded ? <ExpandLess /> : <ExpandMore />}
        >
          {isAllExpanded ? 'Collapse All' : 'Expand All'}
        </Button>
      </Box>
      {(isLoading || isUpdating) && <Loader position="centered" type="overlay" />}
      <Formik
        enableReinitialize
        initialValues={{
          emailEvent: currentTemplate?.event ?? EMAIL_EVENTS.ServiceCompletion,
          templateName: currentTemplate?.name ?? '',
          sortOrder:
            currentTemplate?.sortOrder && currentTemplate?.sortOrder !== null
              ? `${currentTemplate?.sortOrder!}`
              : '',
          isSuppressed: currentTemplate?.isSuppressed ?? false,
          emailBccs: currentTemplate?.emailBccs ?? [],
          emailSubject: currentTemplate?.emailSubject ?? '',
          emailBody: currentTemplate?.emailBody ?? '',
          smsBody: currentTemplate?.smsBody ?? '',
          campaignId: currentTemplate?.campaignId ?? CAMPAIGN_BANNER_GENERIC_DEFAULT,
          emailCampaignId: currentTemplate?.emailCampaignId ?? '',
          textDetails: currentTemplate?.smsBody ? true : false,
          isSmsAllowModify: currentTemplate?.isSmsAllowModify ?? false,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async ({ textDetails, ...values }) => {
          try {
            setIsUpdating(true);
            if (isNewTemplate) {
              await createCommunicationTemplate({
                ...values,
                sortOrder: values.sortOrder || 0,
                officeId: user?.officeId!,
              });
              enqueueSnackbar('Template was created successfully!', {
                variant: 'success',
              });
            } else {
              await updateCommunicationTemplate(currentTemplate?.communicationTemplateId!, {
                ...values,
                sortOrder: values.sortOrder || 0,
                officeId: user?.officeId!,
              });
              enqueueSnackbar('Template updated!', {
                variant: 'success',
              });
            }
            history.replace(redirect ?? Paths.communicationTemplates.url);
          } catch (err: any) {
            enqueueSnackbar(
              err?.Detail ||
                `Error ${isNewTemplate ? 'creating' : 'editing'} template, please try again.`,
              { variant: 'error' }
            );
          } finally {
            setIsUpdating(false);
          }
        }}
      >
        {({
          isValid,
          dirty,
          initialValues,
          values,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          handleBlur,
          validateForm,
        }) => {
          return (
            <>
              <ConfirmPrompt
                when={!deepEqual(initialValues, values) && !isSubmitting && !isLoading}
                message={defaultUnsavedChangesMessage}
              />
              <Form onSubmit={handleSubmit}>
                <Card>
                  <CardTitle
                    title="Email/Text Template Details"
                    withExpand
                    overrideExpand={isAllExpanded}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        md={values.emailEvent === EMAIL_EVENTS.InRoute ? 12 : 6}
                        // key here to focrce a re-render when the user switches between text and email
                        // to recall the api and filter out options
                        key={values.textDetails ? 'textDetails' : 'emailEvent'}
                      >
                        <SelectAsync
                          name="emailEvent"
                          label="System Event"
                          value={values.emailEvent}
                          required
                          apiRequest={() => getCommunicationTemplateEventTypes()}
                          transformResponse={(response: IDropdownResponse[]) => {
                            const emailTypesToExclude: string[] = [
                              isNewTemplate || values.textDetails
                                ? EMAIL_EVENTS.PaymentReceipt
                                : '',
                              isNewTemplate || values.textDetails
                                ? EMAIL_EVENTS.PaymentDeclined
                                : '',
                              values.textDetails ? EMAIL_EVENTS.MassEmail : '',
                            ];

                            const filteredEmailTypes = response
                              .filter(item => !emailTypesToExclude.includes(item.value))
                              .map(record => ({
                                label: record.description,
                                value: record.value,
                              }));

                            return filteredEmailTypes;
                          }}
                          onChange={e => {
                            setFieldValue('emailEvent', e.target.value);
                            const selected = systemEvents?.find(
                              system => system.value === e.target.value
                            );
                            // only change the default if it hasn't been already changed by the user
                            if (values.textDetails && values.smsBody !== selected?.label) {
                              setFieldValue(
                                'smsBody',
                                values.emailEvent === EMAIL_EVENTS.ServiceCompletion
                                  ? DEFAULT_SERVICE_COMPLETION_MESSAGE
                                  : DEFAULT_IN_ROUTE_MESSAGE
                              );
                            } else {
                              setFieldValue('smsBody', '');
                            }
                            if (e.target.value !== EMAIL_EVENTS.ServiceCompletion) {
                              setFieldValue('campaignId', '');
                            }
                            // clear the text option if they change system event
                            if (
                              e.target.value !== EMAIL_EVENTS.InRoute &&
                              e.target.value !== EMAIL_EVENTS.ServiceCompletion &&
                              values.textDetails
                            ) {
                              setFieldValue('textDetails', false);
                            }
                          }}
                          handleOptions={val => setSystemEvents(val)}
                          error={touched?.emailEvent && !!errors?.emailEvent}
                          helperText={touched?.emailEvent && errors?.emailEvent}
                        />
                      </Grid>

                      {(values.emailEvent === EMAIL_EVENTS.ServiceCompletion ||
                        values.emailEvent === EMAIL_EVENTS.InRoute) && (
                        <>
                          {values.emailEvent === EMAIL_EVENTS.ServiceCompletion && (
                            <Grid item xs={12} md={6}>
                              <SelectAsync
                                name="campaignId"
                                label="Campaign Banner"
                                value={values.campaignId}
                                required
                                apiRequest={() => getCommunicationTemplateCampaigns()}
                                transformResponse={(response: IDropdownResponse[]) => {
                                  return response.map(record => ({
                                    label: record.description,
                                    value: record.value,
                                  }));
                                }}
                                error={touched?.campaignId && !!errors?.campaignId}
                                helperText={touched?.campaignId && errors?.campaignId}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Template Name"
                          name="templateName"
                          required
                          value={values.templateName}
                          error={touched?.templateName && !!errors?.templateName}
                          helperText={touched?.templateName && errors?.templateName}
                          clearValue={!values.templateName}
                          shouldDelay={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          label="Sort Order"
                          name="sortOrder"
                          type="number"
                          value={values.sortOrder}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Do Not Send"
                          name="isSuppressed"
                          checked={values.isSuppressed}
                          onChange={(_, checked) => {
                            setFieldValue('isSuppressed', checked);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Email Subject"
                          name="emailSubject"
                          required
                          error={touched?.emailSubject && !!errors?.emailSubject}
                          helperText={touched?.emailSubject && errors?.emailSubject}
                          shouldDelay={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MultipleEmailInput
                          fullWidth
                          size="small"
                          placeholder="test@example.com"
                          label="BCC"
                          value={values.emailBccs}
                          onChange={(_e, newEmailsValue) => {
                            setFieldValue('emailBccs', newEmailsValue);
                          }}
                          helperText="Use BCC to send a copy of the email to people within your company"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Wysiwyg
                          label="Email Body"
                          required
                          value={values.emailBody}
                          onChange={val => {
                            setFieldValue('emailBody', val);
                            validateForm({
                              templateName: values.templateName ?? '',
                              emailSubject: values.emailSubject ?? '',
                              emailEvent: values.emailEvent ?? '',
                              emailBody: val,
                            });
                          }}
                        />
                      </Grid>
                      {(values.emailEvent === EMAIL_EVENTS.ServiceCompletion ||
                        values.emailEvent === EMAIL_EVENTS.InRoute) && (
                        <Grid
                          item
                          xs={12}
                          md={values.emailEvent === EMAIL_EVENTS.ServiceCompletion ? 4 : 8}
                        >
                          <Switch
                            label="Text Template Details"
                            onChange={(_, checked) => {
                              setFieldValue('textDetails', checked);
                              if (checked) {
                                setFieldValue(
                                  'smsBody',
                                  values.emailEvent === EMAIL_EVENTS.ServiceCompletion
                                    ? DEFAULT_SERVICE_COMPLETION_MESSAGE
                                    : DEFAULT_IN_ROUTE_MESSAGE
                                );
                              } else {
                                validateForm({
                                  templateName: values.templateName ?? '',
                                  emailSubject: values.emailSubject ?? '',
                                  emailBody: values.emailBody ?? '',
                                  emailEvent: values.emailEvent ?? '',
                                });
                                setFieldValue('smsBody', '');
                              }
                            }}
                            name="textDetails"
                          />
                        </Grid>
                      )}
                      {values.textDetails && (
                        <Grid item xs={12}>
                          <MuiTextField
                            label="SMS Body"
                            name="smsBody"
                            required
                            minRows={3}
                            multiline
                            fullWidth
                            size="small"
                            value={values.smsBody}
                            onChange={e => {
                              setFieldValue('smsBody', e.target.value);
                            }}
                            onBlur={handleBlur}
                            error={touched?.smsBody && !!errors?.smsBody}
                            helperText={touched?.smsBody && errors?.smsBody}
                          />
                          <FormHelperText>
                            <Typography component="span" fontWeight="bold" fontSize="12px">
                              Characters left:
                            </Typography>{' '}
                            {values.smsBody.length > 160 ? 0 : 160 - values.smsBody.length}
                          </FormHelperText>
                          {values.smsBody.length > 160 && (
                            <Alert severity="info">
                              SMS messages are limited to 160 characters, multiple messages will be
                              sent if over that amount.
                            </Alert>
                          )}
                        </Grid>
                      )}
                      {values.textDetails && values.emailEvent === EMAIL_EVENTS.InRoute && (
                        <Grid item xs={12}>
                          <Tooltip title="If unchecked, only the time for the message will be editable">
                            <FormControlLabel
                              control={<Checkbox />}
                              label="SMS Editable"
                              name="isSmsAllowModify"
                              checked={values.isSmsAllowModify}
                              onChange={(_, checked) => {
                                setFieldValue('isSmsAllowModify', checked);
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <EmailTemplateSubstitutions selectedEmailEvent={values.emailEvent} />
                      </Grid>
                    </Grid>
                    <FormSaveSection
                      handleCancel={() => {
                        history.push(redirect ?? Paths.communicationTemplates.url);
                      }}
                      isSaveDisabled={!isValid}
                      showBack={!dirty}
                      containerStyles={{ flexDirection: { xs: 'row' } }}
                    />
                  </CardTitle>
                </Card>
              </Form>
              {!isNewTemplate && (
                <FollowUpNotesCard
                  communicationTemplateId={communicationTemplateId}
                  overrideExpand={isAllExpanded}
                />
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};
