import { stringNumNull } from '../helpers/util';

export interface IEstimate {
  estimateId: string | number;
  estimateNumber: string | number;
  whenCreated: string;
  status: string | null;
  details: string | null;
  createdBy: string | null;
  userId: string;
  total: string | number;
  serviceTypeId: null;
  serviceType: null;
  customAgreementId: null;
  customAgreement: null;
  accountId: string;
  accountName: string;
  leadId: string | null;
  leadCustomerName: string | null;
  promptForLaborRate: boolean;
  repairId: string;
  agreementStatus: string;
  repairAgreementLink: string;
  isCreditCardRequired: boolean;
  isSecurityDepositRequired: boolean;
  hasOverridenAgreement: boolean;
  hasApprovalOverride: boolean;
  approvalDate: string;
}

export interface IEstimateDetail extends IEstimate {
  whenInvoiced: string;
  whenExpires?: string;
  account: string;
  site: string;
  termsAndConditions?: string;
  invoiceNumber?: string;
  hasEstimateAgreementBeenSigned?: boolean;
  hasSecurityDepositBeenRefundedOrVoided?: boolean;
  securityDepositTransactionDetailId?: string | null;
  isVoidable: boolean;
  isRefundable: boolean;
}

export interface IEstimateForm {
  accountId: string;
  accountName: string;
  additionalTermsAndConditions: string;
  agreementRequired?: boolean;
  agreementStatus?: string;
  chargedLaborAmount?: number;
  chargedLaborDuration?: number;
  customAgreementDescription: string;
  customAgreementId?: string | null;
  details: string;
  emails: string[] | null;
  estimateId?: string;
  estimateNumber?: string;
  estimateStatus: string;
  estimateType?: string;
  estimatedMaterialCost?: number;
  hasEstimateAgreementBeenSigned?: boolean;
  hasSecurityDepositBeenRefundedOrVoided?: boolean;
  hasEstimateAgreementBeenCreated?: boolean;
  hasEstimateAgreementBeenSent?: boolean;
  invoiceNumber: string;
  isCreditCardRequired: boolean;
  isItemized: boolean;
  isRefundable: boolean;
  isVoidable: boolean;
  leadCustomerName: string;
  leadId?: stringNumNull;
  newCustomer?: any;
  newLead?: any;
  promptForLaborRate: boolean;
  repairId?: string;
  securityDeposit?: stringNumNull;
  securityDepositTransactionDetailId?: string | null;
  serviceNotes?: string;
  serviceTypeDescription: string;
  serviceTypeId?: string | null;
  siteId: string;
  siteName: string;
  transactionId: string | null;
  userId: string;
  userName: string;
  whenAgreementSigned: string;
  whenCreated: string;
  whenExpires: string;
  whenInvoiced: string;
  approvalDate: string | null;
  invoiceView: string;
  officeId: string;
  accountEmail: string | null;
  paymentMethodId: string | null;
  invoiceCreated: boolean | null;
  signature: string | null;
  hasApprovalOverride: boolean;
  officeName: string;
  poolCommerceInventoryId: string | null;
}

export interface IEstimatePost {
  estimateNumber: number;
  estimateStatus: string;
  userId: string;
  accountId: string | null;
  siteId: string | null;
  details: string;
  additionalTermsAndConditions: string;
  whenExpires: string;
  leadId?: number | null;
  serviceTypeId?: string | null;
  customAgreementId?: string | null;
  serviceNotes?: string;
  securityDeposit?: number | null;
  isItemized: boolean;
  isCreditCardRequired: boolean;
  repairId?: string;
  ignoreAgreementRequirement?: boolean;
}

export interface IEstimateLineItem {
  estimateLineItemId: string;
  sortOrder: number;
  tranCodeId: string;
  tranCodeDescription?: string;
  lookupCode?: string;
  details: string;
  serialNumber: string;
  rate: number;
  quantity: number;
  amount?: number;
  inventoryId?: number | null;
  externalPartId?: number | null;
  isSerialized?: boolean;
  isLaborLineItem: boolean;
  laborFeeType?: string | null;
  fixedLaborRate?: number | null;
  initialLaborFees?: number | null;
  additionalLaborFees?: number | null;
  initialLaborFeesDuration?: string | null;
  estimatedLaborDuration?: number | null;
  postLaborChargesAs?: number | null;
  poolCommerceInventoryId?: string | null;
}

export interface IPool360LineItem extends IEstimateLineItem {
  isSelected?: boolean;
  cost?: number;
  canAddToCart?: boolean;
}

export interface IEstimateLineItemDetail {
  estimateLineItemId: string;
  sortOrder: number | string;
  tranCodeId: string;
  tranCodeDescription: string;
  lookupCode: string;
  details: string;
  serialNumber: string;
  rate: number | string;
  quantity: number | string;
  amount?: number | string;
  storeInventoryId?: number | string;
  externalPartId?: number | string;
  inventoryDescription?: string;
  isSerialized: boolean | undefined;
  laborFeeType?: string | null;
  fixedLaborRate?: number | null;
  initialLaborFees?: number | null;
  additionalLaborFees?: number | null;
  initialLaborFeesDuration?: string | null;
  estimatedLaborDuration?: number | null;
  estimatedLaborDurationActual?: number | null;
  poolCommerceInventoryId?: string | null;
}

export interface IEstimateLineItemPost {
  estimateLineItemId?: string;
  sortOrder: number | string;
  tranCodeId: string;
  details: string;
  serialNumber: string;
  rate: number | string;
  quantity: number | string;
  amount?: number | string;
  inventoryId?: stringNumNull;
  externalPartId?: stringNumNull;
  poolCommerceInventoryId?: stringNumNull;
}

export interface IEstimateLaborLineItem {
  sortOrder: number;
  details: string;
  laborFeeType: string | null;
  fixedLaborRate: number | null;
  initialLaborFees: number | null;
  additionalLaborFees: number | null;
  initialLaborFeesDuration: string | null;
  estimatedLaborDuration: number | null;
  postLaborChargesAs: number | null;
}

export interface IEstimateSalesTaxLineItem {
  amount: number | string;
  storeInventoryId?: stringNumNull;
  inventoryItemId?: stringNumNull;
}

export interface IEstimateSalesTaxPost {
  officeId?: stringNumNull;
  accountId?: stringNumNull;
  lineItems: IEstimateSalesTaxLineItem[] | null;
}

export interface IEstimateSalesTax {
  rate: number;
  amount: number;
}

export enum LaborFeeTypes {
  Fixed = 'Fixed',
  Variable = 'Variable',
};