import { FC, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';
import { Page, Loader, Link } from '../../../components';
import { getAccount, getAdditionalInfoFields } from '../../../fetch';
import { IAccountDetail, IAdditionalInfoField } from '../../../models';
import { CustomersDetailDisplay } from './CustomersDetailDisplay';
import { CustomersDetailCRUD } from './CustomersDetailCRUD';
import { UserContext } from '../../../context';
import { Paths, Permissions, ROLES } from '../../../constants';
import { getCustomerDetailRoute, hasCorrectUserPermissions } from '../../../helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IPageParams {
  paramsAccountId: string;
}

interface ICustomersDetail {
  currentAccountId?: string;
}

export const CustomersDetail: FC<ICustomersDetail> = ({ currentAccountId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions } = useContext(UserContext);
  const { pathname } = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');
  const needsRefresh = !!currentAccountId || searchParams.get('refresh');
  const history = useHistory();
  const { v2Customers } = useFlags();

  // used for editing/viewing a customer
  const { paramsAccountId } = useParams() as IPageParams;
  const accountId = paramsAccountId ?? currentAccountId;
  // used for creating a new customer
  const isNewCustomer = accountId === 'new';
  const isEditMode = pathname.includes('edit');

  const [currentCustomer, setCurrentCustomer] = useState<IAccountDetail | null>(null);
  const [isLoadingCustomer, setLoadingCustomer] = useState<boolean>(true);
  const [hasCustomerLoaded, setCustomerLoaded] = useState<boolean>(false);

  const [actualSpendingLimit, setActualSpendingLimit] = useState<number>(0);
  const fetchCustomerDetail = async () => {
    if (
      !isNewCustomer &&
      accountId &&
      hasCorrectUserPermissions(Permissions.ViewCustomers, user!)
    ) {
      setLoadingCustomer(true);
      try {
        const res = await getAccount(accountId);
        setCurrentCustomer(res);
        setCustomerLoaded(true);
        // wait till the page is loading before flipping this flag so the form is in correct state
        const timer = setTimeout(() => {
          setCustomerLoaded(false);
        }, 500);

        // Clear timer on unmount
        return () => {
          clearTimeout(timer);
        };
      } catch (error) {
        enqueueSnackbar(`Error loading customer, please try again.`, {
          variant: 'error',
        });
      } finally {
        setLoadingCustomer(false);
      }
    } else {
      setLoadingCustomer(false);
    }
  };

  //Get Additional Info Fields
  const [additionalInfoFields, setAdditionalInfoFields] = useState<IAdditionalInfoField[]>([]);
  const fetchAdditionalInfoFields = async () => {
    if (user?.officeId) {
      try {
        const res = await getAdditionalInfoFields({ perPage: -1, officeId: user?.officeId });
        setAdditionalInfoFields(res.records);
        setCustomerLoaded(true);
        // wait till the page is loading before flipping this flag so the form is in correct state
        setTimeout(() => {
          setCustomerLoaded(false);
        }, 500);
      } catch (error) {
        enqueueSnackbar(`Error loading sales taxes, please try again.`, {
          variant: 'error',
        });
      }
    }
  };
  useEffect(() => {
    fetchCustomerDetail();
    fetchAdditionalInfoFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsRefresh]);

  useEffect(() => {
    if (
      (user?.userType !== ROLES.Emulating &&
        isEditMode &&
        !permissions?.includes(Permissions.EditAccountInfo)) ||
      !v2Customers
    ) {
      enqueueSnackbar('Not the right permissions, redirecting...', {
        variant: 'info',
      });
      history.push('/');
    }
  }, [isEditMode, permissions, enqueueSnackbar, history, v2Customers, user]);
  return (
    <Page
      title={
        isLoadingCustomer
          ? 'Loading...'
          : Boolean(currentCustomer?.accountId)
          ? `${isEditMode ? 'Edit ' : ''}${currentCustomer?.name}` ||
            `${currentCustomer?.firstName} ${currentCustomer?.lastName}`
          : 'New Customer'
      }
      breadcrumb={
        isLoadingCustomer
          ? undefined
          : Boolean(currentCustomer?.accountId) && isEditMode
          ? {
              text:
                currentCustomer?.name ||
                `${currentCustomer?.firstName} ${currentCustomer?.lastName}`,
              title: 'Back to Customer Detail',
              link: redirect?.includes(`${getCustomerDetailRoute(accountId)}`)
                ? redirect
                : `${getCustomerDetailRoute(accountId)}`,
            }
          : {
              text: 'Customers',
              title: 'Back to Customers',
              link: redirect?.includes(Paths.customers.url) ? redirect : Paths.customers.url,
            }
      }
    >
      {isLoadingCustomer && (
        <Box mt={4} mb={2}>
          <Box py={5}>
            <Loader position="centered" />
          </Box>
        </Box>
      )}
      {!currentCustomer && !isLoadingCustomer && !isNewCustomer && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="10rem"
        >
          <Typography>No customer found.</Typography>
          <Link to={redirect?.includes(Paths.customers.url) ? redirect : Paths.customers.url}>
            Back to customers
          </Link>
        </Box>
      )}
      {!isLoadingCustomer && !isEditMode && (
        <CustomersDetailDisplay
          accountId={accountId}
          currentCustomer={currentCustomer}
          isLoadingCustomer={isLoadingCustomer}
        />
      )}
      {!isLoadingCustomer && (isEditMode || isNewCustomer) && (
        <CustomersDetailCRUD
          accountId={accountId}
          isNewCustomer={isNewCustomer}
          isEditing={isEditMode}
          currentCustomer={currentCustomer}
          hasCustomerLoaded={hasCustomerLoaded}
          actualSpendingLimit={actualSpendingLimit}
          setActualSpendingLimit={setActualSpendingLimit}
          additionalInfoFields={additionalInfoFields}
        />
      )}
    </Page>
  );
};
