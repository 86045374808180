import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { formatShortFriendlyDateWithTime } from '../../helpers';
import { IValidateAddress } from '../../models';

export const verifyAddressesColumns: GridColDef[] = [
  {
    field: 'description',
    headerName: 'Site Name',
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'street',
    headerName: 'Street',
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'city',
    headerName: 'City',
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'state',
    headerName: 'State',
    disableColumnMenu: true,
  },
  {
    field: 'zipCode',
    headerName: 'ZIP Code',
    disableColumnMenu: true,
  },
  {
    field: 'latitude',
    headerName: 'Latitude',
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'longitude',
    headerName: 'Longitude',
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'manualLocation',
    headerName: 'Manual Location',
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<IValidateAddress>) => {
      const { row: site } = params;
      return <>{site.manualLocation ? 'Yes' : 'No'}</>;
    },
  },
  {
    field: 'whenVerified',
    headerName: 'When Verified',
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<IValidateAddress>) => {
      const { row: site } = params;
      return <>{formatShortFriendlyDateWithTime(site.whenVerified)}</>;
    },
  },
];
