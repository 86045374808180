import { FC, useContext, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Fade, Grid } from '@mui/material';
import { Modal, Loader, TextField, ModalSaveSection } from '../../../../components';
import { ICommunicationTemplateInsert, IResponse } from '../../../../models';
import {
  createCommunicationInsert,
  getCommunicationInserts,
  updateCommunicationInsert,
} from '../../../../fetch';
import { UserContext } from '../../../../context';
import { useQuery } from 'react-query';

interface IAddEditFollowUpNotesModal {
  isOpen: boolean;
  communicationTemplateId: string;
  onClose: (shouldUpdate?: boolean) => void;
  selectedFollowUpNote: ICommunicationTemplateInsert | null;
}

const AddEditFollowUpNotesSchema = Yup.object().shape({
  insertName: Yup.string().required('Required'),
  insertText: Yup.string().required('Required'),
  sortOrder: Yup.number().required('Required'),
});

export const AddEditFollowUpNotesModal: FC<IAddEditFollowUpNotesModal> = ({
  isOpen,
  communicationTemplateId,
  onClose,
  selectedFollowUpNote,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const { isLoading: isLoadingSortOrderNumbers, data: currentSortOrderNumbersData } = useQuery<
    IResponse<ICommunicationTemplateInsert[]>
  >(
    ['getEmailTemplateFollowUpNotesSortOrder'],
    () =>
      getCommunicationInserts(communicationTemplateId, {
        sortBy: 'sortOrder',
        sortDirection: 'asc',
        page: 1,
        perPage: -1,
        officeId: user?.officeId,
      }),
    { enabled: isOpen && !!communicationTemplateId && !!user }
  );
  const currentSortOrderNumbers = useMemo(
    () => currentSortOrderNumbersData?.records.map(line => Number(line.sortOrder)) || [],
    [currentSortOrderNumbersData]
  );
  const calculatedSortOrder = useMemo(() => {
    if (selectedFollowUpNote) {
      return Number(selectedFollowUpNote?.sortOrder);
    }
    const lastItemNumber = currentSortOrderNumbers
      ? currentSortOrderNumbers[currentSortOrderNumbers.length - 1] ??
        currentSortOrderNumbers.length
      : 0;
    return lastItemNumber + 1;
  }, [currentSortOrderNumbers, selectedFollowUpNote]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          insertName: selectedFollowUpNote?.insertName ?? '',
          insertText: selectedFollowUpNote?.insertText ?? '',
          sortOrder: String(calculatedSortOrder),
        }}
        validationSchema={AddEditFollowUpNotesSchema}
        onSubmit={async (values, actions) => {
          try {
            const payload = {
              ...values,
              sortOrder: Number(values.sortOrder),
            };
            selectedFollowUpNote
              ? await updateCommunicationInsert(
                  communicationTemplateId,
                  selectedFollowUpNote.emailTemplateInsertId,
                  payload
                )
              : await createCommunicationInsert(communicationTemplateId, payload);
            enqueueSnackbar(
              `Successfully ${!selectedFollowUpNote ? 'created' : 'updated'} follow up note!`,
              {
                variant: 'success',
              }
            );
            onClose(true);
            actions.resetForm();
          } catch (err: any) {
            enqueueSnackbar(err?.Detail || `Error saving follow up note. Please try again.`, {
              variant: 'error',
            });
          }
        }}
      >
        {({ resetForm, isSubmitting, handleSubmit, dirty, isValid }) => {
          const handleClose = () => {
            resetForm();
            onClose();
          };
          return (
            <Modal
              open={isOpen}
              onClose={() => handleClose()}
              maxWidth="sm"
              title={!!selectedFollowUpNote ? 'Edit Follow Up Note' : 'Add Follow Up Note'}
            >
              {isLoadingSortOrderNumbers && <Loader type="overlay" position="centered" />}
              {isSubmitting && <Loader type="overlay" position="centered" title="Saving..." />}
              <Fade in={isOpen}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        fullWidth
                        required
                        size="small"
                        autoComplete="nope"
                        label="Sort Order"
                        name="sortOrder"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TextField
                        fullWidth
                        required
                        size="small"
                        autoComplete="nope"
                        label="Note Name"
                        name="insertName"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        multiline
                        rows={4}
                        size="small"
                        autoComplete="nope"
                        label="Note"
                        name="insertText"
                      />
                    </Grid>
                  </Grid>
                  <ModalSaveSection
                    isSaveDisabled={!dirty || !isValid}
                    handleCancel={() => {
                      handleClose();
                    }}
                    handleSave={handleSubmit}
                  />
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
