import axios from 'axios';
import {
  // Dev Note: Uncomment this when the 'Central Auth' feature is desired
  //  authorizationRequest,
  tokenRequest,
  msalInstance,
} from '../services';
import { getCookie, getEnvCookieKey } from './cookies';
import { Paths } from '../constants';

let buildConfig = require('../buildSettings');
let CANCEL_TOKEN_SOURCE = axios.CancelToken.source();

export const generateNewCancelTokenSource = () => {
  CANCEL_TOKEN_SOURCE = axios.CancelToken.source();
};

const instance = axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}`,
  withCredentials: true,
});
// @ts-ignore
instance.interceptors.request.use(async config => {
  config.cancelToken = CANCEL_TOKEN_SOURCE.token;
  try {
    // Dev Note: We ideally should only need tokenResApi, but central auth seems to reject our api audience
    // Pulling 'tokenResAuth' so the BE can request roles from central auth
    const tokenResApi = await msalInstance.acquireTokenSilent(tokenRequest);

    // Dev Note: Uncomment this when the 'Central Auth' feature is desired
    // const tokenResAuth =
    //   !authorizationRequest.scopes || authorizationRequest.scopes.length === 0
    //     ? undefined
    //     : await msalInstance.acquireTokenSilent(authorizationRequest);
    config.headers.Authorization = `Bearer ${tokenResApi.accessToken}`;
    // config.headers.CentralAuthorization: `Bearer ${tokenResAuth.accessToken}`;
    return config;
  } catch (error) {}
  return config;
});

instance.interceptors.response.use(
  response => response,
  async error => {
    // if the error is a cancel then don't return any reject errors, but just resolve the promise
    if (axios.isCancel(error)) {
      return new Promise(() => {});
    }
    // for pinch service, if we get a 401 for whatever reason, and we have a cookie, then reject the promise
    // this will log the user out and send them to the login page
    if (error?.response?.status === 401 && getCookie(getEnvCookieKey())) {
      return Promise.reject(error);
    }

    // user gets a 401 while on a page after getting a request, redirct to /login or /unauthorized, also ignore for hitting /login endpoint
    if (error?.response?.status === 401 && !error?.response?.config?.url?.includes('/login')) {
      if (
        !window.history.state?.state ||
        window.history.state?.state?.from === window.location.pathname
      ) {
        if (
          window.location.pathname !== Paths.unauthorized.url &&
          window.location.pathname !== '/logout'
        ) {
          const isPool360 = Paths.unauthorized.url.includes('/unauthorized');
          window.location.href = Paths.unauthorized.url;
          if (!isPool360 && !!msalInstance.getActiveAccount()) {
            try {
              // Log out of MSAL
              await msalInstance.logoutRedirect({
                postLogoutRedirectUri: `${buildConfig.REACT_APP_MSAL_REDIRECT}`,
              });
            } catch (error) {
              console.error(error);
            }
          }
        } else {
          return Promise.reject(error);
        }
      }
      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);

export const finishPendingRequests = (cancellationReason: string) => {
  CANCEL_TOKEN_SOURCE.cancel(cancellationReason);
  generateNewCancelTokenSource();
};

export { instance };
