import { CardTitle, Card, useDataGrid, GridDataFetcher, DisplayGroup } from '../../../components';
import { Tooltip, Button, useMediaQuery, Alert, Box } from '@mui/material';
import { useState, FC, useCallback } from 'react';
import { IAccountSimple, ITransaction } from '../../../models';
import { createRepairInvoice, getTransactions } from '../../../fetch';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OTSInvoicesDataGrid } from './ots-invoices-data-grid';
import { Paths } from '../../../constants';

interface IOTSInvoices {
  repairId: string;
  isExpanded?: boolean;
  shouldShowLaborModal?: boolean;
  enableCreateInvoice: boolean;
  isModal?: boolean;
  isOTSStatusOpen?: boolean;
  paymentMethodId: string | null;
  refetchRepairStatus: () => void;
  currentCustomer?: IAccountSimple | null;
  creditCardLast4Digits?: string;
  creditCardExpirationDate?: string;
}

export const OTSInvoices: FC<IOTSInvoices> = ({
  repairId,
  isExpanded,
  shouldShowLaborModal,
  enableCreateInvoice,
  isModal,
  isOTSStatusOpen,
  paymentMethodId,
  refetchRepairStatus,
  currentCustomer,
  creditCardLast4Digits,
  creditCardExpirationDate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const history = useHistory();
  const redirect = `?redirect=${Paths.ots.url}/${repairId}`;
  const isMobile = useMediaQuery(`(max-width: 600px)`);

  const dataFetcher: GridDataFetcher<ITransaction> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res = await getTransactions({
          repairId,
          sortBy: sortColumn || 'whenPosted',
          sortDirection: sortDirection || 'desc',
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading transactions, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ITransaction>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'ots-invoices-grid',
    },
    dataFetcher,
  });

  const handleCreateInvoice = async () => {
    try {
      setIsSubmitting(true);
      const newTransactionId = await createRepairInvoice(repairId);
      enqueueSnackbar(`Invoice created!`, {
        variant: 'success',
      });
      history.push(`${Paths.billing.url}/invoices/${newTransactionId}${redirect}`);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error creating invoice, please try again`, {
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <Card>
        <CardTitle
          title="Invoices"
          mobileWrap
          withExpand
          overrideExpand={isExpanded}
          action={
            !isModal ? (
              <Tooltip title={!enableCreateInvoice ? 'Agreement not signed' : ''}>
                <Button
                  onClick={e => {
                    handleCreateInvoice();
                  }}
                  className={clsx('print--none')}
                  color="secondary"
                  size="small"
                  disabled={!enableCreateInvoice || isLoading || rows?.length === 1 || isSubmitting}
                  fullWidth={isMobile}
                  startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                  data-testid="create-invoice-button"
                >
                  Create Invoice
                </Button>
              </Tooltip>
            ) : undefined
          }
        >
          {creditCardLast4Digits && creditCardExpirationDate && (
            <Alert severity="info" sx={{ marginBottom: '1rem' }}>
              <Box display="flex" gap={1}>
                <DisplayGroup inlineLabelWidth="2rem" label="CC Last Four:" isInline>
                  {creditCardLast4Digits}
                </DisplayGroup>
                <DisplayGroup inlineLabelWidth="2rem" label="Expires:" isInline>
                  {creditCardExpirationDate}
                </DisplayGroup>
              </Box>
            </Alert>
          )}
          <OTSInvoicesDataGrid
            loading={isLoading}
            rows={rows}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            refetch={refetch}
            redirect={redirect}
            hideFooter={true}
            isOTSStatusOpen={isOTSStatusOpen}
            repairId={repairId}
            paymentMethodId={paymentMethodId}
            refetchRepairStatus={refetchRepairStatus}
            shouldShowLaborModal={shouldShowLaborModal}
            currentCustomer={currentCustomer}
          />
        </CardTitle>
      </Card>
    </>
  );
};
