import { faChevronLeft, faRoute, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  styled,
} from '@mui/material';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { SaveButton } from '../../components';
import { IDropdownResponse, IRouteUpdateMode } from '../../models';
import { UpdateModeSelect } from '../routes';
import { useConfirm } from '../../hooks';
import clsx from 'clsx';
import { Paths } from '../../constants';

interface IServiceButtons {
  handleSave: () => void;
  isAdmin: boolean;
  isDisabled?: boolean;
  updateMode: IRouteUpdateMode;
  onUpdateModeChange?: (value: IRouteUpdateMode) => unknown;
  onReset?: () => unknown;
  isLoadingSelectedRoute?: boolean;
  confirmChangesLoss: () => Promise<boolean>;
  hasChanges: boolean;
  optimizeRoutes: () => Promise<void>;
  optimizeMethods: IDropdownResponse[];
  isLoadingOptimizationMethods: boolean;
  selectedMethod: string;
  setSelectedMethod: React.Dispatch<React.SetStateAction<string>>;
}

export const ServiceButtons: FC<IServiceButtons> = ({
  updateMode,
  onUpdateModeChange,
  handleSave,
  isAdmin,
  isDisabled,
  onReset,
  isLoadingSelectedRoute,
  hasChanges,
  confirmChangesLoss,
  optimizeRoutes,
  optimizeMethods,
  isLoadingOptimizationMethods,
  selectedMethod,
  setSelectedMethod,
}) => {
  const isMobile = useMediaQuery(`(max-width: 1230px)`);
  const isMobileSm = useMediaQuery(`(max-width: 900px)`);
  const history = useHistory();
  const confirm = useConfirm();
  const params = new URLSearchParams(window.location.search);

  return (
    <ButtonWrapper
      display="flex"
      alignItems="end"
      justifyContent={isMobile ? 'flex-start' : 'flex-end'}
      marginTop={isMobile ? '1rem' : 0}
      gap={1}
      isMobileSm={isMobileSm}
      className={clsx('print--none')}
    >
      <>
        <Button
          onClick={async () => {
            if (hasChanges) {
              const result = await confirmChangesLoss();
              if (!result) {
                return;
              }
            }
            history.push(params.get('redirect') ?? Paths.routes.url);
          }}
          color="inherit"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" />}
          data-testid="back-button"
        >
          Back
        </Button>
        {isAdmin && (
          <>
            <UpdateModeSelect value={updateMode} onChange={onUpdateModeChange} />
            <OptimizationMethodStyles isMobileSm={isMobileSm}>
              <Typography>Optimization Method</Typography>
              <FormControl fullWidth variant="outlined" size={'small'}>
                <Select
                  disabled={isLoadingOptimizationMethods}
                  name="optimizeRouteOptions"
                  labelId="optimizeRouteOptions"
                  id="optimizeRouteOptions"
                  value={selectedMethod}
                  onChange={e => setSelectedMethod(e.target.value)}
                  inputProps={{
                    'data-testid': 'optimize-method-select',
                  }}
                >
                  {optimizeMethods &&
                    optimizeMethods.map(optimizeMethod => {
                      return (
                        <MenuItem
                          key={`optimize-method-${optimizeMethod.value}`}
                          value={optimizeMethod.value}
                        >
                          {optimizeMethod.description}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </OptimizationMethodStyles>
            <Button
              disabled={isLoadingSelectedRoute}
              onClick={async () => {
                if (hasChanges) {
                  const result = await confirm(
                    'You have unsaved changes, are you sure you want to optimize?'
                  );
                  if (result) {
                    return optimizeRoutes();
                  } else {
                    return;
                  }
                }

                await optimizeRoutes();
              }}
              color="primary"
              startIcon={<FontAwesomeIcon icon={faRoute} />}
              data-testid="optimize-button"
            >
              Optimize
            </Button>
            <Button
              disabled={isDisabled}
              color="error"
              onClick={onReset}
              startIcon={<FontAwesomeIcon icon={faUndo} />}
            >
              Discard Changes
            </Button>
            <SaveButton disabled={isDisabled} handleSave={() => !!handleSave && handleSave()} />
          </>
        )}
      </>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'isMobileSm',
})<{ isMobileSm: boolean }>(({ isMobileSm }: { isMobileSm: boolean }) => ({
  width: isMobileSm ? '100%' : 'auto',
  flexDirection: isMobileSm ? 'column' : 'row',
  '& .MuiButton-root': {
    width: isMobileSm ? '100%' : 'auto',
  },
}));

const OptimizationMethodStyles = styled(Box, {
  shouldForwardProp: prop => prop !== 'isMobileSm',
})<{ isMobileSm: boolean }>(({ isMobileSm }: { isMobileSm: boolean }) => ({
  width: isMobileSm ? '100%' : 'auto',
}));
