import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, FC } from 'react';
import { Button } from '@mui/material';
import { CardTitle, Card } from '../../components';
import { ServiceLogDetails } from './service-log-details';
import { ServiceLogModal } from './ServiceLogModal';
import { generateUUID } from '../../helpers';

interface IServiceLog {
  serviceId: string | number;
  isModal?: boolean;
  isExpanded?: boolean;
}

export const ServiceLog: FC<IServiceLog> = ({ serviceId, isModal, isExpanded }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [shouldRefetchTableUpdate, setShouldRefetchTable] = useState<string | null>(null);

  return (
    <>
      <Card className="print--avoid-break">
        <CardTitle
          withExpand
          overrideExpand={isExpanded}
          initialExpand={false}
          title="Service Log"
          mobileWrap
          action={
            <>
              {!isModal && (
                <Button
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon={faPlusCircle} size="lg" />}
                  size="small"
                  onClick={() => setModalOpen(true)}
                >
                  Add Service Log
                </Button>
              )}
            </>
          }
          unmountOnExit
        >
          <ServiceLogDetails
            serviceId={serviceId}
            shouldRefetchTableUpdate={shouldRefetchTableUpdate}
          />
        </CardTitle>
      </Card>
      <ServiceLogModal
        open={isModalOpen}
        onClose={(shouldUpdate?: boolean) => {
          if (shouldUpdate) {
            // generate a random UUID to force a re-fetch of the table
            setShouldRefetchTable(generateUUID());
          }
          setModalOpen(false);
        }}
        serviceId={serviceId}
      />
    </>
  );
};
