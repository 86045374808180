import { useState } from 'react';
import { Page } from '../../components';
import { IBreadcrumb } from '../../models';
import { EstimateDetail } from './estimate-detail';
import { useParams } from 'react-router-dom';
import { Paths } from '../../constants';

export const EstimatesDetailsPage = () => {
  const { estimateId }: { estimateId: string } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const leadId = searchParams.get('leadId');
  const redirect = searchParams.get('redirect');
  const isNewEstimate = estimateId === 'new';

  const [pageBreadCrumb, setPageBreadCrumb] = useState<IBreadcrumb | undefined>(undefined);
  const [estimateNumber, setEstimateNumber] = useState<string | null | undefined>(null);

  return (
    <Page
      title={
        isNewEstimate
          ? 'New Estimate'
          : estimateNumber === null
          ? 'Loading...'
          : `Estimate ${estimateNumber}`
      }
      breadcrumb={pageBreadCrumb}
    >
      <EstimateDetail
        redirect={leadId ? `${Paths.leads.url}/${leadId}` : redirect ?? Paths.estimates.url}
        setPageBreadCrumb={setPageBreadCrumb}
        setEstimateNumber={setEstimateNumber}
      />
    </Page>
  );
};
