import { IconButton, ListItem, Menu, ListItemButton } from '@mui/material';
import { useState, FC, useContext } from 'react';
import { MoreVert } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, ExternalLink } from '../../components';
import { IScheduledService } from '../../models';
import { UserContext } from '../../context';
import { Paths, Permissions } from '../../constants';
import { getLegacyUrl, hasCorrectUserPermissions } from '../../helpers';

interface IServicesActionButton {
  service: IScheduledService;
  redirect?: string;
}

export const ServicesActionButton: FC<IServicesActionButton> = ({ service, redirect }) => {
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const legacyUrl = getLegacyUrl?.();

  const { v2Customers } = useFlags();
  const isNotActiveLink =
    service.status === 'Closed' && !hasCorrectUserPermissions(Permissions.EditClosedService, user!);
  return (
    <div>
      <IconButton
        color="primary"
        id={`services-actions-${service.scheduledServiceId}`}
        aria-controls={open ? `services-actions-${service.scheduledServiceId}` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        data-testid="service-actions-button"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={`services-actions-${service.scheduledServiceId}`}
        MenuListProps={{
          'aria-labelledby': `services-actions-${service.scheduledServiceId}`,
          sx: {
            padding: 0,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div>
          {isNotActiveLink ? (
            <ListItem disablePadding>
              <ListItemButton disabled>Edit Service</ListItemButton>
            </ListItem>
          ) : (
            <>
              {service.scheduledServiceId && (
                <Link
                  to={`${Paths.maintenance.url}/${service.scheduledServiceId}${
                    redirect ? `?redirect=${redirect}` : ''
                  }`}
                  data-testid="edit-service"
                >
                  <ListItem disablePadding>
                    <ListItemButton>Edit Service</ListItemButton>
                  </ListItem>
                </Link>
              )}
            </>
          )}
          {service.recurringServiceId && (
            <Link
              to={`${Paths.maintenance.url}/recurring/${service.recurringServiceId}${
                redirect ? `?redirect=${redirect}` : ''
              }`}
              data-testid="edit-recurring-service"
            >
              <ListItem disablePadding>
                <ListItemButton>Edit Recurring Service</ListItemButton>
              </ListItem>
            </Link>
          )}
          {v2Customers ? (
            <>
              <Link
                to={`${Paths.customers.url}/${service.accountId}/sites?siteId=${service.siteId}`}
                data-testid="view-site"
              >
                <ListItem disablePadding>
                  <ListItemButton>View Site</ListItemButton>
                </ListItem>
              </Link>
              <Link
                to={`${Paths.customers.url}/${service.accountId}/sites?siteId=${service.siteId}&isEdit=true`}
                data-testid="edit-site"
              >
                <ListItem disablePadding>
                  <ListItemButton>Edit Site</ListItemButton>
                </ListItem>
              </Link>
            </>
          ) : (
            <ExternalLink
              to={`${legacyUrl}/Office/Account/View/${service.accountId}`}
              data-testid="edit-site"
            >
              <ListItem disablePadding>
                <ListItemButton>Edit Site</ListItemButton>
              </ListItem>
            </ExternalLink>
          )}
        </div>
      </Menu>
    </div>
  );
};
