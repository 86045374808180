import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Tooltip,
  Typography,
  Box,
  styled,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { InvalidLocationIcon } from '../../../components';
import { ICalendarView, IService, ITechnician } from '../../../models';
import { FC, useState } from 'react';
import {
  faBroom,
  faExternalLink,
  faFileInvoice,
  faFlask,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { isRouteStartOrEnd } from '../utils';
import clsx from 'clsx';

interface ServiceTechItemProps {
  service: IService;
  allowOptimization: boolean;
  handleSiteChange: (id: string | number) => void;
  routeId: string | number;
  tech: ITechnician;
  handleOptimizationClick: (e: any, tech: ITechnician) => void;
  view: ICalendarView;
}

const CONTEXT_MENU_X_OFFSET = 2;
const CONTEXT_MENU_Y_OFFSET = -6;

export const ServiceTechItem: FC<ServiceTechItemProps> = ({
  service,
  handleSiteChange,
  routeId,
  view = ICalendarView.Day,
}) => {
  const isStartOrEnd = isRouteStartOrEnd(service);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + CONTEXT_MENU_X_OFFSET,
            mouseY: event.clientY + CONTEXT_MENU_Y_OFFSET,
          }
        : null
    );
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  return (
    <Box
      onContextMenu={isRouteStartOrEnd(service) ? undefined : handleContextMenu}
      sx={{
        backgroundColor: isRouteStartOrEnd(service) ? 'transparent' : 'rgba(0, 188, 231, .25)',
        borderBottom: service.siteName === 'Route Start' ? '1px solid black' : 0,
        borderTop: service.siteName === 'Route End' ? '1px solid black' : 0,
        borderRadius: isStartOrEnd ? 0 : '6px',
        margin: isStartOrEnd ? 0 : '8px 0',
        padding: '8px 5px 8px 5px',
      }}
      className={clsx(`view-${view}`)}
    >
      <ServiceName>{service.siteName}</ServiceName>
      <Box flex={1} display="flex" alignItems="center">
        {service.recurringServiceId && (
          <Tooltip title="This is a recurring service">
            <span>
              <StyledIcon icon={faRefresh} size="lg" />
            </span>
          </Tooltip>
        )}
        {service.showBeakerIcon && (
          <Tooltip title="Service">
            <span>
              <StyledIcon icon={faFlask} size="lg" />
            </span>
          </Tooltip>
        )}
        {service.showBrushIcon && (
          <Tooltip title="Cleaning">
            <span>
              <StyledIcon icon={faBroom} size="lg" />
            </span>
          </Tooltip>
        )}
        {service.showWrenchIcon && (
          <Tooltip title="Repair">
            <span>
              <StyledIcon icon={faFileInvoice} size="lg" />
            </span>
          </Tooltip>
        )}
        {(!service.latitude || !service.longitude) && (
          <InvalidLocationIcon
            accountId={service.accountId}
            siteId={service.siteId}
            handleSiteChange={() => handleSiteChange(routeId)}
          />
        )}
      </Box>
      <Menu
        open={contextMenu !== null}
        onClose={closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
        }
      >
        <MenuItem
          component="a"
          href={`/services/maintenance/${service.scheduledServiceId}`}
          target="_blank"
          onClick={closeContextMenu}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faExternalLink} size="sm" />
          </ListItemIcon>
          <ListItemText>Open</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const ServiceName = styled(Typography)(({ theme }) => ({
  fontSize: '0.85rem',
  fontWeight: 'bold',
  lineHeight: 1,
}));

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  borderRadius: '50%',
  fontSize: 16,
  marginRight: 5,
  color: theme.palette.primary.light,
}));
