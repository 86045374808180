import { FC, useEffect, useState, forwardRef, useContext } from 'react';
import { Alert, Box, Button } from '@mui/material';
import { Loader, NonAuthPage, Link } from '../../components';
import { getMe } from '../../fetch';
import { IAPIError } from '../../models';
import { Redirect } from 'react-router-dom';
import { hasAppAccessPermission } from '../../helpers';
import { Paths } from '../../constants';
import { useLogout } from '../../hooks';
import { BrandingContext } from '../../context/branding-context';

interface IUnauthorizedProps {}

export const Unauthorized: FC<IUnauthorizedProps> = () => {
  const handleLogout = useLogout();
  const { isPoolService } = useContext(BrandingContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userErrorTitle, setUserErrorTitle] = useState('Unauthorized!');
  const [userError, setUserError] = useState<string>('');
  const [user, setUser] = useState<any>(undefined);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const user = await getMe();
        setUser(user);
        if (!hasAppAccessPermission(user)) {
          setUserError('Unauthorized access. User does not have desktop application access.');
          setUserErrorTitle('Unauthorized!');
        }
      } catch (error: any) {
        const resError: IAPIError = error;
        setUserError(resError?.Detail ?? 'Unauthorized access');
        setUserErrorTitle(resError?.Title ?? 'Unauthorized!');
      } finally {
        setIsLoading(false);
      }
    };
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader type="overlay" position="centered" />;
  }

  if (!isLoading && !!user && hasAppAccessPermission(user)) {
    return <Redirect to={Paths.home.url} />; // If user is properly logged in, and has app access permission set, redirect to home page
  }

  return (
    <NonAuthPage title={userErrorTitle ?? 'Unauthorized!'}>
      <Alert severity="error" sx={{ mt: 2, mb: 1 }}>
        {!!userError && <> {userError}</>}
      </Alert>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button
          color="primary"
          variant="contained"
          onClick={isPoolService ? handleLogout : undefined}
          component={
            isPoolService
              ? 'button'
              : forwardRef((props: any, _ref) => {
                  return <Link {...props} type="white" />;
                })
          }
          to={isPoolService ? undefined : '/logout'}
        >
          Logout
        </Button>
      </Box>
    </NonAuthPage>
  );
};
