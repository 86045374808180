import { useState, useContext, useMemo, FC, forwardRef } from 'react';
import { useQuery } from 'react-query';
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Box,
  TextField,
  FormControlLabel,
  Divider,
  Grid,
  Button,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Loader, Card, Link } from '../../components';
import { UserContext } from '../../context';
import { IBillingGroup, IResponse, IAPIError } from '../../models';
import {
  getBillingGroups,
  hasMonthlyChargedTransactions,
  postDuplicateMonthlyCharges,
  postMonthlyCharges,
} from '../../fetch';
import { LateFeesModal } from './late-fees-modal';
import { useConfirm } from '../../hooks';
import { DuplicatePostModal } from './duplicate-post-modal';
import { BrandingContext } from '../../context/branding-context';
import { useFlags } from 'launchdarkly-react-client-sdk';

const getMonthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format;

interface IMonthlyBillingProps {
  setRefresh: (val: boolean) => void;
}

export const MonthlyBilling: FC<IMonthlyBillingProps> = ({ setRefresh }) => {
  const isSmMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { isPoolService } = useContext(BrandingContext);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [referenceInput, setReferenceInput] = useState(`${getMonthName(new Date())} Billing`);
  const [selectedBillingOption, setSelectedBillingOption] = useState<string | null>(null);
  const [lateFeesModalIsOpen, setLateFeesModalIsOpen] = useState(false);
  const [isCheckingMonthlyBilling, setIsCheckingMonthlyBilling] = useState(false);
  const [isPostingMonthlyBilling, setIsPostingMonthlyBilling] = useState(false);
  const [isDuplicatePostModalOpen, setIsDuplicatePostModalOpen] = useState(false);
  const { user, hasQBInvoiceEntryType } = useContext(UserContext);

  const { accountingExport } = useFlags();

  const { isLoading: isLoadingBillingGroups, data: billingGroupsData } = useQuery<
    IResponse<IBillingGroup[]>,
    Error
  >(['billing-groups'], () => getBillingGroups({ perPage: -1, officeId: user?.officeId }), {
    onSuccess: d => {
      const monthlyOption = d?.records.find(r => r.description === 'Monthly');
      if (monthlyOption) {
        setSelectedBillingOption(monthlyOption?.billingGroupId as string);
      }
    },
  });
  const billingGroups: IBillingGroup[] = useMemo(
    () => billingGroupsData?.records || [],
    [billingGroupsData]
  );

  const closeLateFeesModal = () => {
    setLateFeesModalIsOpen(false);
  };

  const openLateFeesModal = () => {
    setLateFeesModalIsOpen(true);
  };

  const handlePostMonthlyCharges = async (confirmedDuplicate?: boolean) => {
    try {
      setIsPostingMonthlyBilling(true);
      await postMonthlyCharges({
        officeId: user?.officeId as string,
        billingGroupId: selectedBillingOption as string,
        whenCreated: selectedDate.toISOString(),
        reference: referenceInput,
        confirmedDuplicate: confirmedDuplicate ?? false,
      });
      enqueueSnackbar('Posted monthly charges!', {
        variant: 'success',
      });
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? 'Error, posting monthly charges.', {
        variant: 'error',
      });
    } finally {
      setRefresh(true);
      setReferenceInput(`${getMonthName(selectedDate)} Billing`);
      setIsPostingMonthlyBilling(false);
    }
  };

  return (
    <>
      <Card
        cardTitleProps={{
          title: 'Monthly Billing',
        }}
      >
        <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Invoice Begin Date"
              format="MM/dd/yyyy"
              onChange={(date: any) => {
                setSelectedDate(date);
                setReferenceInput(`${getMonthName(date)} Billing`);
              }}
              value={selectedDate ? new Date(selectedDate) : null}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: false,
                  size: 'small',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              label="Reference"
              value={referenceInput}
              onChange={e => {
                setReferenceInput(e.target.value);
              }}
              inputProps={{
                'data-testid': 'reference-textfield',
              }}
            />
          </Grid>
        </Grid>
        <Box mb={1}>
          {isLoadingBillingGroups && (
            <Loader position={isSmMobile ? 'centered' : 'left'} type="inline" />
          )}
          {(isPostingMonthlyBilling || isCheckingMonthlyBilling) && (
            <Loader position="centered" type="overlay" />
          )}
          {!isLoadingBillingGroups && (
            <FormControl>
              <FormLabel id="billing-group" color="primary">
                Billing Group
              </FormLabel>
              <RadioGroup
                aria-labelledby="billing-group"
                name="billing-group"
                data-testid="billing-group-radio-buttons"
                value={selectedBillingOption}
                onChange={e => {
                  setSelectedBillingOption(e.target.value);
                }}
              >
                {billingGroups.map((group, index) => {
                  return (
                    <FormControlLabel
                      key={`${index}`}
                      value={group.billingGroupId}
                      control={<Radio />}
                      label={group.description}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          )}
        </Box>
        <Divider />
        <Box
          marginTop="1rem"
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection={{
              xs: 'column',
              sm: 'row',
            }}
            height={{
              xs: isPoolService && accountingExport ? '10rem' : '7rem',
              sm: 'auto',
            }}
            width={{
              xs: '100%',
              sm: 'auto',
            }}
            justifyContent={{ xs: 'space-between', md: 'center' }}
            gap={1}
            flexWrap="wrap"
          >
            <Button
              onClick={async () => {
                const result = await confirm(
                  'Are you sure you want to post this billing? This cannot be deleted.'
                );
                if (result) {
                  try {
                    setIsCheckingMonthlyBilling(true);
                    // Check if there are monthly charged transactions to display
                    const hasMontlyChargedTransactions = await hasMonthlyChargedTransactions(
                      selectedBillingOption as string
                    );

                    if (!hasMontlyChargedTransactions) {
                      await confirm(
                        'The billing group you are trying to post to does not contain any customers. Please add customers to this billing group and try again.'
                      );
                    } else {
                      // Check for duplicate postings
                      const hasDuplicate = await postDuplicateMonthlyCharges({
                        officeId: user?.officeId as string,
                        billingGroupId: selectedBillingOption as string,
                        reference: referenceInput,
                      });

                      if (hasDuplicate) {
                        setIsDuplicatePostModalOpen(true); // Show Duplicate Post Modal for extra confirmation
                      } else {
                        handlePostMonthlyCharges();
                      }
                    }
                  } catch (error: any) {
                    const e: IAPIError = error;
                    enqueueSnackbar(e?.Detail ?? `Error posting`, {
                      variant: 'error',
                    });
                  } finally {
                    setIsCheckingMonthlyBilling(false);
                  }
                }
              }}
              data-testid="post-button"
              color="primary"
              size="small"
              variant="outlined"
              fullWidth={isSmMobile}
              disabled={
                isPostingMonthlyBilling || !selectedBillingOption || isCheckingMonthlyBilling
              }
            >
              {hasQBInvoiceEntryType ? 'Send to QuickBooks' : 'Post'}
            </Button>
            <Button
              onClick={openLateFeesModal}
              data-testid="post-late-fees-button"
              color="primary"
              size="small"
              variant="outlined"
              fullWidth={isSmMobile}
              disabled={!selectedBillingOption || hasQBInvoiceEntryType}
            >
              Post Late Fees
            </Button>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              fullWidth={isSmMobile}
              component={forwardRef((props: any, _ref) => {
                return <Link {...props} data-testid="print-statements-button" />;
              })}
              to="/reports?reportId=8d928bb6-f0ac-42b8-8aa6-ac8b6a53abf6"
              disabled={!selectedBillingOption}
            >
              Print Statements
            </Button>
            {isPoolService && accountingExport && (
              <Button
                color="primary"
                size="small"
                variant="outlined"
                fullWidth={isSmMobile}
                component={forwardRef((props: any, _ref) => {
                  return <Link {...props} data-testid="accounting-report-button" />;
                })}
                to="/billing/export"
              >
                Accounting Export
              </Button>
            )}
          </Box>
        </Box>
        <LateFeesModal
          open={lateFeesModalIsOpen}
          onClose={closeLateFeesModal}
          billingGroupId={selectedBillingOption}
        />
      </Card>
      <DuplicatePostModal
        isOpen={isDuplicatePostModalOpen}
        handleClose={() => setIsDuplicatePostModalOpen(false)}
        handleSubmit={() => handlePostMonthlyCharges(true)}
      />
    </>
  );
};
