import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardTitle,
  GridDataFetcher,
  useDataGrid,
  ServerSideDataGrid,
  Card,
} from '../../../components';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { ICompanyPhone } from '../../../models';
import { deleteCompanyPhoneNumber, getCompanyPhoneNumbers } from '../../../fetch';
import { faPlusCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useConfirm } from '../../../hooks';
import { UserContext } from '../../../context';
import { AddEditPhoneNumberModal } from './company-info-add-edit-phone-modal';

export const CompanyPhoneTable: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingModal, showModal] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState<ICompanyPhone | null>(null);
  const confirm = useConfirm();
  const { user } = useContext(UserContext);

  const dataFetcher: GridDataFetcher<ICompanyPhone> = useCallback(
    async ({ perPage, sortColumn, sortDirection, page }) => {
      try {
        const options: any = {
          sortBy: sortColumn,
          sortDirection: sortDirection || 'asc',
          perPage,
          page: page + 1,
          officeId: user?.officeId as string,
        };

        const res = await getCompanyPhoneNumbers(options);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error) {
        enqueueSnackbar(`Error loading company phone numbers, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ICompanyPhone>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'company-phone-grid',
      sortColumn: 'phoneNumberType',
      sortDirection: 'asc',
    },
    dataFetcher,
  });

  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'phoneNumberType',
        headerName: 'Phone Type',
        flex: 1,
      },
      {
        field: 'areaCode',
        headerName: 'Area Code',
        flex: 1,
      },
      {
        field: 'phoneNumber',
        headerName: 'Number',
        flex: 1,
      },
      {
        field: 'extension',
        headerName: 'Extension',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams<ICompanyPhone>) => {
          return (
            <Box display="flex">
              <IconButton
                color="primary"
                title="Edit Phone"
                onClick={() => {
                  showModal(true);
                  setCurrentPhoneNumber(params.row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
              <IconButton
                color="error"
                title="Delete Phone"
                onClick={async () => {
                  try {
                    const result = await confirm('Are you sure you want to delete this phone?');
                    if (result) {
                      setDeleting(true);
                      await deleteCompanyPhoneNumber(params.row.officePhoneNumberId);
                      await refetch();
                      enqueueSnackbar(`Phone Deleted!`, {
                        variant: 'success',
                      });
                    }
                  } catch (error) {
                    enqueueSnackbar(`Error deleting phone, please try again.`, {
                      variant: 'error',
                    });
                  } finally {
                    setDeleting(false);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, isDeleting]);

  return (
    <>
      <Card>
        <CardTitle
          title="Phone Numbers"
          mobileWrap
          withExpand
          overrideExpand={false}
          action={
            <>
              <Button
                onClick={() => showModal(true)}
                color="secondary"
                size="small"
                disabled={isLoading}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add Phone
              </Button>
            </>
          }
        >
          <ServerSideDataGrid
            autoHeight
            disableColumnFilter
            disableColumnMenu
            getRowId={(row: ICompanyPhone) => row.officePhoneNumberId}
            rows={rows}
            columns={columns}
            loading={isLoading}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            sortModel={sortModel}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChange}
          />
        </CardTitle>
      </Card>
      <AddEditPhoneNumberModal
        open={isShowingModal}
        onClose={() => {
          showModal(false);
          setCurrentPhoneNumber(null);
        }}
        currentPhoneNumber={currentPhoneNumber}
        fetchPhoneNumbers={() => refetch()}
      />
    </>
  );
};
