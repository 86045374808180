import { FC, useContext, useState } from 'react';
import { Card, CardTitle, SearchField, IconPageHeading } from '../../components';
import { ESearchParams, SearchParamsContext, UserContext } from '../../context';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { faEdit, faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { InventoryListDetails } from './inventory-list-details';
import { InventorySearchDetails } from './inventory-search-details';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IInventoryItem, IInventoryResetStatus } from '../../models';
import { InventoryModal } from './360/inventory-modal';
import { inventoryColumns } from './inventory-columns';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useConfirm } from '../../hooks';
import { checkPriceResetInventory, priceResetInventory } from '../../fetch';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { formatShortFriendlyDateWithTime } from '../../helpers';

interface IInventoryList {}

export const InventoryList: FC<IInventoryList> = () => {
  const { user } = useContext(UserContext);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { searchParams, setSearchParams } = useContext(SearchParamsContext);

  const [searchValue, setSearchValue] = useState<string>(
    searchParams?.get(ESearchParams.searchValue) ?? ''
  );
  const [searchedValue, setSearchedValue] = useState<string>(
    searchParams?.get(ESearchParams.searchValue) ?? ''
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchRecordCount, setSearchRecordCount] = useState<number | null>(null);
  const [isInventoryDetailModal, setIsInventoryDetailModal] = useState(false);
  const [selectedInventoryItem, setSelectedInventoryItem] = useState<IInventoryItem | null>(null);
  const [refresh, setRefresh] = useState(false);
  const [isPriceResetRunning, setIsPriceResetRunning] = useState(false);

  const {
    isFetching,
    isLoading: isCheckingInventoryReset,
    data: inventoryResetStatus,
    refetch: refetchInventoryStatusReset,
  } = useQuery<IInventoryResetStatus>(
    ['checkPriceResetInventory', user],
    () => checkPriceResetInventory(user?.officeId!),
    {
      enabled: !!user?.userId,
    }
  );

  const handlePriceReset = async () => {
    const result = await confirm('Reset all Pool 360 Inventory Items to current Retail Price?');
    if (!result) return;

    setIsPriceResetRunning(true);
    try {
      await priceResetInventory();
      refetchInventoryStatusReset();
      enqueueSnackbar(
        'Inventory price reset started, an email will be sent when the process is finished',
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        error?.Detail ?? `Error, failed to run inventory price reset, please try again.`,
        {
          variant: 'error',
        }
      );
    } finally {
      setIsPriceResetRunning(false);
    }
  };

  const gridColumns = [
    ...inventoryColumns,
    {
      field: 'actions',
      headerName: '',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: 'col-sticky',
      width: 50,
      renderCell: (params: GridRenderCellParams<IInventoryItem>) => {
        const { row: item } = params;
        return (
          <IconButton
            title={`Edit Inventory Item ${item.itemId}`}
            color="primary"
            sx={{ marginRight: theme => theme.spacing(1) }}
            onClick={() => {
              setIsInventoryDetailModal(true);
              setSelectedInventoryItem(item);
            }}
          >
            <FontAwesomeIcon icon={faEdit} size="sm" />
          </IconButton>
        );
      },
    },
  ];
  return (
    <>
      {searchedValue && !!searchRecordCount && (
        <Box mb={2}>
          <IconPageHeading
            icon={faSearch}
            showHome={false}
            showDivider={false}
            heading={`${searchRecordCount ?? 0} Results for "${searchedValue}"`}
          />
        </Box>
      )}
      {/* Overflow unset is for position sticky to work for datagrid column */}
      <Card sxProps={{ overflow: 'unset' }}>
        <CardTitle
          mobileWrap
          marginBottom={0}
          action={
            <>
              <SearchField
                isDisabled={isLoading}
                placeholder="Find inventory..."
                handleSearch={val => {
                  if (val) {
                    setSearchedValue(val);
                    setSearchParams({ [ESearchParams.searchValue]: val });
                  }
                }}
                handleClearSearch={() => {
                  setSearchValue('');
                  setSearchedValue('');
                  setSearchRecordCount(null);
                  setSearchParams({ [ESearchParams.searchValue]: undefined });
                }}
                searchValue={searchValue}
                setSearchValue={val => {
                  if (!val) {
                    setSearchedValue('');
                    setSearchRecordCount(null);
                  }
                  setSearchValue(val);
                }}
              />
              <Button
                color="secondary"
                size="small"
                sx={{ minWidth: 110 }}
                onClick={() => {
                  setIsInventoryDetailModal(true);
                }}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                Add Item
              </Button>
            </>
          }
        >
          {!!searchedValue ? (
            <InventorySearchDetails
              searchedValue={searchedValue}
              user={user}
              setLoading={val => setIsLoading(val)}
              setSearchRecordCount={val => setSearchRecordCount(val)}
              columns={gridColumns}
              refresh={refresh}
              setRefresh={setRefresh}
              shouldUseSearchParams={true}
            />
          ) : (
            <InventoryListDetails
              searchedValue={searchedValue}
              user={user}
              setLoading={val => setIsLoading(val)}
              columns={gridColumns}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}

          <InventoryModal
            isOpen={isInventoryDetailModal}
            handleClose={refetch => {
              if (refetch) {
                setRefresh(true);
              }
              setIsInventoryDetailModal(false);
              setSelectedInventoryItem(null);
            }}
            selectedInventoryItem={selectedInventoryItem}
          />
        </CardTitle>
      </Card>
      <Box mt={2} display="flex" alignItems="center" justifyContent="space-between" gap={2}>
        <Button
          onClick={() => {
            handlePriceReset();
          }}
          color="primary"
          size="small"
          variant="outlined"
          disabled={isPriceResetRunning || isFetching || inventoryResetStatus?.status === 'Running'}
        >
          {isCheckingInventoryReset || isFetching || isPriceResetRunning
            ? 'Loading...'
            : inventoryResetStatus?.status === 'Running'
            ? 'Price Reset Running...'
            : 'Pool 360 Price Reset'}
        </Button>
        {inventoryResetStatus?.lastModified && (
          <Typography variant="caption" color="textSecondary">
            Last Pool 360 Price Reset:{' '}
            {formatShortFriendlyDateWithTime(inventoryResetStatus.lastModified)}
          </Typography>
        )}
      </Box>
    </>
  );
};
