import { FC, useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { GridDataFetcher, useDataGrid } from '../../components';
import { searchInventory } from '../../fetch';
import { InventoryDataGrid } from './InventoryDataGrid';
import { IInventoryItem, IUser } from '../../models';
import { GridColDef } from '@mui/x-data-grid';

interface IInventorySearchDetails {
  searchedValue: string;
  user?: IUser;
  setLoading: (val: boolean) => void;
  setSearchRecordCount: (val: number) => void;
  columns?: GridColDef[];
  refresh?: boolean;
  setRefresh?: (val: boolean) => void;
  shouldUseSearchParams?: boolean;
}

export const InventorySearchDetails: FC<IInventorySearchDetails> = ({
  searchedValue,
  user,
  setLoading,
  setSearchRecordCount,
  columns,
  refresh,
  setRefresh,
  shouldUseSearchParams = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const dataFetcher: GridDataFetcher<IInventoryItem, number> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      if (!!searchedValue) {
        try {
          setLoading(true);
          const options: any = {
            sortBy: sortColumn,
            sortDirection: sortDirection ?? 'asc',
            perPage,
            officeId: user?.officeId as string,
            search: searchedValue,
            page: page + 1,
          };

          const res = await searchInventory(options);
          setSearchRecordCount(res.totalRecordCount);
          return {
            rows: res.records,
            rowCount: res.totalRecordCount,
          };
        } catch (error) {
          enqueueSnackbar(`Error searching inventory, please try again.`, {
            variant: 'error',
          });
          throw error;
        } finally {
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchedValue]
  );
  const {
    isLoading: isSearching,
    page: searchPage,
    rows: searchRows,
    pageSize: searchPageSize,
    rowCount: searchRecordCount,
    sortModel: searchSortModal,
    onPageChange,
    onPageSizeChange: onSearchPageSizeChange,
    onSortModelChange: onSearchSortModelChange,
    refetch: refetchInventoryItems,
  } = useDataGrid<IInventoryItem, number>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'inventory-grid',
      sortColumn: 'description',
      sortDirection: 'asc',
    },
    dataFetcher,
    shouldUseSearchParams: shouldUseSearchParams,
  });
  useEffect(() => {
    if (refresh) {
      refetchInventoryItems();
      setRefresh && setRefresh(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (
    <InventoryDataGrid
      loading={isSearching}
      rows={searchRows}
      rowCount={searchRecordCount}
      page={searchPage}
      pageSize={searchPageSize}
      onPageChange={onPageChange}
      onPageSizeChange={onSearchPageSizeChange}
      sortModel={searchSortModal}
      onSortModelChange={onSearchSortModelChange}
      columns={columns}
    />
  );
};
