import { IconButton, ListItem, Menu, ListItemButton, ListItemText } from '@mui/material';
import { useState, FC, useContext } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IBillingLog } from '../../models';
import { Permissions } from '../../constants';
import { UserContext } from '../../context';
import { hasCorrectUserPermissions } from '../../helpers';

interface IBillingLogKebabProps {
  billingLog: IBillingLog;
  handleDownload: (transactionBatchId: string, reference: string) => void;
  handleDelete: (transactionBatchId: string) => void;
  allowDelete?: boolean;
}

export const BillingLogKebab: FC<IBillingLogKebabProps> = ({
  billingLog,
  handleDownload,
  handleDelete,
  allowDelete,
}) => {
  const { user, hasQBInvoiceEntryType } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        color="primary"
        id="billing-log"
        aria-controls={open ? 'billing-log' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ padding: 0 }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="billing-log"
        MenuListProps={{
          'aria-labelledby': 'billing-log',
          sx: {
            padding: 0,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <ListItem disablePadding>
          <ListItemButton
            sx={{ color: theme => theme.palette.primary.main }}
            onClick={() => handleDownload(billingLog.transactionBatchId, billingLog.reference)}
            data-testid="download-billing"
          >
            <ListItemText>Download Billing</ListItemText>
          </ListItemButton>
        </ListItem>
        {(hasCorrectUserPermissions(Permissions.DeleteBillingBatches, user!) &&
          !hasQBInvoiceEntryType) ||
          (allowDelete && (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ color: theme => theme.palette.error.main }}
                onClick={() => handleDelete(billingLog.transactionBatchId)}
                data-testid="delete-billing"
              >
                <ListItemText>Delete Billing</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
      </Menu>
    </div>
  );
};
