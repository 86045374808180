import { FC, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/x-date-pickers';
import { UserContext } from '../../../context';
// Components
import {
  Modal,
  Loader,
  TextField,
  SelectAsync,
  Wysiwyg,
  SaveButton,
  CancelIcon,
} from '../../../components';
import { Box, Fade, Typography, Grid, Divider, Button } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
// fetch
import { updateNewsItem, createNewsItem, getNewsCategories } from '../../../fetch';
import { StoresAutocomplete } from './stores-autocomplete';
import { ILookupModel, INews } from '../../../models';

interface IAddEditNewsItemModal {
  open: boolean;
  onClose: () => void;
  currentNewsItem?: INews | null;
  fetchNewsItems: () => void;
}

const Schema = Yup.object().shape({
  category: Yup.string().required('Required'),
  subject: Yup.string().required('Required'),
  officeIds: Yup.array(),
  message: Yup.string().required('Required'),
  displayDate: Yup.string().required('Required'),
  takeDownDate: Yup.string(),
});

export const AddEditNewsItemModal: FC<IAddEditNewsItemModal> = ({
  open,
  onClose,
  currentNewsItem,
  fetchNewsItems,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isSuperAdmin, user } = useContext(UserContext);
  const setDefaultOffices = () => {
    // If edit and offices, create array
    if (currentNewsItem?.offices && currentNewsItem?.offices?.length > 0) {
      return currentNewsItem?.offices?.map(office => office.officeId);
    }
    // If edit and no offices, return All value
    if (currentNewsItem?.offices && currentNewsItem?.offices?.length === 0) {
      return ['-1'];
    }
    return [`${user?.officeId}`]; // If new, default to user's office
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          category: currentNewsItem?.category ?? '',
          subject: currentNewsItem?.subject ?? '',
          officeIds: setDefaultOffices(),
          message: currentNewsItem?.message ?? '',
          displayDate: currentNewsItem?.displayDate ?? new Date().toISOString(),
          takeDownDate: currentNewsItem?.takeDownDate ?? '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            ...values,
            officeIds: values?.officeIds?.includes('-1') ? [] : values.officeIds,
          };
          try {
            currentNewsItem
              ? await updateNewsItem(currentNewsItem?.newsItemId, data)
              : await createNewsItem(data);
            enqueueSnackbar(
              currentNewsItem
                ? 'Successfully updated news item!'
                : 'Successfully created news item!',
              {
                variant: 'success',
              }
            );
            onClose();
            fetchNewsItems();
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving news item, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({
          resetForm,
          isSubmitting,
          values,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          dirty,
          isValid,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              maxWidth="md"
            >
              {isSubmitting && <Loader type="overlay" position="centered" />}
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <Box marginBottom="2rem">
                    <Typography variant="h5" sx={{ paddingBottom: '.5rem' }}>
                      {currentNewsItem ? 'Edit News Item' : 'Add News Item'}
                    </Typography>
                    <Divider />
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SelectAsync
                          label="Category"
                          name="category"
                          required
                          apiRequest={() => getNewsCategories()}
                          transformResponse={(res: ILookupModel[]) =>
                            res.map(r => ({
                              label: r.description,
                              value: r.value,
                            }))
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name="subject" label="Subject" required />
                      </Grid>
                      {isSuperAdmin && (
                        <Grid item xs={12}>
                          <StoresAutocomplete values={values} setFieldValue={setFieldValue} />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Wysiwyg
                          label="Message"
                          required
                          value={values.message}
                          onChange={val => setFieldValue('message', val)}
                          placeholder="Add Message..."
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box mt={1}>
                          <DatePicker
                            label="Display Date"
                            format="MM/dd/yyyy"
                            onChange={(date: any) => {
                              setFieldValue('displayDate', date);
                            }}
                            maxDate={values.displayDate ? new Date(values.displayDate) : null}
                            value={values.displayDate ? new Date(values.displayDate) : null}
                            slotProps={{
                              textField: {
                                error: !!errors?.displayDate && !!touched?.displayDate,
                                size: 'small',
                                fullWidth: true,
                                required: true,
                              },
                            }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box mt={1}>
                          <DatePicker
                            label="Takedown Date"
                            format="MM/dd/yyyy"
                            onChange={(date: any) => {
                              setFieldValue('takeDownDate', date);
                            }}
                            value={values.takeDownDate ? new Date(values.takeDownDate) : null}
                            minDate={values.displayDate ? new Date(values.displayDate) : null}
                            slotProps={{
                              textField: {
                                error: !!errors?.takeDownDate && !!touched?.takeDownDate,
                                size: 'small',
                                fullWidth: true,
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      margin="1rem 0"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Box marginRight="0.5rem">
                        <Button
                          type="button"
                          color="inherit"
                          onClick={() => {
                            onClose();
                          }}
                          startIcon={<CancelIcon />}
                          data-testid="cancel-button"
                        >
                          Cancel
                        </Button>
                      </Box>
                      <SaveButton disabled={!dirty || isSubmitting || !isValid} />
                    </Box>
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
