import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState, useContext, useCallback } from 'react';
import { GridDataFetcher, Loader, useDataGrid } from '../../../components';
import { IAccountDetail, ISite, ISiteGetResponse } from '../../../models';
import { UserContext } from '../../../context';
import { getSite, getSites, deleteSite } from '../../../fetch';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { SiteDetailDisplayView, SiteDetailCRUDView, SitesList } from '../../sites';
import { useConfirm, useSearchParams } from '../../../hooks';

export interface ISitesTab {
  accountId: string;
  currentCustomer: IAccountDetail | null;
  setIsTabDirty: (val: boolean) => void;
}

export const SitesTab: FC<ISitesTab> = ({ accountId, currentCustomer, setIsTabDirty }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const confirm = useConfirm();
  const [queryParams, setSearchParams] = useSearchParams();

  const paramsSiteId = queryParams.get('siteId');
  const paramsIsEditing = queryParams.get('isEdit');

  const [isLoadingSite, setLoadingSite] = useState(false);
  const [isNewSite, setNewSite] = useState(false);
  const [site, setSite] = useState<ISiteGetResponse | null>(null);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean | undefined>(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const fetchSiteDetail = async (siteId: string) => {
    setLoadingSite(true);
    try {
      const siteRes = await getSite(siteId);
      setSite(siteRes);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error loading sites, please try again.`, {
        variant: 'error',
      });
    } finally {
      setLoadingSite(false);
    }
  };

  const dataFetcher: GridDataFetcher<ISite> = useCallback(
    async ({ sortColumn, sortDirection, page, perPage, isManagingSites }) => {
      try {
        const res = await getSites({
          page: page + 1,
          perPage,
          accountId,
          officeId: user?.officeId,
          sortBy: sortColumn || 'description',
          sortDirection: sortDirection || 'desc',
        });
        if (res.totalRecordCount === 1 && !isManagingSites) {
          fetchSiteDetail(res?.records?.[0]?.siteId);
        } else {
          return {
            rows: res.records,
            rowCount: res.totalRecordCount,
          };
        }
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading sites, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading: isLoadingSites,
    rowCount: recordCount,
    refetch: fetchSites,
    page,
    pageSize,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<ISite>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      sortColumn: 'description',
      sortDirection: 'desc',
      gridKeyName: 'customer-sites-grid',
    },
    dataFetcher,
  });

  useEffect(() => {
    if (paramsSiteId) {
      fetchSiteDetail(paramsSiteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsSiteId]);

  if (isLoadingSite || isDeleting || isLoadingSites) {
    return (
      <Box mt={4}>
        <Loader position="centered" />
      </Box>
    );
  }
  if (!!site || isNewSite) {
    return (
      <>
        <Box
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className="print--none"
        >
          <Button
            variant="text"
            onClick={() => setIsAllExpanded(!isAllExpanded ? true : false)}
            startIcon={isAllExpanded ? <ExpandLess /> : <ExpandMore />}
          >
            {isAllExpanded ? 'Collapse All' : 'Expand All'}
          </Button>
        </Box>
        {!paramsIsEditing && !isNewSite && (
          <SiteDetailDisplayView
            accountId={accountId}
            siteId={site?.siteId!}
            site={site!}
            isAllExpanded={isAllExpanded}
            handleEditSite={site => {
              setSearchParams({
                isEdit: 'true',
              });
            }}
            handleViewAll={() => {
              fetchSites({
                isManagingSites: true,
              });
              // setEditing(false);
              setSite(null);
            }}
          />
        )}
        {(!!paramsIsEditing || isNewSite) && (
          <SiteDetailCRUDView
            accountId={accountId}
            siteId={site?.siteId!}
            site={site as ISiteGetResponse}
            fetchSite={fetchSiteDetail}
            accountAddress={currentCustomer?.address!}
            isNewSite={isNewSite}
            isAllExpanded={isAllExpanded}
            currentCustomer={currentCustomer}
            setEditing={() => {
              setSearchParams({
                isEdit: 'true',
              });
            }}
            setIsTabDirty={setIsTabDirty}
            handleDelete={async (site: ISiteGetResponse) => {
              const result = await confirm('Are you sure you want to delete this site?');
              if (!result) return;

              try {
                setIsDeleting(true);
                await deleteSite(site.siteId);
                enqueueSnackbar(`Site deleted!`, { variant: 'success' });
                setSearchParams({
                  isEdit: undefined,
                });
                setSite(null);
                fetchSites();
              } catch (err: any) {
                enqueueSnackbar(err?.Detail ?? `Error deleting site, please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsDeleting(false);
              }
            }}
            handleCancel={(isNew: boolean | undefined) => {
              setSearchParams({
                isEdit: undefined,
              });
              setNewSite(false);
              if ((rows && rows?.length > 1) || isNew) {
                setSite(null);
              }
              if (isNew) {
                fetchSites();
              }
            }}
            handleSave={(id: string | undefined, isNew: boolean | undefined) => {
              setNewSite(false);
              // If isNew and id of newly created item, then fetch the site detail and load the edit view
              if (isNew && id) {
                setSearchParams({
                  isEdit: 'true',
                });
                return fetchSiteDetail(id);
              }
              // All other states should not be in edit mode
              setSearchParams({
                isEdit: undefined,
              });
              // If not new and id, then fetch the site detail and load the display view
              if (id && !isNew) {
                return fetchSiteDetail(id);
              }
              // Otherwise, load the list of sites and clear the site detail
              if ((rows && rows?.length > 1) || isNew) {
                setSite(null);
              }
              fetchSites();
            }}
          />
        )}
      </>
    );
  }
  return (
    <Box marginTop="1rem">
      <SitesList
        sites={rows}
        handleViewSite={(site: ISite) => {
          fetchSiteDetail(site.siteId);
        }}
        handleEditSite={(site: ISite) => {
          setSearchParams({
            isEdit: 'true',
          });
          fetchSiteDetail(site.siteId);
        }}
        handleNewSite={() => {
          setNewSite(true);
          setSearchParams({
            isEdit: 'true',
          });
        }}
        fetchSites={fetchSites}
        page={page}
        pageSize={pageSize}
        recordCount={recordCount}
        sortModel={sortModel}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        isLoading={isLoadingSites}
      />
    </Box>
  );
};
