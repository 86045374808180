import { FC } from 'react';
import { useQuery } from 'react-query';
import { getAllHealthChecks } from '../../../fetch/developer';
import { Box, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Card, Loader } from '../../../components';

export const HealthChecksDetail: FC = () => {
  const { isLoading, data } = useQuery('healthChecks', () => getAllHealthChecks());

  return (
    <Card>
      {isLoading ? (
        <Loader type="overlay" />
      ) : (
        <>
          <Typography variant="h6">
            Status:{' '}
            {data?.Status ? (
              <Typography
                component="span"
                sx={{
                  color: theme =>
                    data?.Status === 'Healthy'
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                }}
              >
                {data?.Status}
              </Typography>
            ) : (
              ''
            )}
          </Typography>
          <Box mb={1}>
            <Divider />
          </Box>
          <List disablePadding>
            {data?.Entries?.map((entry, index) => {
              return (
                <ListItem key={`${index}`} sx={{ paddingTop: 0 }}>
                  <ListItemText
                    primary={entry.HealthCheckName}
                    secondary={entry.Status}
                    sx={{
                      '& .MuiListItemText-secondary': {
                        color: theme =>
                          entry.Status === 'Healthy'
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      },
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </Card>
  );
};
