import { Stack, Grid, Box } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { startOfDay } from 'date-fns';
import { SelectListFilter, DateFilter, Card } from '../../../components';
import { ScheduledServices } from '../ScheduledServices';
import { RepairVisits } from './repair-visits';
import { useQuery } from 'react-query';
import { IListUser, IResponse } from '../../../models';
import { getUsers } from '../../../fetch';
import { UserContext } from '../../../context';
import { Paths } from '../../../constants';

export const FieldReportDetails = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get('userId');
  const date = searchParams.get('date');

  const { user } = useContext(UserContext);
  const [selectedUser, setSelectedUser] = useState(userId ?? 'all');
  const [selectedDate, setSelectedDate] = useState(
    date ? new Date(date).toISOString() : new Date().toISOString()
  );

  const { isLoading: isLoadingUsers, data: usersData } = useQuery<IResponse<IListUser[]>, Error>(
    ['getUsers'],
    () => getUsers({ perPage: -1, officeId: user?.officeId, isDisabled: false })
  );

  const users = useMemo(() => usersData?.records ?? [], [usersData]);

  const redirect = useMemo(
    () =>
      encodeURIComponent(`${Paths.fieldReport.url}?date=${selectedDate}&userId=${selectedUser}`),
    [selectedDate, selectedUser]
  );
  return (
    <Stack gap={2}>
      <Card>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <SelectListFilter
                showClear={false}
                filter={{
                  name: 'user',
                  label: isLoadingUsers ? 'Loading...' : 'User',
                  type: 'SelectList',
                  disabled: isLoadingUsers,
                  selectListItems: [
                    {
                      text: 'All',
                      value: 'all',
                    },
                    ...(users ?? []).map(u => ({
                      text: u.userName,
                      value: u.userId,
                    })),
                  ],
                }}
                values={[selectedUser]}
                onChange={value => {
                  setSelectedUser(value?.[0]);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DateFilter
                filter={{
                  name: 'report-date',
                  label: 'Report Date',
                  type: 'Date',
                }}
                values={[selectedDate]}
                onChange={value => {
                  setSelectedDate(value?.[0]);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
      {selectedDate && selectedUser && (
        <>
          <ScheduledServices
            isFieldReport
            dateLinkModal={false}
            title="Maintenance"
            noResultsText="No Maintenance Services."
            redirect={redirect}
            selectedUserFilter={selectedUser === 'all' ? undefined : selectedUser}
            dateRange={{
              selection: {
                startDate: startOfDay(new Date(selectedDate)),
                endDate: new Date(selectedDate),
                key: 'selection',
              },
            }}
            showNonClickableAttributes={false}
            hasQueryParamFiltering={false}
          />
          <RepairVisits
            selectedUserFilter={selectedUser === 'all' ? undefined : selectedUser}
            selectedDateFilter={selectedDate}
            redirect={redirect}
          />
        </>
      )}
    </Stack>
  );
};
