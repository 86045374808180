import React from 'react';
import { Grid, styled } from '@mui/material';
import { IRepairVisitPhoto } from '../../../models';

interface IVisitPhotoGridProps {
  openModal: (isOpen: boolean) => void;
  setSource: (source: string) => void;
  images: IRepairVisitPhoto[];
}

export const VisitPhotoGrid: React.FC<IVisitPhotoGridProps> = props => {
  const imageClicked = (source: string) => {
    props.openModal(true);
    props.setSource(source);
  };

  return (
    <Grid display="grid" gap="10px" gridTemplateColumns="repeat(auto-fill, minmax(240px, 1fr))">
      {props.images.map(image => (
        <div key={image.visitPhotoId}>
          <ImgButton
            src={image.urlPath}
            alt={image.photoName}
            onClick={() => imageClicked(image.urlPath)}
          />
          {image.photoName}
        </div>
      ))}
    </Grid>
  );
};

const ImgButton = styled('img')(() => ({
  width: '100%',
  height: '70%',
  objectFit: 'cover',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));
