import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DatePicker } from '../../components';
import { IServiceRouteDetail } from '../../models';
import React, { useState, useEffect, FC } from 'react';
import { isValidDate } from '../../helpers';
import { addDays } from 'date-fns';
import { Paths } from '../../constants';

interface IRouteDayPicker {
  selectedRoute?: IServiceRouteDetail;
  selectedServiceDate?: string;
  hasChanges: boolean;
  isLoadingSelectedRoute?: boolean;
  setCurrentServiceRouteId: React.Dispatch<React.SetStateAction<string | number>>;
  confirmChangesLoss: () => Promise<boolean>;
}

export const RouteDayPicker: FC<IRouteDayPicker> = ({
  isLoadingSelectedRoute,
  selectedServiceDate,
  setCurrentServiceRouteId,
  confirmChangesLoss,
  hasChanges,
  selectedRoute,
}) => {
  const history = useHistory();
  const [serviceDate, setServiceDate] = useState(selectedServiceDate ?? selectedRoute?.serviceDate);

  const getTickId = (date: Date) => date.getTime() * 10000 + 621355968000000000;

  const handleDateChange = async (date: any) => {
    if (isValidDate(date)) {
      const goToDate = () => {
        // get the tickId
        const ticksId = getTickId(date);
        setServiceDate(date);
        setCurrentServiceRouteId(ticksId);
        let newUrl = `${Paths.routes.url}/${ticksId}`;
        history.push(newUrl);
      };
      if (hasChanges) {
        const result = await confirmChangesLoss();
        if (result) {
          goToDate();
        }
        return;
      }
      goToDate();
    }
  };

  const onPrevious = async () => {
    const goPrev = () => {
      if (selectedRoute) {
        let newUrl = `/routes/${selectedRoute?.previousRouteId}`;
        history.push(newUrl, {
          key: selectedRoute?.previousRouteId,
        });
        setCurrentServiceRouteId(String(selectedRoute?.previousRouteId));
      } else {
        const previousTickId = getTickId(addDays(new Date(serviceDate!), -1));
        history.push(`/routes/${previousTickId}`, {
          key: previousTickId,
        });
        setCurrentServiceRouteId(String(previousTickId));
      }
    };

    if (hasChanges) {
      const result = await confirmChangesLoss();
      if (result) {
        goPrev();
      }
      return;
    }
    goPrev();
  };

  const onNext = async () => {
    const goNext = () => {
      if (selectedRoute) {
        let newUrl = `/routes/${selectedRoute?.nextRouteId}`;
        history.push(newUrl, {
          key: selectedRoute?.nextRouteId,
        });
        setCurrentServiceRouteId(String(selectedRoute?.nextRouteId));
      } else {
        const nextTickId = getTickId(addDays(new Date(serviceDate!), 1));
        history.push(`/routes/${nextTickId}`, {
          key: nextTickId,
        });
        setCurrentServiceRouteId(String(nextTickId));
      }
    };

    if (hasChanges) {
      const result = await confirmChangesLoss();
      if (result) {
        goNext();
      }
      return;
    }
    goNext();
  };

  useEffect(() => {
    setServiceDate(selectedServiceDate ?? selectedRoute?.serviceDate);
  }, [selectedServiceDate, selectedRoute]);

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        disabled={isLoadingSelectedRoute}
        onClick={onPrevious}
        title="Previous Day"
        color="primary"
      >
        <NavigateBefore fontSize="large" />
      </IconButton>
      <DatePicker
        label={'Service Date'}
        format={'MM/dd/yyyy'}
        onChange={(date: any) => handleDateChange(date)}
        value={serviceDate ? new Date(serviceDate) : null}
        slotProps={{
          textField: {
            inputProps: {
              'data-testid': 'service-date-field',
            },
            size: 'small',
            InputLabelProps: {
              shrink: true,
            },
            sx: {
              width: {
                xs: '100%',
                md: '17rem',
                lg: '20rem',
              },
            },
            disabled: isLoadingSelectedRoute,
          },
        }}
      />
      <IconButton
        disabled={isLoadingSelectedRoute}
        onClick={onNext}
        title="Next Day"
        color="primary"
      >
        <NavigateNext fontSize="large" />
      </IconButton>
    </Box>
  );
};
