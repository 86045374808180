import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useContext, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { UserContext } from '../../../context';
import {
  ConfirmPrompt,
  DisplayGroup,
  Loader,
  SaveButton,
  Select,
  TextField,
} from '../../../components';
import { Box, Grid, IconButton, MenuItem, Tooltip } from '@mui/material';
import { deepEqual } from 'fast-equals';
import { defaultUnsavedChangesMessage } from '../../../constants';
import { useQuery } from 'react-query';
import {
  EAccountProvider,
  IAccountingProviderSettings,
  IAccountingProviderSettingsAccount,
  IResponse,
  EAccountProviderEntryType,
} from '../../../models';
import {
  getAccountingProviderSettings,
  getAccountingProviderSettingsAccounts,
  storeAccountingProviderSettings,
} from '../../../fetch';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

interface IQuickBooksOptionsFormProps {}

const Schema = Yup.object().shape({
  entryType: Yup.string().required('Required'),
  accountsReceivableAccountId: Yup.string().required('Required'),
  undepositedFundsAccountId: Yup.string().required('Required'),
  unearnedIncomeAccountId: Yup.string().required('Required'),
  journalEntryPrefix: Yup.string()
    .max(10, 'Max character limit of 10')
    .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed')
    .required('Required'),
});

export const QuickBooksOptionsForm: FC<IQuickBooksOptionsFormProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, accountingProvider, reloadAccountingProvider } = useContext(UserContext);
  const featureFlags = useFlags();
  const countSettingsRef = useRef(0);
  const countProviderRef = useRef(0);

  const {
    isLoading: isLoadingAccountingProviderSettings,
    data: currentAccountingProviderSettings,
    refetch: refetchAccountingProviderSettings,
  } = useQuery<IAccountingProviderSettings, Error>(
    ['getAccountingProviderSettings', user],
    () => {
      countSettingsRef.current += 1;
      return getAccountingProviderSettings(user?.officeId!);
    },
    {
      notifyOnChangeProps: 'tracked',
      enabled: accountingProvider?.provider === EAccountProvider.QuickBooks ? true : false,
      // on initial connection if we have a valid connection but no ids, refetch every second until we have ids
      refetchInterval: data => {
        if (data?.accountsReceivableAccountId === null && countSettingsRef.current < 3) {
          return 1000;
        }
        return false;
      },
    }
  );

  const { isLoading: isLoadingAccountingProviderAccounts, data: accountingProviderAccounts } =
    useQuery<IAccountingProviderSettingsAccount[], Error>(
      ['getAccountingProviderSettingsAccounts', user],
      async () => {
        countProviderRef.current += 1;
        const res: IResponse<IAccountingProviderSettingsAccount[]> =
          await getAccountingProviderSettingsAccounts(user?.officeId!, { perPage: -1 });
        return res?.records ?? [];
      },
      {
        notifyOnChangeProps: 'tracked',
        enabled: accountingProvider?.provider === EAccountProvider.QuickBooks ? true : false,
        // on initial connection if we have a valid connection but no accounts, refetch every second until we have accounts
        refetchInterval: data => {
          if (data?.length === 0 && countProviderRef.current < 3) {
            return 1000;
          }
          return false;
        },
      }
    );

  const isLoading = useMemo(
    () => isLoadingAccountingProviderAccounts || isLoadingAccountingProviderSettings,
    [isLoadingAccountingProviderAccounts, isLoadingAccountingProviderSettings]
  );
  const accountsReceivableData: IAccountingProviderSettingsAccount[] = useMemo(
    () =>
      accountingProviderAccounts?.filter(account => account.accountType === 'AccountsReceivable') ??
      [],
    [accountingProviderAccounts]
  );
  const undepositedFundsData: IAccountingProviderSettingsAccount[] = useMemo(
    () =>
      accountingProviderAccounts?.filter(account => account.accountType === 'UndepositedFunds') ??
      [],
    [accountingProviderAccounts]
  );
  const unearnedIncomeAccountData: IAccountingProviderSettingsAccount[] = useMemo(
    () => accountingProviderAccounts?.filter(account => account.accountType === 'Liability') ?? [],
    [accountingProviderAccounts]
  );
  const setDefaultEntryType = () => {
    // No longer need to clear set value based on FF because the user can't update the value once set. Instead set Journaling as default if flag is off and no value currently
    if (
      currentAccountingProviderSettings &&
      currentAccountingProviderSettings.entryType !== 'None'
    ) {
      return currentAccountingProviderSettings?.entryType; // Set based on saved value, if value is not None
    }
    if (
      accountingProvider?.provider === EAccountProvider.QuickBooks &&
      featureFlags.quickbooksInvoicing
    ) {
      return EAccountProviderEntryType.Invoice; // Default to Invoicing, if available
    }
    if (featureFlags.quickbooksJournaling) {
      return EAccountProviderEntryType.JournalEntry; // Default to Journaling, if invoicing not available
    }
    return '';
  };
  const showEntryTypeEmptyState = useMemo(
    () =>
      !featureFlags.quickbooksInvoicing &&
      !featureFlags.quickbooksJournaling &&
      (!currentAccountingProviderSettings ||
        currentAccountingProviderSettings?.entryType === 'None'),
    [
      featureFlags.quickbooksInvoicing,
      featureFlags.quickbooksJournaling,
      currentAccountingProviderSettings,
    ]
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          entryType: setDefaultEntryType(),
          accountsReceivableAccountId:
            currentAccountingProviderSettings?.accountsReceivableAccountId ??
            accountsReceivableData[0]?.accountId ??
            '',
          undepositedFundsAccountId:
            currentAccountingProviderSettings?.undepositedFundsAccountId ??
            undepositedFundsData[0]?.accountId ??
            '',
          unearnedIncomeAccountId:
            currentAccountingProviderSettings?.unearnedIncomeAccountId ??
            unearnedIncomeAccountData[0]?.accountId ??
            '',
          journalEntryPrefix: currentAccountingProviderSettings?.journalEntryPrefix ?? 'PC',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          try {
            await storeAccountingProviderSettings(user?.officeId!, values);
            enqueueSnackbar('Successfully updated QuickBooks options!', {
              variant: 'success',
            });
            actions.resetForm({
              values,
            });
            reloadAccountingProvider();
            await refetchAccountingProviderSettings();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error saving QuickBooks options, please try again.', {
              variant: 'error',
            });
          }
        }}
      >
        {({ isSubmitting, values, handleSubmit, isValid, initialValues }) => {
          const isSaveDisabled = !isValid || isSubmitting || isLoading || showEntryTypeEmptyState;
          return (
            <>
              <ConfirmPrompt
                when={!deepEqual(initialValues, values)}
                message={defaultUnsavedChangesMessage}
              />
              {(isSubmitting || isLoading) && <Loader type="overlay" position="centered" />}
              <Form onSubmit={handleSubmit} autoComplete="none">
                <Grid container spacing={2}>
                  <Grid item xs={12} mb={2}>
                    {currentAccountingProviderSettings?.entryType === 'None' ||
                    !currentAccountingProviderSettings ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <Select
                            autoComplete="nope"
                            label="Entry Type"
                            required
                            name="entryType"
                            InputLabelProps={{
                              shrink: showEntryTypeEmptyState ? true : undefined, // Undefined required to avoid overlap bug
                            }}
                            SelectProps={{
                              displayEmpty: showEntryTypeEmptyState,
                            }}
                          >
                            {/* Avoid empty dropdown if both flags are off */}
                            {showEntryTypeEmptyState && (
                              <MenuItem value="" selected>
                                Contact Support
                              </MenuItem>
                            )}
                            {featureFlags.quickbooksJournaling && (
                              <MenuItem value={EAccountProviderEntryType.JournalEntry}>
                                Journaling
                              </MenuItem>
                            )}
                            {featureFlags.quickbooksInvoicing && (
                              <MenuItem value={EAccountProviderEntryType.Invoice}>
                                Invoicing
                              </MenuItem>
                            )}
                          </Select>
                        </Grid>
                      </Grid>
                    ) : (
                      <DisplayGroup isInline label="Entry Type:" labelId="entryType">
                        {currentAccountingProviderSettings.entryType ===
                        EAccountProviderEntryType.JournalEntry
                          ? 'Journaling'
                          : 'Invoicing'}
                      </DisplayGroup>
                    )}
                  </Grid>

                  {values.entryType === EAccountProviderEntryType.JournalEntry && (
                    <Grid item xs={12} sm={6} lg={4} xl={3}>
                      <Select
                        disabled={isLoading}
                        autoComplete="nope"
                        label={isLoading ? 'Loading...' : 'Accounts Receivable Account'}
                        required
                        name="accountsReceivableAccountId"
                      >
                        {accountsReceivableData.map(account => (
                          <MenuItem
                            key={`accountsReceivableAccountId-${account.accountId}`}
                            value={account.accountId}
                          >
                            {account.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}
                  {values.entryType === EAccountProviderEntryType.JournalEntry && (
                    <Grid item xs={12} sm={6} lg={4} xl={3}>
                      <Select
                        disabled={isLoading}
                        autoComplete="nope"
                        label={isLoading ? 'Loading...' : 'Undeposited Funds Account'}
                        required
                        name="undepositedFundsAccountId"
                      >
                        {undepositedFundsData.map(account => (
                          <MenuItem
                            key={`undepositedFundsAccountId-${account.accountId}`}
                            value={account.accountId}
                          >
                            {account.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}
                  {values.entryType === EAccountProviderEntryType.JournalEntry && (
                    <Grid item xs={12} sm={6} lg={4} xl={3}>
                      <Select
                        disabled={isLoading}
                        autoComplete="nope"
                        label={isLoading ? 'Loading...' : 'Deposits/Prepayments'}
                        required
                        name="unearnedIncomeAccountId"
                      >
                        {unearnedIncomeAccountData.map(account => (
                          <MenuItem
                            key={`unearnedIncomeAccountId-${account.accountId}`}
                            value={account.accountId}
                          >
                            {account.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <Box display="flex" alignItems="flex-start">
                      <TextField
                        required
                        name="journalEntryPrefix"
                        label="Journal Entry Prefix In Quickbooks"
                      />
                      <Tooltip
                        placement="bottom"
                        title={
                          <div>
                            Refers to the set of letters that appear at the beginning of a journal
                            entry number in Quickbooks.
                          </div>
                        }
                      >
                        <Box display="flex">
                          <IconButton
                            color="primary"
                            sx={{ marginTop: 0.5, backgroundColor: 'transparent !important' }}
                          >
                            <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
                <Box marginTop="1rem" display={'flex'} justifyContent={'flex-end'}>
                  <SaveButton disabled={isSaveDisabled} />
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
