import { FC } from 'react';
import { CardTitle, Card } from '../../../components';
import { PoolStructureDetails } from './pool-structure-details';

type stringBoolNumber = 'string' | 'boolean' | 'number';

export const generalInfoSectionFields: { name: string; type: stringBoolNumber }[] = [
  { name: 'size', type: 'string' },
  { name: 'depth', type: 'string' },
  { name: 'tile', type: 'string' },
  { name: 'skimBasket', type: 'string' },
  { name: 'coping', type: 'string' },
  { name: 'skimCover', type: 'string' },
  { name: 'mastic', type: 'string' },
  { name: 'coverType', type: 'string' },
];

export const conditionNotesSectionFields: {
  name: string;
  type: stringBoolNumber;
}[] = [
  { name: 'condition', type: 'string' },
  { name: 'notes', type: 'string' },
];

export const spaInfoSectionFields: {
  name: string;
  type: stringBoolNumber;
}[] = [
  { name: 'hasSpaBoosterPump', type: 'boolean' },
  { name: 'spaVolume', type: 'string' },
  { name: 'spaBoosterStyle', type: 'string' },
  { name: 'spaBrand', type: 'string' },
  { name: 'spaBoosterHp', type: 'string' },
  { name: 'spaModel', type: 'string' },
  { name: 'spaSerialNumber', type: 'string' },
  { name: 'spaDateOfPurchase', type: 'string' },
];

export const pumpFilterCleanerSectionFields: {
  name: string;
  type: stringBoolNumber;
}[] = [
  { name: 'pumpBasket', type: 'string' },
  { name: 'airRelief', type: 'string' },
  { name: 'poolMotor', type: 'string' },
  { name: 'cleanerStyle', type: 'string' },
  { name: 'poolHp', type: 'string' },
  { name: 'cleanerPump', type: 'string' },
  { name: 'hasConduit', type: 'boolean' },
  { name: 'cleanerClock', type: 'string' },
  { name: 'conduitCondition', type: 'string' },
  { name: 'autoCleaner', type: 'boolean' },
  { name: 'timeClock', type: 'string' },
  { name: 'cleanerVolts', type: 'string' },
  { name: 'timeClockVolts', type: 'string' },
];

export const pipingInfoSectionFields: {
  name: string;
  type: stringBoolNumber;
}[] = [
  { name: 'gauge', type: 'string' },
  { name: 'valveSize', type: 'string' },
  { name: 'mainDrain', type: 'string' },
  { name: 'controlValve', type: 'string' },
];

interface IPoolStructureCard {
  globalFormik?: {
    values: any;
    handleChange: (e: React.ChangeEvent<any>) => void;
    setFieldValue: (name: string, val: any) => void;
  };
  readOnly?: boolean;
  isServiceDetailPage?: boolean;
  isExpanded?: boolean;
  isCollapsible?: boolean;
  initialExpand?: boolean;
  siteId: string;
  shouldPoolRefresh?: boolean;
  shouldFetch: boolean;
  isCardLayout?: boolean;
  triggerFormValidation?: boolean;
}

export const PoolStructureCard: FC<IPoolStructureCard> = ({
  globalFormik,
  readOnly,
  shouldPoolRefresh,
  isServiceDetailPage,
  isExpanded,
  isCollapsible = false,
  initialExpand = false,
  siteId,
  shouldFetch,
  isCardLayout = true,
  triggerFormValidation,
}) => {
  if (!isCardLayout) {
    return (
      <PoolStructureDetails
        siteId={siteId}
        globalFormik={globalFormik}
        readOnly={readOnly}
        shouldFetch={shouldFetch}
        shouldPoolRefresh={shouldPoolRefresh}
        isServiceDetailPage={isServiceDetailPage}
        triggerFormValidation={triggerFormValidation}
        isCollapsible={isCollapsible}
      />
    );
  }
  return (
    <Card>
      <CardTitle
        title="Pool Structure"
        withExpand={isServiceDetailPage || isCollapsible}
        initialExpand={initialExpand}
        overrideExpand={isExpanded}
        unmountOnExit={isServiceDetailPage}
      >
        <PoolStructureDetails
          siteId={siteId}
          globalFormik={globalFormik}
          readOnly={readOnly}
          shouldFetch={shouldFetch}
          shouldPoolRefresh={shouldPoolRefresh}
          isServiceDetailPage={isServiceDetailPage}
          triggerFormValidation={triggerFormValidation}
          isCollapsible={isCollapsible}
        />
      </CardTitle>
    </Card>
  );
};
