import { IconButton, ListItem, Menu, ListItemButton } from '@mui/material';
import { useState, FC } from 'react';
import { MoreVert } from '@mui/icons-material';
import { Link } from '../../components';
import { ICommission } from '../../models/commissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteCommission } from '../../fetch';
import { useConfirm } from '../../hooks';
import { useSnackbar } from 'notistack';
import { Paths } from '../../constants';

interface ICommissionsActionButton {
  commission: ICommission;
  redirect?: string;
  setDeleting: (val: boolean) => void;
  refetchData: () => void;
}

export const CommissionsActionButton: FC<ICommissionsActionButton> = ({
  commission,
  redirect,
  refetchData,
  setDeleting,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirm = useConfirm();

  return (
    <div>
      <IconButton
        color="primary"
        id="services-actions"
        aria-controls={open ? 'services-actions' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="services-actions"
        MenuListProps={{
          'aria-labelledby': 'services-actions',
          sx: {
            padding: 0,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Link to={`${Paths.commissions.url}/${commission?.commissionId}${redirect}`}>
          <ListItem disablePadding>
            <ListItemButton>
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
              Edit Commission
            </ListItemButton>
          </ListItem>
        </Link>
        <Link
          to={
            commission.accountId
              ? `${Paths.customers.url}/${commission?.accountId}/edit${redirect}`
              : ''
          }
        >
          <ListItem disablePadding>
            <ListItemButton>
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
              Edit Customer
            </ListItemButton>
          </ListItem>
        </Link>
        <ListItem disablePadding>
          <ListItemButton
            onClick={async () => {
              const result = await confirm('Are you sure you want to delete this commission?');
              if (result) {
                try {
                  setDeleting(true);
                  await deleteCommission(commission.commissionId);
                  enqueueSnackbar(`Commission Deleted!`, {
                    variant: 'success',
                  });
                  refetchData();
                } catch (error) {
                } finally {
                  setDeleting(false);
                }
              }
            }}
            sx={{ color: theme => theme.palette.error.main }}
          >
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: '.5rem' }} />
            Delete Commission
          </ListItemButton>
        </ListItem>
      </Menu>
    </div>
  );
};
