import { FC, useEffect, useMemo, useState } from 'react';
import {
  getEstimateLineItem,
  updateEstimateLaborLineItem,
  createEstimateLaborLineItem,
} from '../../fetch';
import {
  IEstimateLineItem,
  IEstimateLineItemDetail,
  IEstimateLaborLineItem,
  IResponse,
  IInventory,
  LaborFeeTypes,
} from '../../models';
import { Formik } from 'formik';
import { formatMoney, convertToNumber } from '../../helpers';
import { EstimateLaborRateModalDetails } from './estimate-labor-rate-modal-details';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useQuery } from 'react-query';

interface IEstimateLaborRateModal {
  estimateId: string;
  lineItemId: string | null;
  fetchEstimateLineItems: () => void;
  estimateLineItems: IEstimateLineItem[];
  isOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  setEstimateLineItems: (val: IEstimateLineItem[]) => void;
  isEditable?: boolean;
  handleSalesTax: (lineItems: IEstimateLineItem[]) => Promise<IEstimateLineItem[]>;
}

const FORM_VALIDATION = Yup.object().shape({
  sortOrder: Yup.number()
    .typeError('Must be a number')
    .required('Required')
    .test('Is positive?', "Line number must be greater than '0'", (value: any) => value > 0),
  laborFeeType: Yup.string().required('Required'),
  fixedLaborRate: Yup.mixed().when('laborFeeType', {
    is: (laborFeeType: string) => laborFeeType === LaborFeeTypes.Fixed,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  initialLaborFees: Yup.mixed().when('laborFeeType', {
    is: (laborFeeType: string) => laborFeeType === LaborFeeTypes.Variable,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  additionalLaborFees: Yup.mixed().when('laborFeeType', {
    is: (laborFeeType: string) => laborFeeType === LaborFeeTypes.Variable,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  initialLaborFeesDuration: Yup.mixed().when('laborFeeType', {
    is: (laborFeeType: string) => laborFeeType === LaborFeeTypes.Variable,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  estimatedLaborDuration: Yup.mixed().when('laborFeeType', {
    is: (laborFeeType: string) => laborFeeType === LaborFeeTypes.Variable,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  postLaborChargesAs: Yup.string().required('Required'),
  totalCharges: Yup.string(),
  details: Yup.string(),
});

export const EstimateLaborRateModal: FC<IEstimateLaborRateModal> = ({
  estimateId,
  lineItemId,
  setEstimateLineItems,
  estimateLineItems,
  isOpen,
  onClose,
  isEditable = true,
  handleSalesTax,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [postLaborOptions, setPostLaborOptions] = useState<IResponse<IInventory[]> | null>(null);
  const [lineItem, setLineItem] = useState<IEstimateLineItemDetail | undefined>(undefined);

  const filteredLineItems = useMemo(() => {
    const filteredItems = estimateLineItems.filter(
      item => item.tranCodeDescription !== 'Security Deposit' && item.tranCodeDescription !== 'Tax'
    );
    const lastItemNumber =
      filteredItems[filteredItems.length - 1]?.sortOrder ?? filteredItems.length;
    return lastItemNumber + 1;
  }, [estimateLineItems]);

  const { isLoading: isLoadingLineItem } = useQuery<IEstimateLineItemDetail, Error>(
    ['getEstimateLineItem', estimateId, lineItemId],
    () => getEstimateLineItem(estimateId!, lineItemId!),
    {
      onSuccess: data => {
        setLineItem(data);
      },
      enabled: isOpen && !!estimateId && !!lineItemId,
    }
  );

  useEffect(() => {
    if (isOpen && !estimateId && Number(lineItemId) <= 0) {
      const selected = estimateLineItems.find(item => item.estimateLineItemId === lineItemId);
      const selectedLookup = postLaborOptions?.records?.find(
        o => o.inventoryId === selected?.postLaborChargesAs
      );
      if (selected) {
        setLineItem({
          estimateLineItemId: selected.estimateLineItemId,
          sortOrder: selected.sortOrder,
          tranCodeId: selected.tranCodeId,
          tranCodeDescription: '',
          lookupCode: selectedLookup?.inventoryId?.toString() ?? '',
          details: selected.details ?? '',
          serialNumber: selected.serialNumber ?? '',
          rate: `${formatMoney(selected?.rate, 2)}`,
          quantity: selected.quantity,
          isSerialized: selected.isSerialized,
          laborFeeType: selected.laborFeeType,
          fixedLaborRate: selected.fixedLaborRate,
          initialLaborFees: selected.initialLaborFees,
          additionalLaborFees: selected.additionalLaborFees,
          initialLaborFeesDuration: selected.initialLaborFeesDuration,
          estimatedLaborDuration: selected.estimatedLaborDuration,
          storeInventoryId: selectedLookup?.inventoryId,
        });
      }
    }
  }, [estimateId, lineItemId, isOpen, estimateLineItems, postLaborOptions]);

  return (
    <Formik
      initialValues={{
        sortOrder: lineItem?.sortOrder ?? `${filteredLineItems}`,
        laborFeeType: lineItem?.laborFeeType ?? LaborFeeTypes.Fixed,
        fixedLaborRate: lineItem?.fixedLaborRate ? formatMoney(lineItem?.fixedLaborRate, 2) : '',
        initialLaborFees: lineItem?.initialLaborFees
          ? formatMoney(lineItem?.initialLaborFees, 2)
          : '',
        additionalLaborFees: lineItem?.additionalLaborFees
          ? formatMoney(lineItem?.additionalLaborFees, 2)
          : '',
        initialLaborFeesDuration: lineItem?.initialLaborFeesDuration ?? '',
        estimatedLaborDuration: lineItem?.estimatedLaborDuration ?? '',
        postLaborChargesAs: lineItem?.storeInventoryId?.toString() ?? '',
        totalCharges: '',
        details: lineItem?.details ?? '',
      }}
      enableReinitialize
      validationSchema={FORM_VALIDATION}
      onSubmit={async (values, actions) => {
        if (!estimateId) {
          const selectedLookupCode = postLaborOptions?.records?.find(
            o => Number(o.inventoryId) === Number(values.postLaborChargesAs)
          );
          const newLineItemData = {
            sortOrder: Number(values.sortOrder),
            tranCodeId: `${-estimateLineItems.length}`,
            rate: values.totalCharges
              ? convertToNumber(values.totalCharges)
              : convertToNumber(values.fixedLaborRate),
            quantity: 1,
            details: values.details,
            tranCodeDescription: 'Department',
            serialNumber: '',
            lookupCode: selectedLookupCode?.lookupCode,
            amount: values.totalCharges
              ? convertToNumber(values.totalCharges)
              : convertToNumber(values.fixedLaborRate),
            isLaborLineItem: true,
            laborFeeType: values.laborFeeType ?? null,
            fixedLaborRate: values.fixedLaborRate ? convertToNumber(values.fixedLaborRate) : null,
            initialLaborFees: values.initialLaborFees
              ? convertToNumber(values.initialLaborFees)
              : null,
            additionalLaborFees: values.additionalLaborFees
              ? convertToNumber(values.additionalLaborFees)
              : null,
            initialLaborFeesDuration: values.initialLaborFeesDuration ?? '',
            estimatedLaborDuration: values.estimatedLaborDuration
              ? convertToNumber(values.estimatedLaborDuration)
              : null,
            postLaborChargesAs: values.postLaborChargesAs
              ? convertToNumber(values.postLaborChargesAs)
              : null,
            inventoryId: values.postLaborChargesAs
              ? convertToNumber(values.postLaborChargesAs)
              : null,
          };
          if (!lineItemId) {
            const items = await handleSalesTax([
              ...estimateLineItems,
              {
                estimateLineItemId: `${-estimateLineItems.length}`,
                ...newLineItemData,
              },
            ]);
            setEstimateLineItems(items);
          } else {
            const copy: IEstimateLineItem[] = JSON.parse(JSON.stringify(estimateLineItems));
            const currentIndex = copy.findIndex(c => c.estimateLineItemId === lineItemId);
            copy[currentIndex] = {
              ...copy[currentIndex],
              ...newLineItemData,
            };
            const items = await handleSalesTax(copy);
            setEstimateLineItems(items);
          }
          onClose();
          setLineItem(undefined);
          actions.resetForm();
        } else {
          const payload: IEstimateLaborLineItem = {
            sortOrder: convertToNumber(values.sortOrder),
            details: values.details,
            laborFeeType: values.laborFeeType ?? null,
            fixedLaborRate: values.fixedLaborRate ? convertToNumber(values.fixedLaborRate) : null,
            initialLaborFees: values.initialLaborFees
              ? convertToNumber(values.initialLaborFees)
              : null,
            additionalLaborFees: values.additionalLaborFees
              ? convertToNumber(values.additionalLaborFees)
              : null,
            initialLaborFeesDuration: values.initialLaborFeesDuration ?? '',
            estimatedLaborDuration: values.estimatedLaborDuration
              ? convertToNumber(values.estimatedLaborDuration)
              : null,
            postLaborChargesAs: values.postLaborChargesAs
              ? convertToNumber(values.postLaborChargesAs)
              : null,
          };
          try {
            if (lineItemId) {
              await updateEstimateLaborLineItem(estimateId, lineItemId, payload);
              enqueueSnackbar('Labor Item updated!', {
                variant: 'success',
              });
            } else {
              await createEstimateLaborLineItem(estimateId, payload);
              enqueueSnackbar('Labor Item created!', {
                variant: 'success',
              });
            }
            onClose(true);
            setLineItem(undefined);
            actions.resetForm();
          } catch (error: any) {
            enqueueSnackbar(error?.Detail ?? 'Error, saving labor line item.', {
              variant: 'error',
            });
          }
        }
      }}
    >
      {({ isSubmitting, isValid, resetForm }) => {
        return (
          <EstimateLaborRateModalDetails
            lineItemId={lineItemId}
            estimateLineItems={estimateLineItems}
            isOpen={isOpen}
            onClose={shouldUpdate => {
              onClose(shouldUpdate);
              setLineItem(undefined);
              resetForm();
            }}
            isSubmitting={isSubmitting}
            isValid={isValid}
            isLoadingLineItem={isLoadingLineItem}
            lineItem={lineItem}
            setPostLaborOptions={setPostLaborOptions}
            isEditable={isEditable}
          />
        );
      }}
    </Formik>
  );
};
