import { Box, ListItem, ListItemButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  ServerSideDataGrid,
  useDataGrid,
  GridDataFetcher,
  CardFiltersLayout,
  Link,
  TableActionsMenu,
  Tabs,
  ITab,
} from '../../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { faEdit, faEye, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useMemo, useState, useCallback, useEffect } from 'react';
import { getTasks } from '../../../fetch';
import {
  formatDate,
  truncate,
  hasCorrectUserPermissions,
  hyphenSeparateTwoInputs,
  formatRawDate,
  getCustomerDetailRoute,
} from '../../../helpers';
import { ISimpleDateRange, ITask, IAccountDetail, IDateRange } from '../../../models';
import { TasksFilters } from './tasks-filters';
import { SearchParamsContext, UserContext, ESearchParams } from '../../../context';
import { Paths, Permissions } from '../../../constants';
import { QuickCompleteModal } from '.';
import { BrandingContext } from '../../../context/branding-context';

export interface ITasksListDetailsProps {
  dateRange?: ISimpleDateRange;
  accountId?: string;
  currentCustomer?: IAccountDetail | null;
  redirect: string;
  isEditable?: boolean;
  excludeFilters?: string[];
  defaultUserFilter?: string;
  scheduledServiceId?: string;
  hasQueryParamFiltering?: boolean;
  setIsShowingFilters: (val: boolean) => void;
  isShowingFilters: boolean;
}

export const TasksListDetails: FC<ITasksListDetailsProps> = ({
  dateRange,
  accountId,
  currentCustomer,
  redirect,
  isEditable = true,
  excludeFilters,
  defaultUserFilter,
  scheduledServiceId,
  hasQueryParamFiltering = true,
  setIsShowingFilters,
  isShowingFilters,
}) => {
  const {
    setSearchParams,
    queryParams,
    paramStartDate,
    paramEndDate,
    paramTab,
    paramUserId,
    paramAccountId,
    paramPriority,
    paramTaskType,
    hasSearchParamValues,
    arrSearchParamsValues,
  } = useContext(SearchParamsContext);

  const { isPoolService } = useContext(BrandingContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  const { user } = useContext(UserContext);

  const [selectedAccountId, setSelectedAccountId] = useState<string>(
    paramAccountId ? paramAccountId : ''
  );
  const [completeModalIsOpen, setCompleteModalIsOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: paramStartDate ? new Date(paramStartDate) : dateRange?.selection?.startDate ?? null,
    endDate: paramEndDate ? new Date(paramEndDate) : dateRange?.selection?.endDate ?? null,
    key: 'selection',
    inputValue: '',
  });
  const [selectedUserId, setSelectedUserId] = useState<string>(defaultUserFilter ?? '');
  const [selectedPriority, setSelectedPriority] = useState<string>('');
  const [filters, setFilters] = useState<{
    startDate?: string | null;
    endDate?: string | null;
    accountId?: string;
    taskDefinitionId?: string;
    userId?: string | null;
    priority?: string | null;
  } | null>(
    defaultUserFilter
      ? { userId: defaultUserFilter }
      : {
          startDate: paramStartDate ? new Date(paramStartDate).toISOString() : null,
          endDate: paramEndDate ? new Date(paramEndDate).toISOString() : null,
          accountId: paramAccountId ? paramAccountId : '',
          taskDefinitionId: paramTaskType ? paramTaskType : '',
          userId: paramUserId ? paramUserId : '',
          priority: paramPriority ? paramPriority : '',
        }
  );
  const [hasAppliedFilters, setHasAppliedFilters] = useState(
    hasSearchParamValues ? true : !!defaultUserFilter
  );
  const [selectedTab, setSelectedTab] = useState<string>(paramTab ? paramTab : 'O');
  const [selectedTaskDefId, setSelectedTaskDefId] = useState<string>(
    paramTaskType ? paramTaskType : ''
  );

  useEffect(() => {
    if (paramAccountId !== null) {
      setSelectedAccountId(paramAccountId);
    }
    if (paramUserId !== null) {
      setSelectedUserId(paramUserId);
    }
    if (paramTaskType !== null) {
      setSelectedTaskDefId(paramTaskType);
    }
    if (paramPriority !== null) {
      setSelectedPriority(paramPriority);
    }
    if (paramEndDate !== null || paramStartDate !== null) {
      setSelectedDateRange({
        ...selectedDateRange,
        endDate: paramEndDate ? new Date(paramEndDate!) : null,
        startDate: paramStartDate ? new Date(paramStartDate!) : null,
      });
    }

    if (paramTab !== null) {
      setSelectedTab(paramTab);
    }
    if (hasSearchParamValues && !hasAppliedFilters && !isShowingFilters) {
      setIsShowingFilters(true);
      setHasAppliedFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paramAccountId,
    paramPriority,
    paramTaskType,
    paramStartDate,
    paramEndDate,
    paramUserId,
    paramTab,
    hasSearchParamValues,
    hasAppliedFilters,
    isShowingFilters,
  ]);

  const dataFetcher: GridDataFetcher<ITask> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection, searchParams }) => {
      // convert the search params to an array of values
      const arrSearchParamsValues = Array.from(searchParams.values()).filter(
        p => !p.includes('redirect')
      );
      // don't call the api when this query param is present or on services page
      if (queryParams && queryParams.includes('redirect') && hasQueryParamFiltering) {
        return {
          continueLoading: true,
        };
      }

      const filterStartDate = filters?.startDate
        ? formatRawDate(new Date(filters?.startDate))
        : searchParams?.get(ESearchParams.startDate)
        ? formatRawDate(new Date(searchParams?.get(ESearchParams.startDate)!))
        : null;
      const passedInStartDate =
        dateRange?.selection?.startDate && formatRawDate(new Date(dateRange?.selection?.startDate));

      const filterEndDate = filters?.endDate
        ? formatRawDate(new Date(filters?.endDate))
        : searchParams?.get(ESearchParams.endDate)
        ? formatRawDate(new Date(searchParams?.get(ESearchParams.endDate)!))
        : null;
      const passedInEndDate =
        dateRange?.selection?.endDate && formatRawDate(new Date(dateRange?.selection?.endDate));
      let params: any = {
        sortBy: sortColumn === 'taskDate' ? 'WhenScheduled' : sortColumn,
        sortDirection: sortDirection ? sortDirection : selectedTab === 'O' ? 'asc' : 'desc',
        page: page + 1,
        perPage,
        isComplete: selectedTab === 'C',
        accountId: accountId ?? selectedAccountId,
        userId: filters?.userId ?? defaultUserFilter,
        priority: filters?.priority,
        taskDefinitionId: filters?.taskDefinitionId,
        scheduledServiceId: scheduledServiceId ?? null,
      };
      if (selectedTab === 'O') {
        params.includeOpen = true;
        params.whenScheduledStartDate =
          searchParams?.get(ESearchParams.startDate) !== null
            ? searchParams?.get(ESearchParams.startDate)!
            : passedInStartDate ?? filterStartDate;
        params.whenScheduledEndDate =
          searchParams?.get(ESearchParams.endDate) !== null
            ? searchParams?.get(ESearchParams.endDate)!
            : passedInEndDate ?? filterEndDate;
      }
      if (selectedTab === 'C') {
        params.includeOpen = false;
        params.whenCompletedStartDate =
          searchParams?.get(ESearchParams.startDate) !== null
            ? searchParams?.get(ESearchParams.startDate)!
            : passedInStartDate ?? filterStartDate;
        params.whenCompletedEndDate =
          searchParams?.get(ESearchParams.endDate) !== null
            ? searchParams?.get(ESearchParams.endDate)!
            : passedInEndDate ?? filterEndDate;
      }
      if (arrSearchParamsValues?.length > 0 && hasQueryParamFiltering) {
        params = {
          ...params,
          isComplete: searchParams?.get(ESearchParams.tab)
            ? searchParams?.get(ESearchParams.tab)! === 'C'
            : false,
          accountId: searchParams.get(ESearchParams.accountId)
            ? searchParams.get(ESearchParams.accountId)!
            : accountId,
          userId:
            searchParams?.get(ESearchParams.userId) !== null
              ? searchParams?.get(ESearchParams.userId)!
              : defaultUserFilter,
          priority:
            searchParams?.get(ESearchParams.priority) !== null
              ? searchParams?.get(ESearchParams.priority)!
              : '',
          taskDefinitionId:
            searchParams?.get(ESearchParams.taskType) !== null
              ? searchParams?.get(ESearchParams.taskType)!
              : '',
        };
      }
      try {
        const res = await getTasks(params);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading tasks, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    !isPoolService || !hasQueryParamFiltering
      ? [filters, dateRange, selectedTab]
      : [isPoolService || hasQueryParamFiltering ? arrSearchParamsValues.length : undefined]
  );
  const {
    rows: items,
    isLoading: isLoadingTasks,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    setPage,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchTasks,
  } = useDataGrid<ITask>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'tasks-grid',
      sortColumn: 'taskDate',
    },
    dataFetcher,
    shouldUseSearchParams: true,
  });

  const columns = useMemo(
    () =>
      [
        {
          field: 'taskDate',
          headerName: 'Date',
          minWidth: 100,
          maxWidth: 100,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return (
              <Link
                to={`${Paths.tasks.url}/${original.scheduledTaskId}${
                  currentCustomer || redirect ? '?' : ''
                }${
                  redirect
                    ? `redirect=${encodeURIComponent(
                        `${redirect}${
                          queryParams
                            ? `?${queryParams}${
                                currentCustomer ? `&accountId=${currentCustomer?.accountId}` : ''
                              }`
                            : ''
                        }`
                      )}`
                    : `${currentCustomer ? `accountId=${currentCustomer?.accountId}&` : ''}`
                }`}
              >
                <Tooltip title="Edit Task" placement="bottom">
                  <span>
                    {original.taskDate ? formatDate(original.taskDate?.split?.('T')?.[0]) : ''}
                  </span>
                </Tooltip>
              </Link>
            );
          },
        },
        !currentCustomer && {
          field: 'accountName',
          headerName: 'Customer',
          flex: 1,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            if (hasCorrectUserPermissions(Permissions.ViewCustomers, user!)) {
              return (
                <Link to={`${getCustomerDetailRoute(original?.accountId!)}`}>
                  <Tooltip placement="bottom" title="View Customer">
                    <span>{original.accountName}</span>
                  </Tooltip>
                </Link>
              );
            } else {
              return <span>{original.accountName}</span>;
            }
          },
        },
        selectedTab === 'O' && {
          field: 'priority',
          headerName: 'Priority',
          flex: 1,
          minWidth: 100,
        },
        {
          headerName: 'Task Type',
          field: 'taskType',
          flex: 1,
          minWidth: 100,
        },
        {
          headerName: 'Assigned To',
          field: 'assignedTo',
          flex: 1,
          minWidth: 150,
        },
        selectedTab === 'C' && {
          headerName: 'Completed Date',
          field: 'whenCompleted',
          flex: 1,
          minWidth: 100,
          maxWidth: 150,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return original.whenCompleted && <>{formatDate(original.whenCompleted)}</>;
          },
        },
        {
          headerName: 'Description',
          field: 'description',
          flex: 1.5,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return original.description ? (
              <Tooltip
                key={`${original.scheduledTaskId}-description`}
                placement="bottom"
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: original.description,
                    }}
                  />
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncate(
                      String(original.description).replace(/<\/?[^>]+(>|$)/g, '') ?? '',
                      60
                    ),
                  }}
                />
              </Tooltip>
            ) : (
              ''
            );
          },
        },
        selectedTab === 'C' && {
          headerName: 'Completion Notes',
          field: 'notes',
          flex: 1.5,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return original.notes ? (
              <Tooltip
                placement="bottom"
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: original.notes,
                    }}
                  />
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncate(
                      String(original.notes).replace(/<\/?[^>]+(>|$)/g, '') ?? '',
                      60
                    ),
                  }}
                />
              </Tooltip>
            ) : (
              ''
            );
          },
        },
        {
          headerName: '',
          field: 'actions',
          flex: 1,
          minWidth: 40,
          maxWidth: 150,
          align: 'center',
          disableColumnMenu: true,
          sortable: false,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return (
              <TableActionsMenu labelContext="Task" id={`action-menu-${original.scheduledTaskId}`}>
                {isEditable && (
                  <Link
                    to={`${Paths.tasks.url}/${original.scheduledTaskId}${
                      currentCustomer || redirect ? '?' : ''
                    }${
                      redirect
                        ? `redirect=${encodeURIComponent(
                            `${redirect}${
                              queryParams
                                ? `?${queryParams}${
                                    currentCustomer
                                      ? `&accountId=${currentCustomer?.accountId}`
                                      : ''
                                  }`
                                : ''
                            }`
                          )}`
                        : `${currentCustomer ? `accountId=${currentCustomer?.accountId}&` : ''}`
                    }`}
                  >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                        Edit Task
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
                {!currentCustomer &&
                  original.accountId &&
                  hasCorrectUserPermissions(Permissions.ViewCustomers, user!) && (
                    <Link to={`${getCustomerDetailRoute(original?.accountId!)}`}>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <FontAwesomeIcon icon={faEye} style={{ marginRight: '.5rem' }} />
                          View Customer
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  )}
                {isEditable && !original.whenCompleted && (
                  <ListItem disablePadding sx={{ color: theme => theme.palette.primary.main }}>
                    <ListItemButton
                      color="primary"
                      onClick={() => {
                        setSelectedTask(original);
                        setCompleteModalIsOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} style={{ marginRight: '.5rem' }} />
                      Quick Complete
                    </ListItemButton>
                  </ListItem>
                )}
              </TableActionsMenu>
            );
          },
        },
      ].filter(Boolean) as any[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, isEditable, selectedTab]
  );

  return (
    <>
      <CardFiltersLayout isOpen={isShowingFilters}>
        <TasksFilters
          selectedAccountId={selectedAccountId}
          setSelectedAccountId={setSelectedAccountId}
          setSelectedDateRange={setSelectedDateRange}
          selectedDateRange={selectedDateRange}
          isLoading={isLoadingTasks}
          setHasAppliedFilters={setHasAppliedFilters}
          hasAppliedFilters={hasAppliedFilters}
          showPriorityFilter={selectedTab === 'O'}
          setSelectedTaskDefId={setSelectedTaskDefId}
          selectedTaskDefId={selectedTaskDefId}
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
          selectedPriority={selectedPriority}
          setSelectedPriority={setSelectedPriority}
          excludeFilters={excludeFilters}
          applyFilters={(clearFilters?: boolean) => {
            if (clearFilters) {
              setFilters({
                startDate: null,
                endDate: null,
                accountId: '',
                taskDefinitionId: '',
                userId: defaultUserFilter ?? '',
                priority: '',
              });
              if (hasQueryParamFiltering) {
                setSearchParams({
                  [ESearchParams.taskType]: '',
                  [ESearchParams.priority]: '',
                  [ESearchParams.startDate]: '',
                  [ESearchParams.endDate]: '',
                  [ESearchParams.accountId]: '',
                  [ESearchParams.userId]: defaultUserFilter ?? '',
                });
              }
            } else {
              setPage(0);
              setFilters({
                ...filters,
                startDate: selectedDateRange?.startDate?.toISOString(),
                endDate: selectedDateRange?.endDate?.toISOString(),
                accountId: selectedAccountId,
                taskDefinitionId: selectedTaskDefId,
                userId: selectedUserId,
                priority: selectedPriority,
              });
              // don't set params on /services/maintenance for now
              if (hasQueryParamFiltering) {
                setSearchParams({
                  [ESearchParams.taskType]: selectedTaskDefId ?? undefined,
                  [ESearchParams.priority]: selectedPriority ?? undefined,
                  [ESearchParams.startDate]:
                    selectedDateRange?.startDate?.toISOString() ?? undefined,
                  [ESearchParams.endDate]: selectedDateRange?.endDate?.toISOString() ?? undefined,
                  [ESearchParams.accountId]: selectedAccountId ?? undefined,
                  [ESearchParams.userId]: selectedUserId ?? undefined,
                });
              }
            }
          }}
        />
      </CardFiltersLayout>
      {selectedTab && (
        <Box>
          <Tabs
            id="tasks-tabs"
            selectedTab={selectedTab}
            setSelectedTab={async val => {
              // Reset upon tab switch
              onPageChange(0);
              setSelectedPriority('');
              if (hasQueryParamFiltering) {
                setSearchParams({
                  [ESearchParams.tab]: val,
                  [ESearchParams.sortDirection]: val === 'O' ? 'asc' : 'desc',
                });
              }
              setSelectedTab(val);
            }}
            tabs={
              [
                {
                  key: 'O',
                  title: 'Open',
                  disabled: isLoadingTasks,
                },
                {
                  key: 'C',
                  title: 'Closed',
                  disabled: isLoadingTasks,
                },
              ].filter(Boolean) as ITab[]
            }
          />
        </Box>
      )}
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: ITask) => row.scheduledTaskId}
        rows={items}
        columns={columns}
        loading={isLoadingTasks}
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        disableColumnFilter
        noResultsMessage="No Scheduled Tasks."
        hasMobileLayout
        mobileProps={{
          mobileCustomDefaultAccessor: (val: ITask) =>
            hyphenSeparateTwoInputs(val?.accountName, val?.taskType),
          truncateAccordionLabel: true,
          showHandleActions: true,
        }}
      />
      {selectedTask && (
        <QuickCompleteModal
          open={completeModalIsOpen}
          onClose={() => setCompleteModalIsOpen(false)}
          task={selectedTask}
          onSubmit={() => {
            refetchTasks();
          }}
        />
      )}
    </>
  );
};
