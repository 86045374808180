import { axios } from '../helpers';
import qs from 'querystring';
import {
  IAddressAutocomplete,
  IAddressVerification,
  IVerifyAddress,
  IVerifyAddressRes,
  IValidateAddressRes,
} from '../models';
import { userSignal } from '../signals';

export const getAddressVerification = async (filters?: {
  officeId?: string | null;
}): Promise<IAddressVerification> => {
  try {
    const { data } = await axios.get(`/api/addresses/verification`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAddressVerificationForAllSites = async (filters?: {
  officeId?: string | null;
}): Promise<IAddressVerification> => {
  try {
    const { data } = await axios.get(`/api/addresses/verificationforallsites`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};


export const getAddressAutocomplete = async (
  search: string,
  token?: string | null,
  officeId?: string | null
): Promise<IAddressAutocomplete[]> => {
  try {
    const { data } = await axios.get(`/api/addresses/auto-complete`, {
      headers: !!token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined,
      params: {
        search,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyAddress = async (
  payload: IVerifyAddress,
  officeId?: string | null
): Promise<IVerifyAddressRes> => {
  try {
    const { data } = await axios.post(`/api/addresses/verify`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getVerifyAddresses = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  serviceDate?: string | null;
  userId?: string | null;
  officeId?: string | null;
}): Promise<IValidateAddressRes> => {
  try {
    const { data } = await axios.get(`/api/addresses/validate`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const getVerifyAllSiteAddresses = async (filters?: {
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number;
  serviceDate?: string | null;
  userId?: string | null;
  officeId?: string | null;
}): Promise<IValidateAddressRes> => {
  try {
    const { data } = await axios.get(`/api/addresses/validateall`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
