import { FC, useContext, useEffect, useState } from 'react';
import { ExternalLink, Loader, Card } from '../../components';
import { useQuery } from 'react-query';
import { Box, Typography, CircularProgress, Button, styled, TypographyProps, FormControlLabel, Checkbox } from '@mui/material';
import { IAddressVerification, ISimpleDateRange } from '../../models';
import { getAddressVerification, getAddressVerificationForAllSites } from '../../fetch';
import { UserContext } from '../../context';
import { getLegacyUrl } from '../../helpers';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Paths } from '../../constants';
import { getLocalStorage, setLocalStorage, ADDRESSES_VALIDATE_ALL } from '../../helpers/local-storage';

interface IAddressVerificationProps {
  dateRange: ISimpleDateRange;
}

export const AddressVerification: FC<IAddressVerificationProps> = ({ dateRange }) => {
  const { user } = useContext(UserContext);
  const [verifyAllAddresses, setVerifyAllAddresses] = useState(false);
  const lsValidateAllAddresses = async (): Promise<boolean | undefined> => {
    return await getLocalStorage(ADDRESSES_VALIDATE_ALL);
  };
  const fetchLsValidateAllAddresses = async () => {
    const lsValidateAllAddressesResult = await lsValidateAllAddresses();
    if (lsValidateAllAddressesResult) {
      setVerifyAllAddresses(lsValidateAllAddressesResult);
    }
  };


  const { isLoading, data, refetch } = useQuery<IAddressVerification, Error>(
    ['addressVerification', dateRange, verifyAllAddresses],
    async () => {
      return (verifyAllAddresses ? getAddressVerificationForAllSites : getAddressVerification)({
        officeId: user?.officeId,
      });
    },
    {
      notifyOnChangeProps: 'tracked',
      staleTime: Infinity,
    }
  );

  const handleVerifyAllAddressesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyAllAddresses(event.target.checked);
    setLocalStorage(ADDRESSES_VALIDATE_ALL, event.target.checked);
    refetch();
  };

  const percent: number | null =
    data?.verifiedAddresses && data?.totalAddresses
      ? Number(((data.verifiedAddresses / data.totalAddresses) * 100).toFixed(0))
      : 0;
  const legacyUrl = getLegacyUrl?.();
  const { v2Customers } = useFlags();

  useEffect(() => {
    fetchLsValidateAllAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      cardTitleProps={{ title: 'Site Address Verification' }}
      sxProps={{ height: '100%', paddingBottom: 0 }}
    >
      {isLoading && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="13.9rem"
        >
          <Loader position="centered" />
        </Box>
      )}
      {!isLoading && data && (
        <>
          <ContentWrapper>
            <ProgressWrapper>
              <StyledActiveProgress
                variant="determinate"
                // divider by two so we only show the half circle
                value={percent ? percent / 2 : 0}
                color="secondary"
                data-testid="progress-dial"
              />
              <StyledCircularProgress
                variant="determinate"
                // divider by two so we only show the half circle
                value={100 / 2}
                color="inherit"
              />
              <PercentText>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                {data?.verifiedAddresses}
                <TargetText component="span">/{data?.totalAddresses} verified</TargetText>
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                {data?.totalAddresses - data?.verifiedAddresses} 
                <TargetText component="span"> Addresses Need Verification</TargetText>
              </Typography>
              </PercentText>
            </ProgressWrapper>
          </ContentWrapper>
          <Box display="flex" alignItems="center" sx={{ justifyContent: "space-between" }}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={verifyAllAddresses}
                    onChange={(event) => {
                      handleVerifyAllAddressesChange(event)
                    }}
                    name="verifyAllAddresses"
                    color="primary"
                  />
                }
                label="Include Sites Without Services"
              /></Box>
            <Box >
              {!v2Customers && (
                <StyledExternalLink
                  data-testid="verify-addresses-button"
                  type="white"
                  target="_blank"
                  to={`${legacyUrl}/Office/Address/Verify`}
                >
                  <FontAwesomeIcon icon={faCheck} style={{ marginRight: '.5rem' }} />
                  Verify Addresses
                </StyledExternalLink>
              )}

              {v2Customers && (
                <Button
                  component={Link}
                  color="primary"
                  size="small"
                  to={`${Paths.verifyAddresses.url}`}
                  startIcon={<FontAwesomeIcon icon={faCheck} />}
                  data-testid="verify-addresses-button"
                >
                  Verify Addresses
                </Button>
              )}
            </Box>
          </Box>
        </>
      )}
      {!isLoading && !data && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="13.9rem"
          data-testid="no-addresses-to-verify"
        >
          <Typography>No Address Verification</Typography>
        </Box>
      )}
    </Card>
  );
};

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '12.6rem',
  paddingBottom: '1rem',
}));

const PercentText = styled(Box)(({ theme }) => ({
  top: '40px',
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'center',
  overflow: 'hidden',
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.grey[500],
  position: 'absolute',
  transform: `rotate(180deg) !important`,
  width: `300px !important`,
  height: `19rem !important`,
  '@media (max-width: 400px)': {
    width: `250px !important`,
    height: `15rem !important`,
    top: 10,
  },
  '@media (min-width: 1200px) and (max-width: 1350px)': {
    width: `250px !important`,
    height: `16rem !important`,
  },
}));

const StyledActiveProgress = styled(CircularProgress)(({ theme }) => ({
  transform: `rotate(180deg) !important`,
  width: `300px !important`,
  height: `19rem !important`,
  position: 'relative',
  zIndex: 1,
  '@media (max-width: 400px)': {
    width: `250px !important`,
    height: `15rem !important`,
    top: 10,
  },
  '@media (min-width: 1200px) and (max-width: 1350px)': {
    width: `250px !important`,
    height: `16rem !important`,
  },
}));

const TargetText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.grey[600],
}));

const StyledExternalLink = styled(ExternalLink)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: '0.375rem 0.75rem',
  fontSize: '0.875rem',
  borderRadius: '0.2rem',
  borderColor: theme.palette.primary.main,
  color: '#fff',
  zIndex: 3,
  cursor: 'pointer',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#1b3783',
    borderColor: '#1a347b',
  },
}));
