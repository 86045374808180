import { axios } from '../helpers';
import qs from 'querystring';
import { IResponse } from '../models';
import { ISitePhoto } from '../models/photos';
import { userSignal } from '../signals';

export const getSitePhotos = async (
  id: string | number,
  filters?: { sortBy?: string; sortDirection?: string; page?: number; perPage?: number | string },
  officeId?: string | null
): Promise<IResponse<ISitePhoto[]>> => {
  try {
    const { data } = await axios.get(`/api/sites/${id}/photos`, {
      params: {
        ...filters,
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const updateSitePhoto = async (
  id: string | number,
  sitePhotoId?: string | number,
  payload?: {
    title?: string;
  },
  officeId?: string | null
): Promise<IResponse<ISitePhoto[]>> => {
  try {
    const { data } = await axios.put(`/api/sites/${id}/photos/${sitePhotoId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const createSitePhotos = async (
  id: string | number,
  payload: File,
  meta?: ISitePhoto,
  officeId?: string | null
): Promise<void> => {
  const formData = new FormData();
  formData.append('File', payload ?? '');
  formData.append('officeId', officeId ?? userSignal.value?.officeId ?? ''); 
   try {
    const { data } = await axios.post(`/api/sites/${id}/photos?Description=${meta?.displayName ?? null}`, formData);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};

export const deleteSitePhoto = async (
  siteId: string | number,
  sitePhotoId: string
): Promise<void> => {
  try {
    const { data } = await axios.delete(`/api/sites/${siteId}/photos/${sitePhotoId}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data);
  }
};
