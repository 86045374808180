import { FC, useMemo, useContext, useCallback, useEffect } from 'react';
import { Button, Tooltip, Box, useMediaQuery } from '@mui/material';
import {
  Link,
  ServerSideDataGrid,
  ExternalLink,
  PrimaryIcon,
  PrimaryIconButton,
  useDataGrid,
  GridDataFetcher,
  TableLegend,
  TableLegendIcon,
} from '../../components';
import { GridRenderCellParams, GridSortDirection, GridValueGetterParams } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatDate, formatTime, getLegacyUrl, hasCorrectUserPermissions } from '../../helpers';
import {
  IDateRange,
  IScheduledService,
  ISimpleDateRange,
  ServicePodType,
  ServiceTypeTab,
} from '../../models';
import { UserContext } from '../../context';
import { Paths, Permissions } from '../../constants';
import {
  faBroom,
  faFileInvoice,
  faFlask,
  faVial,
  faWaterLadder,
  faCamera,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { ServicesActionButton } from './services-actions-button';
import { format } from 'date-fns';

interface IFieldReportDataGrid {
  setConfirmService: (val: IScheduledService) => void;
  dateLinkModal?: boolean;
  redirect?: string;
  accountId?: string;
  setServiceOption: (val: {
    currentServiceId: string | null;
    optionType: ServicePodType | null;
  }) => void;
  selectedTab: ServiceTypeTab | undefined;
  isEditable?: boolean;
  showNonClickableAttributes?: boolean;
  fetchServices: (params: {
    sortColumn?: string;
    beforeItemId?: number;
    sortDirection?: GridSortDirection;
    afterItemId?: number;
    perPage: number;
    isFirstPage?: boolean;
    isLastPage?: boolean;
    searchParams?: URLSearchParams;
    internalDateRange?: IDateRange;
  }) => void;
  internalUserFilter?: string;
  selectedDateRange: IDateRange;
  filters?: any;
  selectedUserGroup: string;
  dateRange?: ISimpleDateRange;
}

export const FieldReportDataGrid: FC<IFieldReportDataGrid> = ({
  setConfirmService,
  dateLinkModal,
  redirect,
  accountId,
  setServiceOption,
  selectedTab,
  isEditable,
  showNonClickableAttributes = true,
  fetchServices,
  internalUserFilter,
  selectedDateRange,
  filters,
  selectedUserGroup,
  dateRange,
}) => {
  const isMidMobile = useMediaQuery('(min-width: 960px) and (max-width: 1200px)');
  const { user } = useContext(UserContext);
  const { v2Customers } = useFlags();
  const isMobile = useMediaQuery('(max-width: 960px)');

  const dataFetcher: GridDataFetcher<IScheduledService, number> = useCallback(
    async ({
      perPage,
      sortColumn,
      sortDirection,
      beforeItemId,
      afterItemId,
      isFirstPage,
      isLastPage,
    }) => {
      return fetchServices({
        sortColumn,
        beforeItemId,
        sortDirection,
        afterItemId,
        perPage,
        isFirstPage,
        isLastPage,
        internalDateRange: !!dateRange
          ? {
              startDate: dateRange?.selection?.startDate,
              endDate: dateRange?.selection?.endDate,
              key: 'selection',
              inputValue: '',
            }
          : undefined,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internalUserFilter, selectedDateRange, filters, selectedUserGroup]
  );

  const {
    rows,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onKeysetPageChange,
    onPageSizeChange,
    onSortModelChange,
    isLoading,
  } = useDataGrid<IScheduledService, number>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: `scheduled-services-grid-field-report`,
      sortColumn: 'serviceDate',
      sortDirection: 'asc',
    },
    keysetPagingKey: 'scheduledServiceId',
    dataFetcher: dataFetcher,
  });

  // Allows for external picker to control and set visible filters and update grid based on selection
  useEffect(() => {
    onKeysetPageChange(0, true, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    onKeysetPageChange(0, true, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const columns = useMemo(() => {
    return [
      {
        field: 'serviceDate',
        headerName: 'Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        maxWidth: 100,
        renderCell: (params: GridValueGetterParams<IScheduledService>) => {
          const { row: original } = params;
          const isRecurringService = !!original?.recurringServiceId;

          if (
            original.status === 'Closed' &&
            !hasCorrectUserPermissions(Permissions.EditClosedService, user!)
          ) {
            return <>{original.serviceDate ? formatDate(original.serviceDate) : ''}</>;
          }
          return isRecurringService && dateLinkModal ? (
            <Button
              color="primary"
              sx={{ fontWeight: 600, padding: 0 }}
              variant="text"
              onClick={() => setConfirmService(original)}
            >
              {formatDate(original.serviceDate)}
            </Button>
          ) : (
            <Link
              to={`${Paths.maintenance.url}/${original.scheduledServiceId}${
                redirect ? `?redirect=${redirect}` : ''
              }`}
            >
              <Tooltip
                title={
                  !!original.frequency
                    ? 'Edit single service in recurring schedule'
                    : 'Edit service appointment'
                }
                placement="bottom"
              >
                <span>{formatDate(original.serviceDate)}</span>
              </Tooltip>
            </Link>
          );
        },
      },
      !accountId && {
        field: 'siteName',
        headerName: 'Site',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          const childComponent = (
            <Tooltip title={`View customer's site`} placement="bottom">
              <span>{original.siteName}</span>
            </Tooltip>
          );
          return v2Customers ? (
            <Link
              to={`${Paths.customers.url}/${original.accountId}/sites?siteId=${original.siteId}`}
            >
              {childComponent}
            </Link>
          ) : (
            <ExternalLink to={`${getLegacyUrl()}/Office/Site/View/${original.siteId}`}>
              {childComponent}
            </ExternalLink>
          );
        },
      },
      {
        field: 'serviceType',
        headerName: 'Type',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'assignedTo',
        headerName: 'Tech',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'whenStarted',
        headerName: 'Started',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          if (formatDate(original.serviceDate) === formatDate(original.whenStarted)) {
            return <span>{formatTime(original.whenStarted)}</span>;
          }
          return formatDate(original.whenStarted);
        },
      },
      {
        field: 'whenCompleted',
        headerName: 'Completed',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          if (original.status === 'Closed') {
            if (formatDate(original.serviceDate) === formatDate(original.whenCompleted)) {
              return <span>{formatTime(original.whenCompleted)}</span>;
            }
            return formatDate(original.whenCompleted);
          }
          return '';
        },
      },
      !isMidMobile && {
        field: 'psi',
        headerName: 'Psi',
        filterable: false,
        disableColumnMenu: true,
        width: 40,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          return <>{original.psi ?? '0'}</>;
        },
      },
      !isMidMobile && {
        field: 'cleanPsi',
        headerName: 'Clean Psi',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      !isMidMobile && {
        field: 'completionNotes',
        headerName: 'Notes',
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
        filterable: false,
      },
      {
        field: 'icons',
        headerName: 'Attributes',
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        width: 200,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          const {
            showBeakerIcon,
            showBrushIcon,
            showWaterTestIcon,
            showWrenchIcon,
            showChemicalUseIcon,
            showPoolEquipmentIcon,
            showServiceCompleteIcon,
            showServicePhotoIcon,
            scheduledServiceId,
          } = original;
          return (
            <Box display="flex">
              {showNonClickableAttributes && (
                <>
                  {showBeakerIcon && <PrimaryIcon icon={faFlask} />}
                  {showBrushIcon && <PrimaryIcon icon={faBroom} />}
                  {showWrenchIcon && <PrimaryIcon icon={faFileInvoice} />}
                </>
              )}
              <Box>
                {showPoolEquipmentIcon && (
                  <Tooltip title={`Site has valid pool equipment`} placement="bottom">
                    <span>
                      <PrimaryIconButton
                        icon={faWaterLadder}
                        onClick={() => {
                          setServiceOption({
                            currentServiceId: scheduledServiceId,
                            optionType: ServicePodType.POOL,
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {showWaterTestIcon && (
                  <Tooltip title={`Water Test has been Performed`} placement="bottom">
                    <span>
                      <PrimaryIconButton
                        icon={faFlask}
                        onClick={() => {
                          setServiceOption({
                            currentServiceId: scheduledServiceId,
                            optionType: ServicePodType.ANALYSIS,
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {showChemicalUseIcon && (
                  <Tooltip title={`Chemical Use has been Logged`} placement="bottom">
                    <span>
                      <PrimaryIconButton
                        icon={faVial}
                        onClick={() => {
                          setServiceOption({
                            currentServiceId: scheduledServiceId,
                            optionType: ServicePodType.RECOMMENDATIONS,
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                )}

                {showServicePhotoIcon && (
                  <Tooltip title={`Service Photo attached`} placement="bottom">
                    <span>
                      <PrimaryIconButton
                        icon={faCamera}
                        onClick={() => {
                          setServiceOption({
                            currentServiceId: scheduledServiceId,
                            optionType: ServicePodType.PHOTO,
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {showServiceCompleteIcon && (
                  <Tooltip title={`Service has been Completed`} placement="bottom">
                    <span>
                      <PrimaryIconButton
                        icon={faCircleCheck}
                        onClick={() => {
                          setServiceOption({
                            currentServiceId: scheduledServiceId,
                            optionType: ServicePodType.DETAILS,
                          });
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
              </Box>
            </Box>
          );
        },
      },
      isEditable && {
        headerName: '',
        field: 'actions',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        width: 60,
        renderCell: (params: GridRenderCellParams<IScheduledService>) => {
          const { row: original } = params;
          return <ServicesActionButton service={original} redirect={redirect} />;
        },
      },
    ].filter(Boolean);
  }, [
    dateLinkModal,
    redirect,
    setConfirmService,
    accountId,
    v2Customers,
    setServiceOption,
    isEditable,
    showNonClickableAttributes,
    isMidMobile,
    user,
  ]);

  return (
    <>
      <ServerSideDataGrid
        autoHeight
        getRowId={(row: IScheduledService) => row.scheduledServiceId}
        rows={rows}
        columns={columns as any[]}
        hasMobileLayout
        mobileProps={{
          mobileCustomDefaultAccessor: (val: IScheduledService) =>
            `${format(new Date(val.serviceDate), 'L/d/yy')} ${val.serviceType}${
              // only show if have a site name and we aren't on the customer site page with the accountId passed in
              val.siteName && !accountId ? ` - ${val.siteName}` : ''
            }`,
          showHandleActions: true,
          truncateAccordionLabel: true,
        }}
        isKeysetTable
        rowCount={recordCount}
        page={page}
        pageSize={perPage}
        loading={isLoading}
        onPageChange={onKeysetPageChange}
        onPageSizeChange={onPageSizeChange}
        sortModel={sortModel}
        onSortModelChange={(model, details) => {
          onSortModelChange(model);
        }}
      />
      {!isLoading && rows && rows?.length > 0 && (
        <Box mt={isMobile ? 1 : 0}>
          <TableLegend>
            <TableLegendIcon icon={faWaterLadder} helpText="Site has valid Pool Equipment" />
            <TableLegendIcon icon={faFlask} helpText="Water Test has been Performed" />
            <TableLegendIcon icon={faVial} helpText="Chemical Use has been Logged" />
            <TableLegendIcon icon={faCamera} helpText="Service Photo Attached" />
            <TableLegendIcon icon={faCircleCheck} helpText="Service has been Completed" />
          </TableLegend>
        </Box>
      )}
    </>
  );
};
