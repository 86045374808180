import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  createAdyenPayment,
  deleteAdyenSavedPaymentMethod,
  getAdyenPaymentSession,
  getAdyenRecurringPaymentSession,
} from '../../fetch';
import { Loader } from '../loader';
import { appInsights } from '../../services';
import { IAdyenSessionRes } from '../../models';
import Core from '@adyen/adyen-web/dist/types/core/core';
const { REACT_APP_AYDEN_ENV, REACT_APP_AYDEN_CLIENT_KEY } = require('../../buildSettings.json');

interface IAdyenDropInProps {
  shouldCallAdyenSession: boolean;
  amount: number;
  accountId: string;
  repairId?: string;
  reference: string;
  setIsShowingSession?: (val: boolean) => void;
  setShouldCallAdyenSession?: (val: boolean) => void;
  createAdyenTransRecord?: boolean;
  afterPaymentComplete?: () => void;
  dropInContainerId?: string;
  isRecurring?: boolean;
  payBalance?: boolean;
  setHasPaymentError?: (val: boolean) => void;
  transactionId?: string;
  token?: string;
  isForSecurityDeposit?: boolean;
  isForAdditionalSecurityDeposit?: boolean;
  paymentSuccessToast?: string;
  showStoredPaymentMethods?: boolean;
  showPaymentMethods?: boolean;
}

const trackAdyen = (data: any) => {
  appInsights.trackEvent({ name: 'Adyen Logging', properties: { ...data } });
};

export const AdyenDropIn: FC<IAdyenDropInProps> = ({
  shouldCallAdyenSession,
  amount,
  accountId,
  repairId,
  reference,
  setIsShowingSession,
  afterPaymentComplete,
  createAdyenTransRecord = true,
  dropInContainerId,
  isRecurring,
  payBalance,
  setHasPaymentError,
  transactionId,
  token,
  isForSecurityDeposit,
  isForAdditionalSecurityDeposit,
  paymentSuccessToast,
  showStoredPaymentMethods = true,
  showPaymentMethods = true,
  setShouldCallAdyenSession,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingSession, setIsLoadingSession] = useState<boolean>(false);
  const [isLoadingCreatePayment, setIsLoadingPayment] = useState<boolean>(false);
  const [sessionData, setSessionData] = useState<IAdyenSessionRes | undefined>(undefined);
  const [checkoutInstance, setCheckoutInstance] = useState<Core | undefined>(undefined);

  useEffect(() => {
    const callAdyenCheckout = async () => {
      try {
        // Create an instance of AdyenCheckout using the configuration object.
        const checkout = await AdyenCheckout({
          environment: REACT_APP_AYDEN_ENV, // Change to 'live' for the live environment.
          clientKey: REACT_APP_AYDEN_CLIENT_KEY, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
          // analytics: {
          //   enabled: true, // Set to false to not send analytics data to Adyen.
          // },
          session: {
            id: sessionData?.id!, // Unique identifier for the payment session.
            sessionData: sessionData?.sessionData!, // The payment session data.
          },
          onPaymentCompleted: async (result: any, component: any) => {
            trackAdyen({ result, component });
            if (result.resultCode === 'Authorised') {
              try {
                setIsLoadingPayment(true);
                if (createAdyenTransRecord) {
                  await createAdyenPayment(
                    {
                      amount,
                      accountId: accountId ?? '',
                      reference,
                      transactionId: transactionId ?? '',
                    },
                    token
                  );
                  enqueueSnackbar(paymentSuccessToast ?? `Successfully Processed Payment!`, {
                    variant: 'success',
                  });
                }
                afterPaymentComplete?.();
                setHasPaymentError && setHasPaymentError(false);
              } catch (error: any) {
                trackAdyen({ error });
                enqueueSnackbar(error?.message || `Error processing payment. Please try again.`, {
                  variant: 'error',
                });
              } finally {
                setIsLoadingPayment(false);
              }
            }
            if (result.resultCode === 'Refused') {
              enqueueSnackbar(`Payment is refused. Please verify card information and try again.`, {
                variant: 'error',
              });
              setHasPaymentError && setHasPaymentError(true);
              trackAdyen({ result, component });
              // on the external payment page reset the ability to pay again after 3.5 seconds if there is a refused error
              if (token) {
                setTimeout(() => {
                  setShouldCallAdyenSession?.(true);
                }, 3500);
              }
            }
          },
          onError: (error: any, component: any) => {
            trackAdyen({ error, component });
            enqueueSnackbar(error?.message || `Error processing payment. Please try again.`, {
              variant: 'error',
            });
            // on the external payment page reset the ability to pay again after 3.5 seconds if there is a error
            if (token) {
              setTimeout(() => {
                setShouldCallAdyenSession?.(true);
              }, 3500);
            }
          },
        });
        setIsShowingSession && setIsShowingSession(true);
        setCheckoutInstance(checkout);
        // Create an instance of Drop-in and mount it to the container you created.
        // https://docs.adyen.com/online-payments/build-your-integration/sessions-flow/?platform=Web&integration=Drop-in&version=5.63.0#drop-in-configuration
        checkout
          .create('dropin', {
            showStoredPaymentMethods: showStoredPaymentMethods,
            showPaymentMethods: showPaymentMethods,
            showRemovePaymentMethodButton: true,
            onDisableStoredPaymentMethod: (storedPaymentMethodId, resolve, reject) => {
              deleteAdyenSavedPaymentMethod(accountId, storedPaymentMethodId)
                .then(() => {
                  resolve();
                })
                .catch((error: any) => {
                  reject();
                  enqueueSnackbar(error?.Detail ?? `Error deleting saved payment method.`, {
                    variant: 'error',
                  });
                });
            },
          })
          .mount(dropInContainerId ? `#${dropInContainerId}` : '#dropin-container');
      } catch (error) {
        console.log(error);
        enqueueSnackbar(`Error setting up payment. Please try again.`, {
          variant: 'error',
        });
      }
    };
    if (sessionData?.id) {
      callAdyenCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData]);

  const createSession = async () => {
    try {
      setIsLoadingSession(true);
      const res = isRecurring
        ? await getAdyenRecurringPaymentSession(
            {
              amount,
              accountId,
              reference,
              payBalance: payBalance || false,
              showRemovePaymentMethodButton: true,
            },
            token
          )
        : await getAdyenPaymentSession(
            {
              amount,
              accountId,
              reference,
              transactionId: transactionId ?? null,
              repairId: repairId ?? '',
              isForSecurityDeposit,
              isForAdditionalSecurityDeposit,
            },
            token
          );
      setSessionData(res);
      if (checkoutInstance) {
        checkoutInstance?.update({
          session: {
            id: res?.id!, // Unique identifier for the payment session.
            sessionData: res?.sessionData!, // The payment session data.
          },
        });
      }
    } catch (error: any) {
      console.log(error);
      trackAdyen({ error });
      enqueueSnackbar(error?.Detail || `Error loading payment session. Please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingSession(false);
    }
  };
  useEffect(() => {
    if (shouldCallAdyenSession) {
      createSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCallAdyenSession]);

  return (
    <>
      {(isLoadingSession || isLoadingCreatePayment) && <Loader type="overlay" />}
      <div id={dropInContainerId ?? 'dropin-container'}></div>
    </>
  );
};
