export interface ICommunicationTemplateRes {
  records: ICommunicationTemplate[];
  totalRecordCount: number;
}

export interface ICommunicationTemplate {
  communicationTemplateId: string;
  name: string;
  sortOrder: number;
  eventDescription: string;
  event: string;
  emailSubject: string;
  isSuppressed: boolean;
}

export interface ICommunicationTemplateDetailCreate {
  templateName: string;
  emailSubject: string;
  officeId: string;
  emailBccs: string[];
  emailBody: string;
  sortOrder: string | number;
  emailEvent: string;
  campaignId: string;
  emailCampaignId: string;
  isSuppressed: boolean;
}

export interface ICommunicationTemplateDetail {
  communicationTemplateId: string;
  name: string;
  sortOrder: 0;
  event: string;
  eventDescription: string;
  emailSubject: string;
  emailTemplateParams: string[];
  emailBccs: string[];
  emailBody: string;
  isSuppressed: boolean;
  emailCampaignId: string;
  campaignId: string;
  smsBody: string;
  isSmsAllowModify: boolean;
}

export interface ICommunicationTemplateInsert {
  emailTemplateInsertId: string;
  insertName: string | null;
  insertText: string | null;
  sortOrder: number | string;
}

export interface ICommunicationTemplateInsertPayload {
  insertName: string | null;
  insertText: string | null;
  sortOrder: number | string;
}

export enum EMAIL_EVENTS {
  PaymentReceipt = 'A',
  PaymentDeclined = 'D',
  InRoute = 'E',
  MassEmail = 'M',
  ServiceCompletion = 'S',
}
