import { Alert, Box, Fade, Grid, Stack, TextField, Typography } from '@mui/material';
import { DisplayGroup, Loader, Modal, ModalSaveSection, SelectAsyncInput } from '../../components';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import {
  EMAIL_EVENTS,
  ICommunicationTemplate,
  IEnRouteNotification,
  IResponse,
} from '../../models';
import {
  createNextEnRouteNotification,
  getCommunicationTemplates,
  getNextEnRouteNotification,
} from '../../fetch';
import { convertToNumber, formatInputPhoneNumber } from '../../helpers';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { useConfirm } from '../../hooks';

interface INextInRouteModalProps {
  isOpen: boolean;
  handleClose: () => void;
  serviceId: string;
  nextServiceInfo?: IEnRouteNotification;
}

export const NextInRouteModal: FC<INextInRouteModalProps> = ({
  isOpen,
  handleClose,
  serviceId,
  nextServiceInfo,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [message, setMessage] = useState('');
  const [minMinutes, setMinMinutes] = useState('');
  const [maxMinutes, setMaxMinutes] = useState('');
  const [isSending, setIsSending] = useState(false);
  const confirm = useConfirm();

  const {
    data,
    refetch: fetchEnRouteNotification,
    isFetching,
    isLoading,
  } = useQuery<IEnRouteNotification>(
    'getNextEnRouteNotification',
    () => getNextEnRouteNotification(serviceId, selectedTemplateId),
    {
      enabled: false,
      onSuccess: d => {
        setMessage(d.message);
        setMinMinutes(d.estimatedMinutesMinRounded.toString());
        setMaxMinutes(d.estimatedMinutesMaxRounded.toString());
      },
    }
  );

  const handleCloseModal = async (showConfirm: boolean = true) => {
    if (showConfirm) {
      const result = await confirm(`Are you sure you don't want to send an SMS notification?`);
      if (result) {
        setMinMinutes('');
        setMaxMinutes('');
        setMessage('');
        handleClose();
      }
    } else {
      setMinMinutes('');
      setMaxMinutes('');
      setMessage('');
      handleClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      maxWidth={!data ? 'sm' : 'md'}
      title="Tech is on the way"
      onClose={() => handleCloseModal()}
    >
      <Fade in={isOpen}>
        <Box mt={1}>
          {(isFetching || isLoading || isSending) && <Loader type="overlay" />}
          {!data && nextServiceInfo && (
            <Stack gap={1} justifyContent="center" width="30rem" margin={`0 auto`}>
              <Typography sx={{ textAlign: 'center' }}>
                The next service scheduled is for {nextServiceInfo?.siteName}. Please select a SMS
                template to select to get started.
              </Typography>
              <SelectAsyncInput
                name="communicationTemplateId"
                label="SMS Template"
                value={selectedTemplateId}
                required
                apiRequest={() =>
                  getCommunicationTemplates({ perPage: -1, event: EMAIL_EVENTS.InRoute })
                }
                transformResponse={(response: IResponse<ICommunicationTemplate[]>) => {
                  return response.records.map(record => ({
                    label: record.name,
                    value: record.communicationTemplateId,
                  }));
                }}
                handleChange={(value: string) => {
                  setSelectedTemplateId(value);
                }}
              />
              <ModalSaveSection
                handleCancel={() => handleCloseModal()}
                cancelLabel="Do Not Send"
                handleSave={() => fetchEnRouteNotification()}
                submitLabel="Next Step"
                isSaveDisabled={!selectedTemplateId}
                submitIcon={faArrowRight}
              />
            </Stack>
          )}
          {data && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <DisplayGroup label="Customer">{data?.accountName}</DisplayGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DisplayGroup label="Site">{data?.siteName}</DisplayGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DisplayGroup label="Phone Number">
                    {data?.accountPrimaryPhoneNumber
                      ? formatInputPhoneNumber(data.accountPrimaryPhoneNumber?.fullPhoneNumber)
                      : ''}
                  </DisplayGroup>
                </Grid>
                <Grid item xs={12}>
                  {data?.isSmsAllowModify ? (
                    <TextField
                      variant="outlined"
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      size="small"
                      multiline
                      fullWidth
                      rows={3}
                      label="Message"
                    />
                  ) : (
                    <DisplayGroup label="Default Message">{data?.message}</DisplayGroup>
                  )}
                </Grid>
                {!data?.isSmsAllowModify && (
                  <Grid item xs={12} md={4}>
                    <DisplayGroup label="Mininum Estimate Arrival (minutes)">
                      <TextField
                        variant="outlined"
                        value={minMinutes}
                        onChange={e => setMinMinutes(e.target.value)}
                        size="small"
                        label=""
                        type="number"
                        error={convertToNumber(minMinutes) < 0}
                        helperText={
                          convertToNumber(minMinutes) < 0 ? 'Min must be greater than 0' : ''
                        }
                      />
                    </DisplayGroup>
                  </Grid>
                )}
                {!data?.isSmsAllowModify && (
                  <Grid item xs={12} md={6}>
                    <DisplayGroup label="Maximum Estimate Arrival (minutes)">
                      <TextField
                        variant="outlined"
                        value={maxMinutes}
                        onChange={e => setMaxMinutes(e.target.value)}
                        size="small"
                        label=""
                        type="number"
                        error={
                          convertToNumber(minMinutes) > convertToNumber(maxMinutes) ||
                          convertToNumber(maxMinutes) < 0
                        }
                        helperText={
                          convertToNumber(minMinutes) > convertToNumber(maxMinutes)
                            ? 'Max must be greater than min'
                            : convertToNumber(maxMinutes) < 0
                            ? 'Max must be greater than 0'
                            : ''
                        }
                      />
                    </DisplayGroup>
                  </Grid>
                )}
                {!data?.isSmsAllowModify && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      The minimum and maximum estimate fields do not update the default message in
                      real time. These values will be included only after selecting 'Send
                      Notification,' provided the message template is correctly configured.
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <ModalSaveSection
                handleCancel={() => handleCloseModal()}
                cancelLabel="Do Not Send"
                isSaveDisabled={
                  convertToNumber(minMinutes) > convertToNumber(maxMinutes) ||
                  convertToNumber(maxMinutes) < 0 ||
                  convertToNumber(minMinutes) < 0
                }
                handleSave={async () => {
                  try {
                    setIsSending(true);
                    await createNextEnRouteNotification(
                      data?.nextScheduledServiceId!,
                      selectedTemplateId,
                      {
                        toPhoneNumbers: data?.accountPrimaryPhoneNumber.fullPhoneNumber
                          ? [data?.accountPrimaryPhoneNumber.fullPhoneNumber]
                          : undefined,
                        message,
                        estimatedMinutesMin: convertToNumber(minMinutes),
                        estimatedMinutesMax: convertToNumber(maxMinutes),
                      }
                    );
                    enqueueSnackbar('SMS message sent!', {
                      variant: 'success',
                    });
                    handleCloseModal(false);
                  } catch (error: any) {
                    enqueueSnackbar(
                      error?.Detail ?? 'Error saving QuickBooks options, please try again.',
                      {
                        variant: 'error',
                      }
                    );
                  } finally {
                    setIsSending(false);
                  }
                }}
                submitLabel="Send Notification"
                submitIcon={faArrowRight}
              />
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};
