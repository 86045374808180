import { axios } from '../helpers';
import qs from 'querystring';
import {
  IDropdownResponse,
  IResponse,
  ICommunicationTemplateDetailCreate,
  ICommunicationTemplateInsert,
  ICommunicationTemplateInsertPayload,
  ICommunicationTemplate,
  ICommunicationTemplateDetail,
} from '../models';
import { userSignal } from '../signals';

const API_PATH = `/api/communication-templates` as const;

export const getCommunicationTemplates = async ({
  ...otherParams
}: {
  officeId?: string | null;
  event?: string;
  sortBy?: string;
  sortDirection?: string;
  page?: number;
  perPage?: number | string;
}): Promise<IResponse<ICommunicationTemplate[]>> => {
  try {
    const { data } = await axios.get(API_PATH, {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCommunicationTemplateEventTypes = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`${API_PATH}/event-types`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCommunicationTemplate = async (
  id: string,
  officeId?: string | null
): Promise<ICommunicationTemplateDetail> => {
  try {
    const { data } = await axios.get(`${API_PATH}/${id}`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const createCommunicationTemplate = async (
  payload: ICommunicationTemplateDetailCreate,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(API_PATH, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const updateCommunicationTemplate = async (
  id: string,
  payload: ICommunicationTemplateDetailCreate,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.put(`${API_PATH}/${id}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const deleateCommunicationTemplate = async (id: string): Promise<string> => {
  try {
    const { data } = await axios.delete(`${API_PATH}/${id}`);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCommunicationTemplateParams = async ({
  ...otherParams
}: {
  emailEvent?: string;
  officeId?: string | null;
}): Promise<any[]> => {
  try {
    const { data } = await axios.get(`${API_PATH}/communication-event-template-params`, {
      params: {
        ...otherParams,
        officeId: otherParams?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCommunicationTemplateCampaigns = async (
  officeId?: string | null
): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await axios.get(`${API_PATH}/campaigns`, {
      params: {
        officeId: officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const getCommunicationInserts = async (
  id: string,
  filters?: {
    officeId?: string | null;
    sortBy?: string;
    sortDirection?: string;
    page?: number;
    perPage?: number | string;
  }
): Promise<IResponse<ICommunicationTemplateInsert[]>> => {
  try {
    const { data } = await axios.get(`${API_PATH}/${id}/inserts`, {
      params: {
        ...filters,
        officeId: filters?.officeId ?? userSignal.value?.officeId ?? '',
      },
      paramsSerializer: params => qs.stringify(params),
    });

    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const createCommunicationInsert = async (
  id: string,
  payload: ICommunicationTemplateInsertPayload,
  officeId?: string | null
): Promise<string> => {
  try {
    const { data } = await axios.post(`${API_PATH}/${id}/inserts`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};

export const updateCommunicationInsert = async (
  id: string,
  communicationInsertId: string,
  payload: ICommunicationTemplateInsertPayload,
  officeId?: string | null
): Promise<void> => {
  try {
    const { data } = await axios.put(`${API_PATH}/${id}/inserts/${communicationInsertId}`, {
      ...payload,
      officeId: officeId ?? userSignal.value?.officeId ?? '',
    });
    return data;
  } catch (error: any) {
    return Promise.reject(error?.response.data);
  }
};
export const deleteCommunicationInsert = async (
  id: string,
  communicationInsertId: string
): Promise<any> => {
  try {
    const { data } = await axios.delete(`${API_PATH}/${id}/inserts/${communicationInsertId}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
