import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useLogout } from '../../hooks';
import { Paths } from '../../constants';

export const Logout = () => {
  const handleLogout = useLogout();

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Redirect to={Paths.login.url} />;
};
