import { FC, useContext, useMemo } from 'react';
import { Link, ServerSideDataGrid, ServerSideDataGridProps } from '../../components';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { formatDate, formatMoney, getCustomerDetailRoute } from '../../helpers';
import { CommissionsActionButton } from './commissions-actions-button';
import { ICommission } from '../../models/commissions';
import { SearchParamsContext } from '../../context';

interface CommissionsDataGridProps extends Omit<ServerSideDataGridProps, 'rows' | 'columns'> {
  rows: ICommission[];
  refetch: () => Promise<void>;
  setDeleting: (val: boolean) => void;
  redirect: string;
}

export const CommissionsDataGrid: FC<CommissionsDataGridProps> = ({
  rows,
  refetch,
  setDeleting,
  redirect,
  ...gridProps
}) => {
  const { queryParams } = useContext(SearchParamsContext);
  const columns = useMemo((): GridColDef[] => {
    return [
      {
        field: 'commissionDate',
        headerName: 'Date',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ICommission>) => {
          const { row: commission } = params;
          return commission.commissionDate ? formatDate(commission.commissionDate) : '';
        },
      },
      {
        field: 'commissionType',
        headerName: 'Type',
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'commissionTo',
        headerName: 'Commission To',
        flex: 1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'accountName',
        headerName: 'Customer',
        width: 200,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ICommission>) => {
          const { row: commission } = params;
          return (
            <>
              {commission.accountId ? (
                <Link
                  to={`${getCustomerDetailRoute(
                    commission?.accountId
                  )}?redirect=${encodeURIComponent(
                    `${redirect}${queryParams ? `?${queryParams}` : ''}`
                  )}`}
                >
                  {commission.accountName}
                </Link>
              ) : (
                commission.accountName
              )}
            </>
          );
        },
      },
      {
        field: 'serviceType',
        headerName: 'Service Type',
        width: 200,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<ICommission>) => {
          const { row: commission } = params;
          return commission.amount ? formatMoney(commission.amount) : '$0.00';
        },
      },
      {
        field: 'actions',
        headerName: '',
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<ICommission>) => {
          const { row: commission } = params;
          return (
            <CommissionsActionButton
              commission={commission}
              redirect={`?redirect=${encodeURIComponent(
                `${redirect}${queryParams ? `?${queryParams}` : ''}`
              )}`}
              setDeleting={setDeleting}
              refetchData={refetch}
            />
          );
        },
      },
    ];
  }, [refetch, setDeleting, redirect, queryParams]);

  return (
    <ServerSideDataGrid
      autoHeight
      getRowId={(row: ICommission) => row.commissionId}
      rows={rows}
      columns={columns}
      hasMobileLayout
      mobileProps={{
        mobileCustomDefaultAccessor: (row: ICommission) =>
          row.commissionDate && formatDate(row.commissionDate),
        showHandleActions: true,
      }}
      {...gridProps}
    />
  );
};
