import { ReactNode, useCallback, useMemo } from 'react';
import { styled } from '@mui/material';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  ICalendarDateRange,
  ITimelessEventChange,
  IWeekday,
  ITimelessEvent,
  CalendarEventTypes,
} from '../../../../../models';
import { getDateRangeInfo, parseCalendarDate } from '../../../../../helpers';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { orderBy } from 'lodash';
import { DroppableWeekdayColumn } from './DroppableWeekdayColumn';

export interface TimelessWeekViewProps<T> {
  events: ITimelessEvent<T>[];
  weekdays: IWeekday[];
  renderEvent: (event: ITimelessEvent<T>) => ReactNode;
  dateRange: ICalendarDateRange;
  onDateChange?: (change: ITimelessEventChange<T>) => unknown;
  allowDragging?: boolean;
}

export function TimelessWeekView<T>({
  events,
  weekdays,
  renderEvent,
  dateRange,
  onDateChange,
  allowDragging = true,
}: TimelessWeekViewProps<T>) {
  const eventsByWeekday = useMemo(() => {
    const rtn: Record<number, ITimelessEvent<T>[]> = {};
    if (!events.length) {
      return rtn;
    }
    events.forEach(item => {
      const day = item.date.getDay();
      if (!rtn[day]) {
        rtn[day] = [];
      }
      rtn[day].push(item);
    });

    Object.entries(rtn).forEach(([day, eventItems]) => {
      rtn[+day] = orderBy(eventItems, detail => detail.index);
    });
    return rtn;
  }, [events]);

  const infoByWeekday = useMemo(() => getDateRangeInfo(dateRange), [dateRange]);

  const onDragEnd = useCallback(
    (dropResult: DropResult) => {
      if (!onDateChange) {
        return;
      }
      const { source, destination, draggableId } = dropResult;

      if (!destination) {
        return [];
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return [];
      }

      const calendarDate = destination.droppableId;
      const targetIndex = destination.index;

      const event = events.find(e => e.id === draggableId);

      if (!event) {
        return;
      }

      const change: ITimelessEventChange<T> = {
        event,
        targetCalendarDate: parseCalendarDate(calendarDate),
        targetIndex,
      };

      onDateChange(change);
    },
    [events, onDateChange]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DnDInnerWrapper className={classes.employeeWorkWeekContainer}>
        {weekdays.map(weekday => {
          const dayInfo = infoByWeekday[weekday];
          if (!dayInfo) {
            return null;
          }
          return (
            <div key={dayInfo.date.toUTCString()} className={classes.weekdayColumn}>
              <div className={classes.weekday}>
                <Typography
                  className={clsx(classes.weekdayTitle, {
                    [classes.currentDayWeekDayLabel]: dayInfo.isCurrentDay,
                  })}
                >
                  {dayInfo.weekdayLabel.toLocaleUpperCase()}
                </Typography>
                <div
                  className={clsx(classes.weekdayNumber, {
                    [classes.currentDayWeekDayLabel]: dayInfo.isCurrentDay,
                  })}
                >
                  {dayInfo.dateLabel}
                </div>
              </div>
              <DroppableWeekdayColumn dayInfo={dayInfo}>
                {eventsByWeekday[weekday]?.map((event, index) => {
                  return (
                    <Draggable
                      key={event.id}
                      isDragDisabled={
                        // @ts-ignore
                        !allowDragging ||
                        (event?.data as any)?.event?.eventType === CalendarEventTypes.ScheduledTask
                      }
                      index={index}
                      draggableId={event.id}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.draggbleContainer}
                        >
                          {renderEvent(event)}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </DroppableWeekdayColumn>
            </div>
          );
        })}
      </DnDInnerWrapper>
    </DragDropContext>
  );
}

const PREFIX = 'WeekView';

const classes = {
  employeeWorkWeekContainer: `${PREFIX}-employeeWorkWeekContainer`,
  weekdayColumn: `${PREFIX}-weekdayColumn`,
  weekday: `${PREFIX}-weekday`,
  weekdayTitle: `${PREFIX}-weekdayTitle`,
  weekdayNumber: `${PREFIX}-weekdayNumber`,
  draggbleContainer: `${PREFIX}-draggbleContainer`,
  currentDayWeekDayLabel: `${PREFIX}-currentDayWeekDayLabel`
};

const DnDInnerWrapper = styled('div')(({ theme }) => ({
  [`&.${classes.employeeWorkWeekContainer}`]: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    '@media print': {
      display: 'table-row',
    },
    '.print-calendar--stack &&': {
      '@media print': {
        display: 'block',
      },
    },
  },

  [`& .${classes.weekdayColumn}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 1,
    flex: '1 1 auto',
    height: '100%',
    '&:nth-child(odd)': {
      backgroundColor: '#EAEEF6',
    },

    [`&:nth-child(odd) > .${classes.weekday}`]: {
      backgroundColor: '#DEEAF4',
    },

    [`&:nth-child(even) > .${classes.weekday}`]: {
      backgroundColor: '#EFFCFE',
    },

    '@media print': {
      display: 'table-cell',
      width: 'auto',
    },
    '.print-calendar--stack &&': {
      '@media print': {
        display: 'block',
        width: '100%',
        borderRight: 'none',
      },
    },
  },

  [`& .${classes.weekday}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 5,
    position: 'sticky',
    top: -1,
    zIndex: 10,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    '@media print': {
      position: 'static',
    },
  },

  [`& .${classes.weekdayTitle}`]: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },

  [`& .${classes.weekdayNumber}`]: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },

  [`& .${classes.draggbleContainer}`]: {
    width: '100%',
  },

  [`& .${classes.currentDayWeekDayLabel}`]: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  }
}));