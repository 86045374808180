import clsx from 'clsx';
import { Dispatch, FC, SetStateAction } from 'react';
import { CardTitle, Link, RepeatPattern, Card } from '../../components';
import { IScheduledServiceDetail } from '../../models';
import { useUnload } from '../../hooks';
import { Paths } from '../../constants';

interface IRecurringPattern {
  frequency?: string;
  recurringServiceId?: string;
  isRecurring: boolean;
  setSchedule: Dispatch<{
    dailyInterval1: number;
    weeklyInterval1: number;
    weeklyInterval2: number;
    monthlyInterval1: number;
    monthlyInterval2: number;
    monthlyInterval3: number;
    yearlyInterval1: number;
    yearlyInterval2: number;
    yearlyInterval3: number;
    recurOption?: boolean;
    recurPeriod: string;
  }>;
  serviceDetails: IScheduledServiceDetail | null;
  setFieldValue: (key: string, val: any) => void;
  endDate: string | Date;
  recurOption: boolean;
  recurPeriod: string;
  dailyInterval1: number;
  weeklyInterval1: number;
  weeklyInterval2: number;
  monthlyInterval1: number;
  monthlyInterval2: number;
  monthlyInterval3: number;
  yearlyInterval1: number;
  yearlyInterval2: number;
  yearlyInterval3: number;
  dirty: boolean;
  isExpanded?: boolean;
  initialDate: Date | string;
  setIsInvalidEndDate: Dispatch<
    SetStateAction<{
      reason: string;
      check: boolean;
    }>
  >;
  isInvalidEndDate: {
    reason: string;
    check: boolean;
  };
}
export const RecurringPattern: FC<IRecurringPattern> = ({
  setSchedule,
  serviceDetails,
  setFieldValue,
  endDate,
  recurPeriod,
  recurOption,
  dailyInterval1,
  weeklyInterval1,
  weeklyInterval2,
  monthlyInterval1,
  monthlyInterval2,
  monthlyInterval3,
  yearlyInterval1,
  yearlyInterval2,
  yearlyInterval3,
  isRecurring,
  frequency,
  recurringServiceId,
  dirty,
  isExpanded,
  initialDate,
  setIsInvalidEndDate,
  isInvalidEndDate,
}) => {
  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, dirty);

  return (
    <Card
      className={clsx('print--avoid-break')}
      sxProps={{
        marginBottom: '16px',
        '@media print': { marginBottom: '3rem' },
      }}
    >
      <CardTitle title="Repeat Pattern" withExpand initialExpand={true} overrideExpand={isExpanded}>
        {!isRecurring && (
          <Link to={`${Paths.maintenance.url}/recurring/${recurringServiceId}`}>{frequency}</Link>
        )}
        {isRecurring && (
          <RepeatPattern
            setSchedule={setSchedule}
            serviceDetails={serviceDetails}
            setFieldValue={setFieldValue}
            endDate={endDate}
            recurOption={recurOption}
            recurPeriod={recurPeriod}
            dailyInterval1={dailyInterval1}
            weeklyInterval1={weeklyInterval1}
            weeklyInterval2={weeklyInterval2}
            monthlyInterval1={monthlyInterval1}
            monthlyInterval2={monthlyInterval2}
            monthlyInterval3={monthlyInterval3}
            yearlyInterval1={yearlyInterval1}
            yearlyInterval2={yearlyInterval2}
            yearlyInterval3={yearlyInterval3}
            initialDate={initialDate}
            setIsInvalidEndDate={setIsInvalidEndDate}
            isInvalidEndDate={isInvalidEndDate}
          />
        )}
      </CardTitle>
    </Card>
  );
};
