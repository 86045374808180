import { Page } from '../../../components';
import { Paths } from '../../../constants';
import { Tasks } from './tasks-list';

export const TasksPage = () => {
  return (
    <Page title="Tasks">
      <Tasks cardTitle="Tasks" showFilters redirect={Paths.tasks.url} />
    </Page>
  );
};
