import { useMediaQuery, styled, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

interface FloatingButtonProps {
  children: ReactNode;
  isModal?: boolean;
  sx?: SxProps;
  modalBottomPosition?: string;
}

interface FloatingButtonStyles {
  isModal?: boolean;
  isMobile?: boolean;
  modalBottomPosition?: string;
}

export const FloatingToolbar: FC<FloatingButtonProps> = ({
  children,
  isModal,
  sx,
  modalBottomPosition,
}) => {
  const isMobile = useMediaQuery(`(max-width: 567px)`);

  return (
    <StyledBox
      isMobile={isMobile}
      isModal={isModal}
      modalBottomPosition={modalBottomPosition}
      className={clsx(classes.saveWrapper, 'print--none')}
      data-testid="FloatingToolbar"
      sx={sx}
    >
      <Box className={classes.buttonWrapper}>
        <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
          {children}
        </Stack>
      </Box>
    </StyledBox>
  );
};

const PREFIX = 'FloatingToolbar';

const classes = {
  saveWrapper: `${PREFIX}-saveWrapper`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
};

const StyledBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isModal' && prop !== 'isMobile' && prop !== 'modalBottomPosition',
})<FloatingButtonStyles>(({ theme, isMobile, isModal }) => ({
  [`&.${classes.saveWrapper}`]: {
    padding: isMobile ? theme.spacing(1, 3, 1, 3) : theme.spacing(2, 6, 2, 6),
    position: 'fixed',
    bottom: isModal ? '32px' : theme.spacing(4),
    zIndex: theme.zIndex.drawer + 2,
    right: 0,
    '@media (min-width: 1200px)': {
      right: isModal ? 'auto' : 0,
      left: isModal ? 'calc(50% - -22rem)' : 'auto',
      bottom: isModal ? '32px' : theme.spacing(4),
    },
  },

  [`& .${classes.buttonWrapper}`]: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(0.25),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 20%)',
  },
}));
