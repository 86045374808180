import { Grid } from '@mui/material';
import { FC, useState, useContext } from 'react';
import { Tasks } from '../scheduling/tasks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RecentItems } from './RecentItems';
import { HomeCalendar } from './HomeCalendar';
import { AddressVerification } from './AddressVerification';
import { ISimpleDateRange } from '../../models';
import { ScheduledServices } from '../services/ScheduledServices';
import { endOfDay, startOfDay } from 'date-fns';
import { UserContext } from '../../context';
import { Paths, Permissions } from '../../constants';
import { hasCorrectUserPermissions } from '../../helpers';

export const HomePageDetail: FC = () => {
  const featureFlags = useFlags();
  const { user } = useContext(UserContext);
  const [selectedDateRange, setSelectedDateRange] = useState<ISimpleDateRange>({
    selection: {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: 'selection',
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={4} order={{ xs: 1 }}>
        <HomeCalendar
          dateRange={selectedDateRange}
          handleDateChange={range => setSelectedDateRange(range)}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4} order={{ xs: 4, md: 2 }}>
        <RecentItems />
      </Grid>
      <Grid item xs={12} lg={4} order={{ xs: 5, md: 3 }}>
        <AddressVerification dateRange={selectedDateRange} />
      </Grid>
      {featureFlags.v2Services &&
        hasCorrectUserPermissions(Permissions.ViewScheduledServices, user!) && (
          <Grid item xs={12} order={{ xs: 2, md: 4 }}>
            <ScheduledServices
              dateRange={selectedDateRange}
              hasQueryParamFiltering={false}
              isHomePage
            />
          </Grid>
        )}
      {featureFlags.v2Scheduling &&
        hasCorrectUserPermissions(Permissions.ViewScheduledServices, user!) && (
          <Grid item xs={12} order={{ xs: 3, md: 5 }}>
            <Tasks
              showFilters
              dateRange={selectedDateRange}
              cardTitle="Scheduled Tasks"
              redirect={Paths.home.url}
              isEditable={false}
              excludeFilters={['Customer', 'Priority', 'Start Date', 'End Date', 'Task Type']}
              defaultUserFilter={user?.userId}
              hasQueryParamFiltering={false}
            />
          </Grid>
        )}
    </Grid>
  );
};
