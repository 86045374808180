import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { Modal } from '../../components';
import { IScheduledService } from '../../models';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../constants';

interface IConfirmModal {
  isOpen: boolean;
  handleClose: () => void;
  confirmService: IScheduledService | null;
  redirect?: string;
}

export const ConfirmModal: FC<IConfirmModal> = ({
  isOpen,
  handleClose,
  confirmService,
  redirect,
}) => {
  const history = useHistory();
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      title="Edit this single service date or the recurrence?"
      maxWidth="sm"
    >
      <Box mt={2} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Box display="flex" gap={1}>
          <Button
            color="primary"
            onClick={() =>
              history.push(
                `${Paths.maintenance.url}/${confirmService?.scheduledServiceId}${
                  redirect ? `?redirect=${redirect}` : ''
                }`
              )
            }
          >
            Single
          </Button>
          <Button
            color="secondary"
            onClick={() =>
              history.push(
                `${Paths.maintenance.url}/recurring/${confirmService?.recurringServiceId}${
                  redirect ? `?redirect=${redirect}` : ''
                }`
              )
            }
          >
            Recurrence
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
