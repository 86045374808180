import { Box, Button } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from 'react-router-dom';
import { FloatingToolbar, CreditLimitAlert } from '../../../components';
import { IAccountDetail } from '../../../models';
import { AccountDetailsDisplay } from './AccountDetailsDisplay';
import { UserContext } from '../../../context';
import { Paths, Permissions } from '../../../constants';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { hasCorrectUserPermissions } from '../../../helpers';

export interface IAccountDetailsTab {
  currentCustomer: IAccountDetail | null;
  isEditable?: boolean;
}

export const AccountDetailsTab: FC<IAccountDetailsTab> = ({ currentCustomer, isEditable }) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const showLimit =
    hasCorrectUserPermissions(Permissions.ViewTransactions, user!) ||
    (hasCorrectUserPermissions(Permissions.ViewAgingOnHomeScreen, user!) &&
      hasCorrectUserPermissions(Permissions.ViewTransactions, user!));
  const [isAllExpanded, setIsAllExpanded] = useState<boolean | undefined>(true);

  return (
    <Box marginTop={0.5} className="print--no-margin">
      <Box
        mb={1}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        className="print--none"
      >
        <Button
          variant="text"
          onClick={() => setIsAllExpanded(!isAllExpanded ? true : false)}
          startIcon={isAllExpanded ? <ExpandLess /> : <ExpandMore />}
          className="print--none"
        >
          {isAllExpanded ? 'Collapse All' : 'Expand All'}
        </Button>
      </Box>
      {showLimit && (
        <CreditLimitAlert
          accountId={currentCustomer?.accountId}
          isExpanded={isAllExpanded}
          containerShadow
          sx={{ mb: 2 }}
        />
      )}
      <AccountDetailsDisplay currentCustomer={currentCustomer} isExpanded={isAllExpanded} />
      {isEditable && (
        <FloatingToolbar>
          <Box>
            {hasCorrectUserPermissions(Permissions.EditAccountInfo, user!) && (
              <Button
                onClick={() => {
                  history.push(`${Paths.customers.url}/${currentCustomer?.accountId}/edit`);
                }}
                color="secondary"
                startIcon={<FontAwesomeIcon icon={faEdit} size="sm" />}
              >
                Edit Details
              </Button>
            )}
          </Box>
        </FloatingToolbar>
      )}
    </Box>
  );
};
